import { ficheConstants } from '../constants/fiche.constants'
import { ficheServices } from '../services/fiche.services'
import { res } from '../helpers/dispatch'

export const ficheAction = {
    getFiche,
    updateFiche,
}

function getFiche() {
    return (dispatch) => {
        dispatch(res.loading(ficheConstants.FICHE_LOADING))
        ficheServices
            .getFiche()
            .then((response) => {
                console.log(response.data)
                let fiche = response.data
                dispatch(res.success(ficheConstants.GETFICHE_SUCCESS, fiche))
            })
            .catch((error) => {
                dispatch(res.failure(ficheConstants.GETFICHE_FAILURE, error))
            })
    }
}

function updateFiche(body, fiche_id) {
    return (dispatch) => {
        dispatch(res.loading(ficheConstants.FICHE_LOADING))
        ficheServices
            .updateFiche(body, fiche_id)
            .then(() => {
                ficheServices.getFiche().then((response) => {
                    console.log(response.data)
                    let fiche = response.data
                    dispatch(
                        res.success(ficheConstants.GETFICHE_SUCCESS, fiche)
                    )
                })
            })
            .catch((error) => {
                dispatch(res.failure(ficheConstants.PUTFICHE_FAILURE, error))
            })
    }
}
