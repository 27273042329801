export function immoNeufCalcule(data) {
    let fixed_emolument = data.dataQuotation.fixed_emoluments.find(
        (fixed_emolument) => fixed_emolument.id === 41
    )
    let result = {
        prix_fixe: fixed_emolument.amount,
        tranche: [],
    }
    let total = fixed_emolument.amount
    //FIND HOUSE'S PRICE
    const input_commun_prix_ancien = parseInt(
        data.reponse_qualifications.find(
            (element) => element.qualification.id === 48
        ).reponse,
        10
    )
    //FIND QUALIFICATION REPONSE NBR DE LOT
    const nbrt_de_lot_id = parseInt(
        data.reponse_qualifications.find(
            (element) => element.qualification.id === 47
        ).choice_qualification_id,
        10
    )
    // FIND TRANCHE TO SELECT
    const tranche_by_nbr_de_lot =
        fixed_emolument.tranche_fixed_emoluments.filter(
            (element) => element.choice_qualification_id === nbrt_de_lot_id
        )
    tranche_by_nbr_de_lot.map((tranche) => {
        if (tranche.max === null) {
            if (input_commun_prix_ancien > tranche.min) {
                let result_max =
                    ((input_commun_prix_ancien - tranche.min) * tranche.rate) /
                    100
                let a = `Plus de ${tranche.min}`
                const obj = {
                    key: a,
                    value: result_max,
                }
                result.tranche.push(obj)
                total += obj.value
            } else {
                let a = `Plus de ${tranche.min}`
                const obj = {
                    key: a,
                    value: 0.0,
                }
                result.tranche.push(obj)
                total += obj.value
            }
        }
        if (input_commun_prix_ancien > tranche.max && tranche.max != null) {
            let lol = ((tranche.max - tranche.min) * tranche.rate) / 100
            let a = `${tranche.min} - ${tranche.max}`
            let obj = {
                key: a,
                value: lol,
            }
            result.tranche.push(obj)
            total += obj.value
        } else if (
            input_commun_prix_ancien > tranche.min &&
            input_commun_prix_ancien < tranche.max
        ) {
            let lol =
                ((input_commun_prix_ancien - tranche.min) * tranche.rate) / 100
            let a = `${tranche.min} - ${tranche.max}`
            let obj = {
                key: a,
                value: lol,
            }
            result.tranche.push(obj)
            total += obj.value
        } else if (input_commun_prix_ancien < tranche.min) {
            let b = `${tranche.min} - ${tranche.max}`
            result.tranche[b] = 0.0
            let obj = {
                key: b,
                value: 0.0,
            }
            result.tranche.push(obj)
            total += obj.value
        }
    })
    result.total = total
    result.name = fixed_emolument.name
    result.id = fixed_emolument.id
    return result
}
