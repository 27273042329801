import React, {Component} from 'react';


class ListDossiersHeader extends Component{
  render(){
    return(
      <div className="mb-4">
        <div className="d-flex flex-column flex-md-row justify-content-between">
          <div>
            <p className="h1 mb-1">Liste des dossiers</p>
          </div>
        </div>
      </div>

    )
  }
}
export default ListDossiersHeader;
