import React from 'react'
import HorrairesForm from './HorrairesForm/HorrairesForm'
import Modal from '../../../components/Modal/Modal'

const FicheHorrairesHeader = () => {
    return (
        <div className="mb-3">
            <div className="d-flex flex-column flex-md-row justify-content-between">
                <div>
                    <p className="h1 mb-2 mb-md-5">
                        Les Horaires de votre étude
                    </p>
                </div>
                <Modal
                    id="AddHorraire"
                    btnStyle="btn btn-secondary"
                    btnTitle="Ajouter un horaire"
                >
                    <HorrairesForm />
                </Modal>
            </div>
        </div>
    )
}

export default FicheHorrairesHeader
