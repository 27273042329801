import { calculImmo } from './Taxes/Immobilier/calculImmo'
import { calculMariage } from './Taxes/calculMariage'
import { calculPACS } from './Taxes/calculPACS'

export function quotationTaxeComposition(data) {
    switch (data.category.id) {
        // Achat immobilier
        case 21:
            return calculImmo(data)
        case 3:
            return calculMariage(data)
        case 4:
            return calculPACS(data)
        default:
            return []
    }
}
