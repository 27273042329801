import { userConstants } from '../constants/user.constants'
import { userService } from '../services/user.service'
import { alertActions } from './alert.actions'
import { history } from '../helpers/history'
import { IntercomAPI } from 'react-intercom'

export const userActions = {
    login,
    logout,
    passwordReset,
}

function login(email, password) {
    return (dispatch) => {
        dispatch(request({ email }))

        userService.login(email, password).then(
            (user) => {
                dispatch(success(user))
                history.push('/leads')
            },
            (error) => {
                dispatch(failure(error.toString()))
                dispatch(alertActions.error(error.toString()))
            }
        )
    }

    function request(user) {
        return { type: userConstants.LOGIN_REQUEST, user }
    }
    function success(user) {
        return { type: userConstants.LOGIN_SUCCESS, user }
    }
    function failure(error) {
        return { type: userConstants.LOGIN_FAILURE, error }
    }
}

function logout() {
    userService.logout()
    IntercomAPI('shutdown')
    return { type: userConstants.LOGOUT }
}

export function passwordReset(body, callback) {
    const promise = userService
        .passwordReset(body, callback)
        .then((response) => response.json())
    return {
        type: userConstants.POSTNEW_PASSWORD,
        payload: promise,
    }
}

export function askNewPassword(body, callback) {
    const promise = userService
        .askNewPassword(body, callback)
        .then((response) => response.json())
    return {
        type: userConstants.ASKNEW_PASSWORD,
        payload: promise,
    }
}
