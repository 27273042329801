import { immoTaxeCalcule } from './ImmoAncienTaxeHelper'
import { csiTaxeCalcule } from './csiTaxeCalcule'
import { csiPretImmoTaxeCalcule } from './csiPretImmoTaxeHelper'
import { publiciteFonciereCalcule } from './publiciteFonciereTaxeHelper'

export function calculImmo(data) {
    const taxes = []
    const typeBien = data.reponse_qualifications.find(
        (response_qualification) =>
            response_qualification.qualification.id === 46
    ).reponse

    const pret =
        data.reponse_qualifications.find(
            (response_qualification) =>
                response_qualification.qualification.id === 49
        ).reponse === 'Oui un emprunt avec garantie hypothécaire'

    switch (typeBien) {
        case 'Un bien ancien':
            taxes.push(immoTaxeCalcule(data))
            break
        case 'Un bien neuf':
            taxes.push(publiciteFonciereCalcule(data))
            break
        default:
            return 'Mauvais calcule'
    }

    if (pret) {
        taxes.push(csiPretImmoTaxeCalcule(data))
    } else {
        taxes.push(csiTaxeCalcule(data))
    }

    return taxes
}
