import React from 'react';
import StarZero from '../../../../../../assets/images/star0.svg';

const RateStarZero = (props) => {


  return(
    <div className='img_star_capsule'>
      <StarZero className='img_star' alt="Logo" />
    </div>
    )
}

export default RateStarZero;
