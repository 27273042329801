import React, { Component } from 'react'
import { ratesAction } from '../../../actions/rate.actions'
// REDUX
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Loader from '../../../components/Loader'

class RatingSentList extends Component {
    componentDidMount() {
        if (this.props.ratesent.ratesent.length === 0) {
            this.props.getRatesSent()
        }
    }

    render() {
        const { loading, ratesent } = this.props.ratesent
        return loading ? (
            <Loader />
        ) : (
            <div className="mb-5">
                <div className="table-responsive">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th className="text-primary">
                                    <strong>E-mail</strong>
                                </th>
                                <th className="text-primary">
                                    <strong>Nom</strong>
                                </th>
                                <th className="text-primary">
                                    <strong>Prénom</strong>
                                </th>
                                <th className="text-primary">
                                    <strong>A noter</strong>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {ratesent.map((demande) => (
                                <tr key={demande.id}>
                                    <td>
                                        <p>{demande.email}</p>
                                    </td>
                                    <td>
                                        <p>{demande.nom}</p>
                                    </td>
                                    <td>
                                        <p>{demande.prenom}</p>
                                    </td>
                                    <td>
                                        <p>
                                            {demande.done ? (
                                                <span>✅</span>
                                            ) : (
                                                <span>❌</span>
                                            )}
                                        </p>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        ratesent: state.ratesent,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { getRatesSent: ratesAction.getRatesSent },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(RatingSentList)
