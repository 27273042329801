import React, {Component} from 'react';
//import NavBarWebsite from '../../components/NavBarWebsite/NavBarWebsite';
import {getStatVisitsWebsite} from '../../../actions/statistiques.actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip,ResponsiveContainer
} from 'recharts';

class TraficStatistiques extends Component {


  componentDidMount(){
    this.props.getStatVisitsWebsite();

  }

  render(){
    if(!this.props.stats){
      return(<p>Chargement...</p>)
    }
    return(
         <div className="mt-4" style = {{ width: '100%', height: 300 }}>
         <h3><strong>Visiteurs sur votre site perso:</strong></h3>
           <ResponsiveContainer>
              <AreaChart
                  data={this.props.stats}
                  margin={{
                    top: 10, right: 30, left: 0, bottom: 0,
                  }}
                >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Area type="monotone" dataKey="pageviews"  name="Visiteurs" stroke="#2287ff" fill="#2287ff" />
              </AreaChart>
           </ResponsiveContainer>
         </div>

    )
  }
}


function mapStateToProps(state) {
  return {
    stats: state.stats.statWebsite
  };
}

function mapDispatchToProps(dispatch){
  return bindActionCreators(
       {getStatVisitsWebsite: getStatVisitsWebsite}, dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(TraficStatistiques);
