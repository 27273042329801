import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

class UserHeader extends Component {
    render() {
        const { lead } = this.props.lead
        return (
            <div className="mb-5">
                <div className="d-flex">
                    <div>
                        <Link
                            to="/leads"
                            className="btn btn-light btn-lg rounded-circle mr-4"
                        >
                            <FontAwesomeIcon icon="angle-left" />
                        </Link>
                    </div>
                    <div>
                        <p className="h2">
                            {lead.nom} {lead.prenom}{' '}
                            <span className="badge-category">
                                {lead.category.category_name}
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return { lead: state.lead }
}

export default connect(mapStateToProps, null)(UserHeader)
