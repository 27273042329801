import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faCalendar } from '@fortawesome/free-solid-svg-icons'

class LinksProfil extends Component {
    render() {
        if (!this.props.fiche) {
            return <p>Chargement...</p>
        }
        return (
            <main
                className="dash-content col-12 col-md-9 col-xl-10"
                role="main"
            >
                <div className="py-5 px-2 px-sm-4">
                    <div className="mb-3">
                        <div className="d-flex flex-column flex-md-row justify-content-between">
                            <div>
                                <p className="h1 mb-2 mb-md-5">
                                    Vos liens utiles
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="shadow p-3 bg-white rounded mb-5">
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="d-flex align-items-center">
                                    <div className="mr-3">
                                        <p className="h2 text-secondary mb-0">
                                            <FontAwesomeIcon icon={faUser} />
                                        </p>
                                    </div>
                                    <div>
                                        <p className="h6 mb-0">
                                            Lien vers ma fiche Notre-Notaire
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 text-sm-right">
                                <a
                                    className="btn btn-sm btn-outline-secondary mt-3 mt-xl-0"
                                    href={`https://www.notre-notaire.fr/geos/${this.props.fiche.slug_geo}/notaire/${this.props.fiche.slug}`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Voir ma fiche
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    return {
        fiche: state.fiche,
    }
}

export default connect(mapStateToProps, null)(LinksProfil)
