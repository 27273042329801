import { APINotreNotaire } from '../helpers/AxiosAPI'

export const userEmolumentService = {
    getEmoluments,
    getUserEmoluments,
    createUserEmolument,
    deletUserEmolulment,
    getUserConsulting,
    createUserConsulting,
}

function getEmoluments() {
    return APINotreNotaire.get('emoluments')
}

function getUserEmoluments() {
    return APINotreNotaire.get('user_emoluments')
}

function createUserEmolument(body) {
    return APINotreNotaire.post(`user_emoluments`, body)
}

function deletUserEmolulment(id) {
    return APINotreNotaire.delete(`user_emoluments/${id}`)
}

function getUserConsulting() {
    return APINotreNotaire.get('get_consulting')
}

function createUserConsulting(body) {
    return APINotreNotaire.post(`consultings`, body)
}
