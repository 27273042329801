import React, {Component} from 'react';
import moment from "moment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


class RdvList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            active: 0,
        };

        this.plus = this.plus.bind(this);
        this.moin = this.moin.bind(this)
    }

    plus() {
        if ((this.props.demande.length - 2)> this.state.active) {
            this.setState({active : this.state.active+1})
        }
    }
    moin() {
        if (this.state.active > 0) {
            this.setState({active : this.state.active-1})
        }
    }


    render() {
        if(this.props.demande.length === 0){
            return (
                <p className='text-center'>Il n'y pas de rendez-vous pour l'instant</p>
            )
        }
        return (
            <div style={{"padding": "0 15px"}} className='row'>
                <button onClick={this.moin} className='col-lg-1 btn-rdv' style={{"padding": "0"}}> <FontAwesomeIcon icon={"angle-left"} /></button>
                <div className='col-lg-10 row justify-content-center' style={{"padding": "0"}}>
                    {this.props.demande.map((demandeone, index) =>
                        <div className={((this.state.active === index || (this.state.active+1) === index) ? 'show' : 'hidden') + ' fiche-rdv row col-5'} key={demandeone.id} style={{marginLeft: 0}}>
                            <div className='col-12'> Horaire du rendez-vous : <strong>{moment(demandeone.disponibility.start_time).locale('fr').format('LLLL')}</strong> </div>
                            <div className='col-6'>
                                Nom: {demandeone.nom} <br/>
                                Téléphone: {demandeone.telephone}
                            </div>
                            <div className='col-6'>
                                Prénom: {demandeone.prenom}<br/>

                            </div>
                            <div className='col-12'>
                                Mail: {demandeone.email} <br/>
                                Sujet : {demandeone.category.name}<br/>
                                Commentaire : <br/>
                                {demandeone.commentaire}
                            </div>
                        </div>
                    )}
                </div>
                <button onClick={this.plus} className='col-lg-1 btn-rdv' style={{"padding": "0"}} > <FontAwesomeIcon icon={"angle-right"} /> </button>
            </div>
        )
    }
}

export default RdvList;
