import { leadConstants } from '../constants/lead.constants'

const leadsState = {
    loading: true,
    leads: [],
    error: false,
}

export function leads(state = leadsState, action) {
    switch (action.type) {
        case leadConstants.LEADS_LOADING:
            return {
                ...state,
                loading: true,
            }
        case leadConstants.GETLEADS_SUCCESS:
            return {
                ...state,
                loading: false,
                leads: action.payload.data,
            }
        case leadConstants.POSTLEAD_SUCCESS:
            state.leads.unshift(action.payload.data)
            return {
                ...state,
                loading: false,
                leads: state.leads,
            }
        default:
            return state
    }
}

const leadState = {
    loading: true,
    lead: null,
    error: false,
}

export function lead(state = leadState, action) {
    switch (action.type) {
        case leadConstants.LEAD_LOADING:
            return {
                ...state,
                loading: true,
            }
        case leadConstants.GETLEAD_SUCCESS:
            return {
                ...state,
                loading: false,
                lead: action.payload.data,
            }
        case leadConstants.POSTSTATUT_SUCCESS:
            state.lead.lead_statuts.history_statut.unshift(action.payload.data)
            return {
                ...state,
                loading: false,
                lead: state.lead,
            }
        default:
            return state
    }
}
