import React, { Component } from 'react'
import QuotationsHeader from './QuotationsHeader/QuotationsHeader'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { quotationAction } from '../../../actions/quotation_requests.actions'
import RequestQuotationsList from './RequestQuotationsList/RequestQuotationsList'
import Loader from '../../../components/Loader'

class Quotations extends Component {
    componentDidMount() {
        this.props.getQuotationRequests()
    }

    render() {
        const { loading } = this.props.quotationsRequest

        return loading ? (
            <main
                className="dash-content col-12 col-md-9 col-xl-10 d-flex justify-content-center my-5"
                role="main"
            >
                <Loader />
            </main>
        ) : (
            <main
                className="dash-content col-12 col-md-9 col-xl-10"
                role="main"
            >
                <div className="py-5 px-2 px-sm-4">
                    <QuotationsHeader />
                    <div className="bloc_conseil mb-4">
                        <div>
                            <b>💡 Bon à savoir :</b>Cette liste affiche les
                            demandes de devis en attentes d'une réponse de votre
                            part, si vous acceptez de répondre au devis du
                            client celui-ci apparaîtra dans les devis envoyés et
                            en attente de réponse.
                        </div>
                    </div>
                    <RequestQuotationsList />
                </div>
            </main>
        )
    }
}
function mapStateToProps(state) {
    return {
        quotationsRequest: state.quotationsRequest,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { getQuotationRequests: quotationAction.getQuotationRequests },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Quotations)
