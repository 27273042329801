import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import { horraireAction } from '../../../../actions/horraires.actions'
import { bindActionCreators } from 'redux'
import { modalActions } from '../../../../actions/modal.actions'

class HorrairesForm extends Component {
    renderField(field) {
        return (
            <div>
                <label>{field.label}</label>
                <input type={field.type} {...field.input} />
            </div>
        )
    }
    onSubmit = (values) => {
        this.props.createHorraire(values)
        this.props.hideModal('AddHorraire')
    }

    render() {
        return (
            <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                <h2>Créer un horaire</h2>
                <div>
                    <label>Jour d'ouverture:</label>
                    <Field name="jour" component="select">
                        <option />
                        <option value="1">Lundi</option>
                        <option value="2">Mardi</option>
                        <option value="3">Mercredi</option>
                        <option value="4">Jeudi</option>
                        <option value="5">Vendredi</option>
                        <option value="6">Samedi</option>
                        <option value="7">Dimanche</option>
                    </Field>
                </div>
                <div className="row">
                    <div className="col">
                        <Field
                            label="Horraire d'ouverture le matin:"
                            name="open_morning"
                            type="time"
                            component={this.renderField}
                        />
                        <Field
                            label="Horraire d'ouverture l'après midi:"
                            name="open_afternoon"
                            type="time"
                            component={this.renderField}
                        />
                    </div>
                    <div className="col">
                        <Field
                            label="Horraire de fermeture le matin:"
                            name="close_morning"
                            type="time"
                            component={this.renderField}
                        />
                        <Field
                            label="Horraire de fermeture l'après midi:"
                            name="close_afternon"
                            type="time"
                            component={this.renderField}
                        />
                    </div>
                </div>
                <div>
                    <br />
                    <button
                        className="btn btn-secondary"
                        type="submit"
                        disabled={this.props.pristine || this.props.submitting}
                    >
                        Créer l'horaire
                    </button>
                </div>
            </form>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            createHorraire: horraireAction.createHorraire,
            hideModal: modalActions.hideModal,
        },
        dispatch
    )
}

export default reduxForm({ form: 'horraire' })(
    connect(null, mapDispatchToProps)(HorrairesForm)
)
