import { rateConstants } from '../constants/rate.constants'
import { rateService } from '../services/rate.services'
import { res } from '../helpers/dispatch'

export const ratesAction = {
    getRates,
    getRatesSent,
    visible_note,
}
export function getRates() {
    return (dispatch) => {
        dispatch(res.loading(rateConstants.RATES_LOADING))
        rateService
            .getRates()
            .then((response) => {
                let rates = response.data
                dispatch(res.success(rateConstants.GETRATES_SUCCESS, rates))
            })
            .catch((error) => {
                dispatch(res.failure(rateConstants.GETRATES_FAILURE, error))
            })
    }
}

export function postReplyRate(body, callback) {
    const promise = rateService.postReplyRate(body, callback)
    return {
        type: rateConstants.POSTREPLY_REQUEST,
        payload: promise,
    }
}

export function askForRating(body, callback) {
    const promise = rateService.askForRating(body, callback)
    return {
        type: rateConstants.ASKRATE_REQUEST,
        payload: promise,
    }
}

function visible_note(body, id) {
    return (dispatch) => {
        dispatch(res.loading(rateConstants.RATES_LOADING))
        rateService
            .setVisible(body, id)
            .then((response) => {
                let rates = response.data
                dispatch(
                    res.success(rateConstants.PUTRATESVISIBLE_SUCCESS, rates)
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(rateConstants.PUTRATESVISIBLE_FAILURE, error)
                )
            })
    }
}

export function getRatesSent() {
    return (dispatch) => {
        dispatch(res.loading(rateConstants.RATESSENT_LOADING))
        rateService
            .getRatesSent()
            .then((response) => {
                let rates = response.data
                dispatch(res.success(rateConstants.GETRATESSENT_SUCCESS, rates))
            })
            .catch((error) => {
                dispatch(res.failure(rateConstants.GETRATESSENT_FAILURE, error))
            })
    }
}
