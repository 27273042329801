import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import { leadsAction } from '../../../../actions/lead.actions'
import { bindActionCreators } from 'redux'
import { modalActions } from '../../../../actions/modal.actions'

class StatutForm extends Component {
    constructor(props) {
        super(props)

        this.onSubmit = this.onSubmit.bind(this)
    }

    onSubmit(values) {
        this.props.hideModal('AddStatut')
        this.props.updateStatut(values, this.props.lead.lead.id)
    }

    render() {
        return (
            <form
                onSubmit={this.props.handleSubmit(this.onSubmit)}
                name="lead_statut"
            >
                <h2 className="text-center">Changer le statut</h2>
                <div>
                    <label>Statut du prospect : </label>
                    <div>
                        <Field name="statut_id" component="select">
                            <option value="2">En cours de traitement</option>
                            <option value="3">Succès</option>
                            <option value="4">Abandon</option>
                        </Field>
                    </div>
                </div>
                <div className="mt-2">
                    <label>Commentaire : </label>
                    <div>
                        <Field name="commentaire" component="textarea" />
                    </div>
                </div>
                <div className="mt-2 text-center">
                    <button className="btn btn-secondary" type="submit">
                        Ajouter l'information
                    </button>
                </div>
            </form>
        )
    }
}

function mapStateToProps(state) {
    return { lead: state.lead }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            updateStatut: leadsAction.updateStatut,
            hideModal: modalActions.hideModal,
        },
        dispatch
    )
}

export default reduxForm({ form: 'statut' })(
    connect(mapStateToProps, mapDispatchToProps)(StatutForm)
)
