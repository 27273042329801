export function csiTaxeCalcule(data) {
    // GET PRIX DU BIEN
    let taxe = data.dataQuotation.taxes.find((t) => t.id === 35)
    const input_commun_prix_ancien = parseInt(
        data.reponse_qualifications.find(
            (response_qualification) =>
                response_qualification.qualification.id === 48
        ).reponse,
        10
    )
    // Check if price mobilier is here
    let input_prix_mobilier = 0.0
    if (
        data.reponse_qualifications.includes(
            (response_qualification) =>
                response_qualification.qualification.id === 52
        )
    ) {
        // GET PRICE MOBILIER
        input_prix_mobilier = parseInt(
            data.reponse_qualifications.find(
                (response_qualification) =>
                    response_qualification.qualification.id === 52
            ).reponse,
            10
        )
    }

    // GET VALEUR MOBILIER MAX
    const valeur_max_mobilier = (20 * input_commun_prix_ancien) / 100
    // CALCULE PRIX TOTAL POUR EMOLUMENT
    const prix_total_pour_emolument =
        input_prix_mobilier < valeur_max_mobilier
            ? input_commun_prix_ancien - input_prix_mobilier
            : input_commun_prix_ancien
    if (taxe.rate != null) {
        taxe.amount = (prix_total_pour_emolument * taxe.rate) / 100
    }
    return taxe
}
