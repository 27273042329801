export const statistiquesConstants = {
    STAT_LOADING: 'STAT_LOADING',
    GETSTATVISITES_SUCCESS: 'GET_STAT_VISITES_SUCCESS',
    GETSTATSRADAR_SUCCESS: 'GET_STATS_RADAR_SUCCESS',
    GETSTATSCOREHISTORY_SUCCESS: 'GET_STAT_SCORE_HISTORY_SUCCESS',
    GETPOSITIONFICHE_SUCCESS: 'GET_POSITION_FICHE_SUCCESS',
    GETSTATVISITESWEBSITE_SUCCESS: 'GET_STAT_VISITES_WEBSITE_SUCCESS',
    GETSTATUNIQUEWEBSITE_SUCCESS: 'GET_STAT_UNIQUE_WEBSITE_SUCCESS',
    GETSTATSPAGESWEBSITE_SUCCESS: 'GET_STATS_PAGES_WEBSITE_SUCCESS',
    GETSTATVISITES_FAILURE: 'GET_STAT_VISITES_FAILURE',
    GETSTATSRADAR_FAILURE: 'GET_STATS_RADAR_FAILURE',
    GETSTATSCOREHISTORY_FAILURE: 'GET_STAT_SCORE_HISTORY_FAILURE',
    GETPOSITIONFICHE_FAILURE: 'GET_POSITION_FICHE_FAILURE',
    GETSTATVISITESWEBSITE_FAILURE: 'GET_STAT_VISITES_WEBSITE_FAILURE',
    GETSTATUNIQUEWEBSITE_FAILURE: 'GET_STAT_UNIQUE_WEBSITE_FAILURE',
    GETSTATSPAGESWEBSITE_FAILURE: 'GET_STATS_PAGES_WEBSITE_FAILURE',
}
