import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import reduxPromise from 'redux-promise'

//Style
import '../assets/stylesheets/application.scss'
import { reducers } from './reducers/store'

import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'

//Internet Explorer
import 'react-app-polyfill/ie9'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

library.add(fas)

import RouterApp from './route/RouterApp'
let middlewares = applyMiddleware(reduxPromise, thunkMiddleware)

if (process.env.API_AUTH.includes('localhost')) {
    let loggerMiddleware = createLogger()
    middlewares = applyMiddleware(
        reduxPromise,
        thunkMiddleware,
        loggerMiddleware
    )
}

ReactDOM.render(
    <Provider store={createStore(reducers, {}, middlewares)}>
        <RouterApp />
    </Provider>,
    document.getElementById('root')
)
