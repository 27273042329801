import React, { Component, Fragment } from 'react'
import { devisCompositionAction } from '../../../../../actions/devis_composition.actions'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class TaxeDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            toggle: false,
            total: 0,
        }

        this.toggleChange = this.toggleChange.bind(this)
    }

    componentDidMount() {
        const data = {
            reponse_qualifications:
                this.props.quotationRequest.reponse_qualifications,
            category: this.props.quotationRequest.category,
            dataQuotation: this.props.dataQuotation,
        }

        this.props.taxeDevis(data)
    }

    toggleChange() {
        this.setState({ toggle: !this.state.toggle })
    }

    render() {
        const { taxes } = this.props.devisComposition
        return taxes.length === 0 ? null : (
            <Fragment>
                <div className="border-bottom py-2">
                    <div className="d-flex bd-highlight">
                        <div className="bd-highlight mr-2 my-auto">
                            <button
                                className="btn btn-light rounded-circle"
                                onClick={this.toggleChange}
                            >
                                <FontAwesomeIcon
                                    icon={
                                        this.state.toggle
                                            ? 'angle-down'
                                            : 'angle-right'
                                    }
                                />
                            </button>
                        </div>
                        <span className="bd-highlight badge badge-danger h4 my-2">
                            Taxes et droits
                        </span>
                        {this.state.toggle ? null : (
                            <div className="ml-auto bd-highlight h4 my-2">
                                {this.props.devisComposition.taxes
                                    .reduce((a, b) => a + b.amount, 0)
                                    .toFixed(2)}
                                € HT
                            </div>
                        )}
                    </div>
                </div>
                {this.state.toggle ? (
                    <Fragment>
                        {taxes.map((taxe) => (
                            <div className="border-bottom py-2" key={taxe.id}>
                                <div className="d-flex align-items-center">
                                    <div className="mr-2">{taxe.name}</div>
                                    <div className="ml-auto">
                                        <b>{taxe.amount.toFixed(2)}€ </b>HT
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div className="border-bottom py-2">
                            <div className="d-flex align-items-center">
                                <div className="mr-2">
                                    <b>Total taxes et droits</b>
                                </div>
                                <div className="ml-auto">
                                    <b>
                                        {this.props.devisComposition.taxes
                                            .reduce((a, b) => a + b.amount, 0)
                                            .toFixed(2)}
                                        €{' '}
                                    </b>
                                    HT
                                </div>
                            </div>
                        </div>
                    </Fragment>
                ) : null}
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        devisComposition: state.devisComposition,
        quotationRequest: state.quotationRequest.quotationRequest,
        dataQuotation: state.dataQuotation.dataQuotation,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { taxeDevis: devisCompositionAction.taxeDevis },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(TaxeDetail)
