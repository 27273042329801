import React, { Component } from 'react'
import { connect } from 'react-redux'
import RequestQuotationCard from '../RequestQuotationCard/RequestQuotationCard'

class RequestQuotationsList extends Component {
    render() {
        const { quotationsRequest } = this.props.quotationsRequest
        return (
            <div className="">
                {quotationsRequest.map((devis) => (
                    <RequestQuotationCard devis={devis} key={devis.id} />
                ))}
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        quotationsRequest: state.quotationsRequest,
    }
}

export default connect(mapStateToProps, null)(RequestQuotationsList)
