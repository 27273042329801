import React, { Component, Fragment } from 'react'
import Template1 from '../../../../assets/images/template-1.png'
import Template2 from '../../../../assets/images/template-2.png'
import Template3 from '../../../../assets/images/template-3.png'
import { updateWebsite } from '../../../actions/website.actions'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import { Link } from 'react-router-dom'
import ColorBoxField from '../../../components/Input/ColorBoxField'

class WebSiteFormUpdate extends Component {
    constructor(props) {
        super(props)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleSubmit(event) {
        event.preventDefault()
        this.props.updateWebsite(
            { color: this.state.background, live: true },
            this.props.website.id
        )
        window.location.reload()
    }

    render() {
        const website = this.props.initialValues
        let templates = {
            1: (
                <img
                    src={Template1}
                    style={{ width: '800px', padding: '10px', margin: '30px' }}
                />
            ),
            2: (
                <img
                    src={Template3}
                    style={{ width: '800px', padding: '10px', margin: '30px' }}
                />
            ),
            3: (
                <img
                    src={Template2}
                    style={{ width: '800px', padding: '10px', margin: '30px' }}
                />
            ),
        }
        return (
            <Fragment>
                <div className="title_website">
                    <div className="bloc_conseil">
                        <div>
                            <b>💡 Bon à savoir :</b> à la création, le
                            déploiement de votre site web prendra quelques
                            heures.
                            <br /> Nous vous enverrons un e-mail quand ce
                            dernier sera en ligne 🎉
                            <br />
                            Sur le menu à gauche, vous pouvez modifier les pages
                            principales du site (page d'accueil, office, équipe
                            et articles).
                            <ul>
                                <li>
                                    Pour modifier vos horaires dans la page
                                    "Nous contacter"{' '}
                                    <b>
                                        <Link
                                            className="link_conseil"
                                            to="/fiche/horaires"
                                        >
                                            rendez-vous ici
                                        </Link>
                                    </b>
                                </li>
                                <li>
                                    Pour modifier vos spécialités dans la page
                                    "Office"{' '}
                                    <b>
                                        <Link
                                            className="link_conseil"
                                            to="/fiche"
                                        >
                                            rendez-vous sur votre fiche
                                        </Link>
                                    </b>
                                </li>
                                <li>
                                    Les contacts provenant de votre site web
                                    seront directement accessibles{' '}
                                    <b>
                                        <Link
                                            className="link_conseil"
                                            to="/users"
                                        >
                                            dans la liste des prospects.
                                        </Link>
                                    </b>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <br />
                <form onSubmit={this.handleSubmit} id="info_form">
                    <h4> Nom de domaine:</h4>
                    <div className="text-center mb-5">
                        <h3>
                            <a
                                href={'http://' + website.domain_name}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {website.domain_name}
                            </a>
                        </h3>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <h4>Model de site:</h4>
                            <div className="theme_choice text-center">
                                {templates[website.template_id]}
                            </div>
                        </div>
                        <div className="col-12">
                            <h4>
                                Choix de la couleur de votre site (modifiable à
                                tout moment):
                            </h4>
                            <Field name="color" component={ColorBoxField} />
                        </div>
                    </div>
                    <div className="text-right mt-5">
                        <button
                            type="button"
                            id="info_form"
                            onClick={this.props.handleSubmit(this.handleSubmit)}
                            className="btn btn-primary"
                        >
                            Enregistrer les modifications
                        </button>
                    </div>
                </form>
            </Fragment>
        )
    }
}

export default reduxForm({ form: 'update_webiste' })(
    connect(null, { updateWebsite })(WebSiteFormUpdate)
)
