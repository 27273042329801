import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getRooms } from '../../../../actions/room.actions'
import VisioAddForm from './VisioAdd/VisioAddForm'
import VisioAddModal from './VisioAdd/VisioAddModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVideo } from '@fortawesome/free-solid-svg-icons'

class VisioCard extends Component {
    componentDidMount() {
        this.props.getRooms()
    }
    render() {
        return (
            <Fragment>
                {this.props.rooms.map((room) => (
                    <div
                        key={room.id}
                        className="shadow p-3 bg-white rounded mb-5"
                    >
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="d-flex align-items-center">
                                    <div className="mr-3">
                                        <p className="h2 text-secondary mb-0">
                                            <FontAwesomeIcon icon={faVideo} />
                                        </p>
                                    </div>
                                    <div>
                                        <p className="h6 mb-0">{room.name}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 text-sm-right">
                                <VisioAddModal>
                                    <VisioAddForm room={room} />
                                </VisioAddModal>
                                <Link
                                    className="btn btn-sm btn-outline-secondary mt-3 mt-xl-0"
                                    to={`visio/${room.id}?name=${room.fiche.nom}`}
                                    target="blank"
                                >
                                    Rejoindre la chambre
                                </Link>
                            </div>
                        </div>
                    </div>
                ))}
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        rooms: state.rooms,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getRooms: getRooms }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(VisioCard)
