import { ficheConstants } from '../constants/fiche.constants'

const ficheState = {
    loading: true,
    fiche: {},
    error: false,
}

export function fiche(state = ficheState, action) {
    switch (action.type) {
        case ficheConstants.FICHE_LOADING:
            return {
                ...state,
                loading: true,
            }
        case ficheConstants.GETFICHE_SUCCESS:
            return {
                ...state,
                loading: false,
                fiche: action.payload.data,
            }

        case ficheConstants.PUTFICHE_SUCCESS:
            return {
                ...state,
                loading: false,
                fiche: action.payload.data,
            }
        default:
            return state
    }
}
