export function calculMariage(data) {
    let taxes = data.dataQuotation.taxes.filter(
        (fixed_emolument) => fixed_emolument.category_id === 3
    )

    let taxes_Mariage = []

    taxes.map((taxe) => {
        taxes_Mariage.push({
            amount: taxe.amount,
            total: taxe.amount,
            name: taxe.name,
        })
    })

    return taxes_Mariage
}
