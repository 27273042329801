import React, { Component, Fragment } from 'react'
import NavBar from '../NavBar/NavBar'
import TopBar from '../TopBar/TopBar'
class Layout extends Component {
    render() {
        return (
            <Fragment>
                <TopBar />
                <div className="container-fluid">
                    <div className="row">
                        <NavBar url={this.props.match.url} />
                        {this.props.children}
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Layout
