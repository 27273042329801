/// CREATION DU DEVIS
import { devisServices } from '../services/devis.services'
import { devisConstants } from '../constants/devis.constants'
import { res } from '../helpers/dispatch'

export const devisAction = {
    getQuotations,
    createQuotation,
}

export function createQuotation(body, callback) {
    const promise = devisServices.createQuotation(body, callback)
    return {
        type: devisConstants.CREATEDEVIS_REQUEST,
        payload: promise,
    }
}

export function getQuotations() {
    return (dispatch) => {
        dispatch(res.loading(devisConstants.DEVIS_LOADING))
        devisServices
            .getQuotations()
            .then((response) => {
                let lead = response.data
                dispatch(res.success(devisConstants.GETDEVIS_SUCCESS, lead))
            })
            .catch((error) => {
                dispatch(res.failure(devisConstants.GETDEVIS_FAILURE, error))
            })
    }
}

export function existQuotation(idQuotationRequest) {
    return (dispatch) => {
        dispatch(res.loading(devisConstants.DEVIS_LOADING))
        devisServices
            .getQuotations()
            .then((response) => {
                let quotations = response.data
                let quotation = quotations.find((quotation) => quotation.quotation_request.id == idQuotationRequest)
                dispatch(res.success(devisConstants.GETDEVIS_SUCCESS, quotation))
            })
            .catch((error) => {
                console.log(error)
                dispatch(res.failure(devisConstants.GETDEVIS_FAILURE, error))
            })
    }
}
