import React, { Component } from 'react'
import { connect } from 'react-redux'
import TraficStatistiques from './TraficStatistiques/TraficStatistiques'
import StatistiquesPages from './StatistiquesPages/StatistiquesPages'
class StatistiquesWebsite extends Component {
    render() {
        if (!this.props.fiche.website) {
            return (
                <main
                    className="dash-content col-12 col-md-9 col-xl-10"
                    role="main"
                >
                    <div className="py-5 px-2 px-sm-4">
                        <div className="mb-3">
                            <div className="d-flex flex-column flex-md-row justify-content-between">
                                <div>
                                    <p className="h1 mb-2 mb-md-5">
                                        Vos statistiques de votre site perso
                                    </p>
                                </div>
                            </div>
                        </div>
                        <h4>
                            Vous devez créer votre site perso pour avoir accès
                            aux données.
                        </h4>
                    </div>
                </main>
            )
        }
        return (
            <main
                className="dash-content col-12 col-md-9 col-xl-10"
                role="main"
            >
                <div className="py-5 px-2 px-sm-4">
                    <div className="mb-3">
                        <div className="d-flex flex-column flex-md-row justify-content-between">
                            <div>
                                <p className="h1 mb-2 mb-md-5">
                                    Vos statistiques de votre site perso
                                </p>
                            </div>
                        </div>
                    </div>
                    <TraficStatistiques />
                    <br />
                    <br />
                    <StatistiquesPages />
                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    return {
        fiche: state.fiche,
    }
}
export default connect(mapStateToProps, null)(StatistiquesWebsite)
