import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { DateTime } from 'luxon'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { quotationAction } from '../../../../actions/quotation_requests.actions'

class RequestQuotationCard extends Component {
    render() {
        return (
            <div className="shadow p-3 mb-4 bg-white rounded">
                <div className="row mb-4">
                    <div className="col-6">
                        <h4 className="text-primary mb-3">
                            Demande de devis n°
                            {this.props.devis.id}{' '}
                            <span className="badge badge-category">
                                {this.props.devis.category.name}
                            </span>
                        </h4>
                        <p className="mb-3">
                            <b>Validité:</b> Il reste{' '}
                            {DateTime.fromISO(this.props.devis.created_at)
                                .plus({
                                    days: 7,
                                })
                                .diff(DateTime.now(), 'days')
                                .toObject()
                                .days.toFixed(0)}{' '}
                            jours
                        </p>
                        <p className="mb-3">
                            <b>Nombre de devis effectué:</b>{' '}
                            {this.props.devis.nbquotation}
                        </p>
                        {this.props.devis.reponse_qualifications.map(
                            (qualif) => (
                                <p className="mb-3" key={qualif.id}>
                                    <b>{qualif.qualification.question}</b>{' '}
                                    {qualif.reponse}
                                </p>
                            )
                        )}
                    </div>
                    <div className="col-6 text-right">
                        <span className="h4">
                            {this.props.devis.geo.nom} -{' '}
                            {this.props.devis.geo.code_postal}
                        </span>
                        <br/>
                        <span>
                            <b>Date de création : </b>{DateTime.fromISO(this.props.devis.created_at).setLocale('fr').toFormat('dd/LL/yyyy')}
                        </span>
                    </div>
                </div>
                <div className="py-2">
                    <div className="d-flex bd-highlight">
                        <div className="mr-auto p-2 bd-highlight my-auto h4">
                            <FontAwesomeIcon
                                icon="check"
                                className="text-secondary"
                            />
                            <b className="pl-2">Demande vérifiée</b>
                        </div>
                        <div className="p-2 bd-highlight">
                            <button
                                className="btn btn-md btn-danger mt-3 mt-xl-0"
                                onClick={() =>
                                    this.props.refuseQuotationRequests(
                                        this.props.devis.id
                                    )
                                }
                            >
                                Refuser la demande
                            </button>
                        </div>
                        <div className="p-2 bd-highlight">
                            <Link
                                to={`/devis/create/${this.props.devis.token}`}
                                className="btn btn-md btn-secondary mt-3 mt-xl-0"
                            >
                                Créer un devis
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            refuseQuotationRequests: quotationAction.refuseQuotationRequests,
        },
        dispatch
    )
}

export default connect(null, mapDispatchToProps)(RequestQuotationCard)
