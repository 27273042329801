import { horrairesConstants } from '../constants/horraires.constants'
import { horrairesService } from '../services/horraire.services'
import { res } from '../helpers/dispatch'

export const horraireAction = {
    createHorraire,
    getHorraires,
    deleteHorraire,
}

function createHorraire(horraire) {
    return (dispatch) => {
        dispatch(res.loading(horrairesConstants.HORRAIRE_LOADING))
        horrairesService
            .createHorraire(horraire)
            .then((response) => {
                let horraire = response.data
                dispatch(
                    res.success(
                        horrairesConstants.POSTHORRAIRE_SUCCESS,
                        horraire
                    )
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(horrairesConstants.POSTHORRAIRE_FAILURE, error)
                )
            })
    }
}

function getHorraires() {
    return (dispatch) => {
        dispatch(res.loading(horrairesConstants.HORRAIRE_LOADING))
        horrairesService
            .getHorraires()
            .then((response) => {
                let horraire = response.data
                dispatch(
                    res.success(
                        horrairesConstants.GETHORRAIRES_SUCCESS,
                        horraire
                    )
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(horrairesConstants.GETHORRAIRES_FAILURE, error)
                )
            })
    }
}

function deleteHorraire(id) {
    return (dispatch) => {
        dispatch(res.loading(horrairesConstants.HORRAIRE_LOADING))
        horrairesService
            .deleteHorraire(id)
            .then(() => {
                dispatch(
                    res.success(horrairesConstants.DELETEHORRAIRE_SUCCESS, id)
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(
                        horrairesConstants.DELETEHORRAIRE_FAILURE,
                        error
                    )
                )
            })
    }
}
