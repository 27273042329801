import React, { Component, Fragment} from 'react';
import VideoComponent from './VideoComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {getRoom} from '../../../actions/room.actions';

class VisioShow extends Component {

 componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    if (!this.props.room) {
      this.props.getRoom(this.props.match.params.id, query.get('name'));
    }
    }
  getProprio(){
     if(this.props.room.identity == this.props.room.proprio){

    }
  }
  render(){
      if (!this.props.room) {
     return <p>Loading...</p>;
    }
    return(
      <Fragment>
        <VideoComponent jwt={this.props.room.jwt} name={this.props.room.identity}/>
      </Fragment>
    )
  }
}

function mapStateToProps(state, ownProps) {
  const id = parseInt(ownProps.match.params.id, 10);
  const room = state.rooms.find(room => room.id === id);
  return {room};
}


function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getRoom }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(VisioShow);
