import { dossiersServices } from '../services/dossiers.services'
import { dossiersConstants } from '../constants/dossiers.constants'
import { res } from '../helpers/dispatch'

export function getDossiers() {
    return (dispatch) => {
        dispatch(res.loading(dossiersConstants.DOSSIERS_LOADING))
        dossiersServices
            .getDossiers()
            .then((response) => {
                let dossiers = response.data
                dispatch(
                    res.success(dossiersConstants.GETDOSSIERS_SUCCESS, dossiers)
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(dossiersConstants.GETDOSSIERS_FAILURE, error)
                )
            })
    }
}
