import React, { Component } from 'react'
//Components
import RatingHeader from './RatingHeader/RatingHeader'
import RatingList from './RatingList/RatingList'

class Rating extends Component {
    render() {
        return (
            <main
                className="dash-content col-12 col-md-9 col-xl-10"
                role="main"
            >
                <div className="py-5 px-2 px-sm-4">
                    <RatingHeader />
                    <RatingList />
                </div>
            </main>
        )
    }
}

export default Rating
