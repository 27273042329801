import React, { Component, Fragment } from 'react'
import FicheAgendaHeader from './FicheAgendaHeader'
import Agenda from './Agenda/Agenda'
import Rdv from './RDV/Rdv'
import { bindActionCreators } from 'redux'
import { getAgenda } from '../../../actions/agenda.action'
import { connect } from 'react-redux'

import LoginAgenda from './LoginAgenda'
import Loader from '../../../components/Loader'

class FicheAgenda extends Component {
    constructor(props) {
        super(props)

        this.state = {
            events: [],
        }
    }

    componentDidMount() {
        this.props.getAgenda()
    }

    affichage() {
        switch (this.props.agenda) {
            case null:
                return <Loader />
            case 0:
                return <LoginAgenda />
            case 1:
                return (
                    <Fragment>
                        <br />
                        <h3 className="text-center">
                            {' '}
                            Votre demande est en cours de traitement par nos
                            équipes
                        </h3>
                    </Fragment>
                )
            default:
                return (
                    <Fragment>
                        <FicheAgendaHeader />
                        <Rdv />
                        <br />
                        <Agenda events={this.props.agenda} />
                    </Fragment>
                )
        }
    }

    render() {
        return (
            <main
                className="dash-content col-12 col-md-9 col-xl-10"
                role="main"
            >
                <div className="py-5 px-2 px-sm-4">{this.affichage()}</div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    return {
        agenda: state.agenda,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getAgenda: getAgenda }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(FicheAgenda)
