import { leadConstants } from '../constants/lead.constants'
import { leadService } from '../services/lead.services'
import { res } from '../helpers/dispatch'

export const leadsAction = {
    createLead,
    getLeads,
    getLead,
    updateStatut,
}

function createLead(lead) {
    return (dispatch) => {
        dispatch(res.loading(leadConstants.LEADS_LOADING))
        leadService
            .createLead(lead)
            .then((response) => {
                let lead = response.data
                dispatch(res.success(leadConstants.POSTLEAD_SUCCESS, lead))
            })
            .catch((error) => {
                dispatch(res.failure(leadConstants.POSTLEAD_FAILURE, error))
            })
    }
}

function getLead(id) {
    return (dispatch) => {
        dispatch(res.loading(leadConstants.LEADS_LOADING))
        leadService
            .getLead(id)
            .then((response) => {
                let lead = response.data
                dispatch(res.success(leadConstants.GETLEAD_SUCCESS, lead))
            })
            .catch((error) => {
                dispatch(res.failure(leadConstants.GETLEAD_FAILURE, error))
            })
    }
}

function getLeads() {
    return (dispatch) => {
        dispatch(res.loading(leadConstants.LEADS_LOADING))
        leadService
            .getLeads()
            .then((response) => {
                let leads = response.data
                dispatch(res.success(leadConstants.GETLEADS_SUCCESS, leads))
            })
            .catch((error) => {
                dispatch(res.failure(leadConstants.GETLEADS_FAILURE, error))
            })
    }
}

function updateStatut(lead_statut, lead_id) {
    return (dispatch) => {
        dispatch(res.loading(leadConstants.LEAD_LOADING))
        leadService
            .updateStatut(lead_statut, lead_id)
            .then((response) => {
                let lead = response.data
                dispatch(res.success(leadConstants.POSTSTATUT_SUCCESS, lead))
            })
            .catch((error) => {
                dispatch(res.failure(leadConstants.POSTSTATUT_FAILURE, error))
            })
    }
}
