import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { quotationAction } from '../../../../actions/quotation_requests.actions'
import DetailQuotation from './DetailQuotation/DetailQuotation'
import ButtonsQuotation from './ButtonsQuotation/ButtonsQuotation'
import Loader from '../../../../components/Loader'

class Quotation extends Component {
    componentDidMount() {
        if (Object.keys(this.props.dataQuotation.dataQuotation).length === 0) {
            this.props.getDataQuotation(
                this.props.quotationRequest.quotationRequest.category_id
            )
        }
    }
    render() {
        const { loading } = this.props.dataQuotation
        return loading ? (
            <Loader />
        ) : (
            <div className="row">
                <div className="col-md-12">
                    <DetailQuotation />
                    <ButtonsQuotation />
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        dataQuotation: state.dataQuotation,
        quotationRequest: state.quotationRequest,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { getDataQuotation: quotationAction.getDataQuotation },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Quotation)
