import React, { Component } from 'react'
import { DateTime } from 'luxon'
import { connect } from 'react-redux'
class InfoQuotation extends Component {
    render() {
        const { quotationRequest } = this.props.quotationRequest
        return (
            <div className="row">
                <div className="col-sm-12 mb-5">
                    <div className="bloc_conseil mb-4">
                        <div>
                            <b>Remarque: </b>
                            <br />
                            Vous avez des commentaires sur les calculs
                            automatiques des émoluments et des taxes, pour nous
                            aider a améliorer la plateforme. <br />
                            Vous pouvez cliquer{' '}
                            <a
                                href="https://tally.so/r/nr6p5n"
                                target="_blank"
                                rel="noreferrer"
                            >
                                ici
                            </a>{' '}
                            pour accéder au formulaire.
                        </div>
                    </div>
                    <div className="shadow p-3 bg-white rounded h-100">
                        <p className="h6 text-primary mb-3">
                            Informations devis
                        </p>
                        <ul className="list-unstyled mb-0">
                            <li className="mb-1">
                                Catégorie :
                                <strong>
                                    {' '}
                                    {quotationRequest.category.name}
                                </strong>
                            </li>
                            <li className="mb-1">
                                Date de la demande :
                                <strong>
                                    {' ' +
                                        DateTime.fromISO(
                                            quotationRequest.created_at
                                        ).toLocaleString(DateTime.DATE_MED)}
                                </strong>
                            </li>
                            {quotationRequest.reponse_qualifications.map(
                                (qualification) => {
                                    if (!qualification.qualification.question) {
                                        return <p>Chargement...</p>
                                    } else {
                                        return (
                                            <li key={qualification.id}>
                                                {
                                                    qualification.qualification
                                                        .question
                                                }{' '}
                                                <strong>
                                                    {' '}
                                                    {qualification.reponse}
                                                </strong>
                                            </li>
                                        )
                                    }
                                }
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return { quotationRequest: state.quotationRequest }
}

export default connect(mapStateToProps, null)(InfoQuotation)
