import React, { Component } from 'react'
//Components
import RatingSentHeader from './RatingSentHeader/RatingSentHeader'
import RatingSentList from './RatingSentList/RatingSentList'

class RatingSent extends Component {
    render() {
        return (
            <main
                className="dash-content col-12 col-md-9 col-xl-10"
                role="main"
            >
                <div className="py-5 px-2 px-sm-4">
                    <RatingSentHeader />
                    <RatingSentList />
                </div>
            </main>
        )
    }
}

export default RatingSent
