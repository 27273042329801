import { APINotreNotaire } from '../helpers/AxiosAPI'

export const devisServices = {
    createQuotation,
    getQuotations,
}

function createQuotation(body) {
    return APINotreNotaire.post('quotations', body)
}

function getQuotations() {
    return APINotreNotaire.get('quotations')
}
