import React, { Component, Fragment } from 'react'
import { reduxForm, Field } from 'redux-form'
import CheckBoxField from '../../../../components/Input/CheckBoxField'
import CkEditorField from '../../../../components/Input/CkEditorField'
import InputPhotoField from '../../../../components/Input/InputPhotoField'

class ArticleForm extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { handleSubmit } = this.props

        return (
            <Fragment>
                <div className="container">
                    <div className="bloc_conseil_seo">
                        <p>
                            <b>🥇 Conseils référencement:</b>
                        </p>
                        <ul>
                            <li>
                                Ne copier-coller surtout pas un article déjà
                                existant ou du existant ou du contenu déjà
                                existant sur Internet, votre article doit être
                                unique
                            </li>
                            <li>Traiter un seul sujet par article.</li>
                            <li>
                                Ayez un titre relativement court. (maximum 8/9
                                mots)
                            </li>
                            <li>Soignez votre introduction.</li>
                            <li>
                                Pour être pris en compte par Google, votre
                                article doit contenir au moins 750 mots.
                            </li>
                            <li>
                                Essayez de rajouter une illustration/image.
                                <b>
                                    {' '}
                                    <a
                                        href="https://morguefile.com/photos/morguefile/4/pop"
                                        target="blank"
                                    >
                                        Ici un site avec des photos
                                        d'illustrations gratuites
                                    </a>
                                </b>
                            </li>
                            <li>
                                Créer des paragraphes avec des titres (Format:
                                "Titre 2" pour les titres et format: "Normal"
                                pour les paragraphes){' '}
                            </li>
                        </ul>
                    </div>
                    <form
                        onSubmit={handleSubmit}
                        name="article"
                        className="redactor"
                    >
                        <div className="">
                            <div className="article_form_title">
                                <Field
                                    name="title"
                                    component="input"
                                    className="fiche_update"
                                    placeholder="Ici votre titre"
                                />
                            </div>
                        </div>
                        <div className="mt-4">
                            <label className="mr-2"> Visible </label>
                            <Field name="online" component={CheckBoxField} />
                            <div className="mt-4">
                                <Field
                                    component={CkEditorField}
                                    name="content"
                                    editorConfig={{
                                        toolbar: [
                                            {
                                                name: 'document',
                                                items: [
                                                    '-',
                                                    'Preview',
                                                    'Print',
                                                    '-',
                                                    'Templates',
                                                ],
                                            },
                                            {
                                                name: 'clipboard',
                                                items: [
                                                    'Cut',
                                                    'Copy',
                                                    'Paste',
                                                    'PasteText',
                                                    'PasteFromWord',
                                                    '-',
                                                    'Undo',
                                                    'Redo',
                                                ],
                                            },
                                            {
                                                name: 'editing',
                                                items: [
                                                    'Find',
                                                    'Replace',
                                                    '-',
                                                    'SelectAll',
                                                    '-',
                                                    'Scayt',
                                                ],
                                            },
                                            {
                                                name: 'forms',
                                                items: [
                                                    'Form',
                                                    'Checkbox',
                                                    'Radio',
                                                    'TextField',
                                                    'Textarea',
                                                    'Select',
                                                    'Button',
                                                    'ImageButton',
                                                    'HiddenField',
                                                ],
                                            },
                                            '/',
                                            {
                                                name: 'basicstyles',
                                                items: [
                                                    'Bold',
                                                    'Italic',
                                                    'Underline',
                                                    'Strike',
                                                    'Subscript',
                                                    'Superscript',
                                                    '-',
                                                    'CopyFormatting',
                                                    'RemoveFormat',
                                                ],
                                            },
                                            {
                                                name: 'paragraph',
                                                items: [
                                                    'NumberedList',
                                                    'BulletedList',
                                                    '-',
                                                    'Outdent',
                                                    'Indent',
                                                    '-',
                                                    'Blockquote',
                                                    'CreateDiv',
                                                    '-',
                                                    'JustifyLeft',
                                                    'JustifyCenter',
                                                    'JustifyRight',
                                                    'JustifyBlock',
                                                    '-',
                                                    'BidiLtr',
                                                    'BidiRtl',
                                                    'Language',
                                                ],
                                            },
                                            {
                                                name: 'links',
                                                items: [
                                                    'Link',
                                                    'Unlink',
                                                    'Anchor',
                                                ],
                                            },
                                            {
                                                name: 'insert',
                                                items: [
                                                    'Image',
                                                    'Flash',
                                                    'Table',
                                                    'HorizontalRule',
                                                    'Smiley',
                                                    'SpecialChar',
                                                    'PageBreak',
                                                    'Iframe',
                                                ],
                                            },
                                            '/',
                                            {
                                                name: 'styles',
                                                items: [
                                                    'Styles',
                                                    'Format',
                                                    'Font',
                                                    'FontSize',
                                                ],
                                            },
                                            {
                                                name: 'colors',
                                                items: ['TextColor', 'BGColor'],
                                            },
                                            {
                                                name: 'tools',
                                                items: [
                                                    'Maximize',
                                                    'ShowBlocks',
                                                ],
                                            },
                                        ],
                                        entities_latin: false,
                                        language: 'fr',
                                        uiColor: '#ffffff',
                                        format_tags: 'p;h2;h3;h4;h5;h6;pre',
                                    }}
                                />
                            </div>
                        </div>

                        <div className="">
                            <label className="label-file">
                                <b>Illustration de votre article:</b>
                            </label>
                            <Field
                                name="photo"
                                component={InputPhotoField}
                                style={{
                                    height: '340px',
                                    marginRight: 'auto',
                                    marginLeft: 'auto',
                                    display: 'block',
                                    paddingTop: '12px',
                                }}
                            />
                        </div>
                        <div className="text-right">
                            <button className="btn btn-primary" type="submit">
                                Enregistrer l'article
                            </button>
                        </div>
                    </form>
                </div>
            </Fragment>
        )
    }
}

export default reduxForm({
    form: 'article_form',
})(ArticleForm)
