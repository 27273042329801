import { articleConstants } from '../constants/article.constants'

const articlesState = {
    loading: true,
    articles: [],
    error: false,
}

export function articles(state = articlesState, action) {
    switch (action.type) {
        case articleConstants.ARTICLES_LOADING:
            return {
                ...state,
                loading: true,
            }
        case articleConstants.GETARTICLES_SUCCESS:
            return {
                ...state,
                loading: false,
                articles: action.payload.data,
            }
        case articleConstants.POSTARTICLE_SUCCESS:
            state.articles.push(action.payload.data)
            return {
                ...state,
                loading: false,
                articles: state.articles,
            }
        case articleConstants.PUTARTICLE_SUCCESS:
            // eslint-disable-next-line no-case-declarations
            let indexarticleUpdate = state.articles.findIndex(
                (article) => article.id === action.payload.data.id
            )
            state.articles.splice(indexarticleUpdate, 1, action.payload.data)
            return {
                ...state,
                loading: false,
                articles: state.articles,
            }
        default:
            return state
    }
}

export function article(state = [], action) {
    switch (action.type) {
        case articleConstants.GETARTICLE_REQUEST:
            return [action.payload]
        default:
            return state
    }
}
