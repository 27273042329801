import { immoAncienCalcule } from './ImmoAncien'
import { immoNeufCalcule } from './ImmoNeuf'
import { immoPretCalcule } from './immoPret'

export function calculImmo(data) {
    const emoluments = []
    const reponse = data.reponse_qualifications.find(
        (response_qualification) =>
            response_qualification.qualification.id === 46
    ).reponse

    const pret =
        data.reponse_qualifications.find(
            (response_qualification) =>
                response_qualification.qualification.id === 49
        ).reponse === 'Oui un emprunt avec garantie hypothécaire'

    switch (reponse) {
        case 'Un bien ancien':
            emoluments.push(immoAncienCalcule(data))
            break
        case 'Un bien neuf':
            emoluments.push(immoNeufCalcule(data))
            break
        default:
            return 'Mauvais calcule'
    }

    if (pret) {
        emoluments.push(immoPretCalcule(data))
    }

    return emoluments
}
