import React from 'react'
import Star from '../../../../../../assets/images/star1.svg'
const RateStars = () => {
    return (
        <div className="img_star_capsule">
            <Star className="img_star" alt="Logo" />
        </div>
    )
}

export default RateStars
