import React, {Component} from 'react';
import SignUp from "../../../../assets/images/Agenda/signup.png";
import {Field, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {token} from "../../../helpers/agendaHelper";
import {agendaServices} from '../../../services/agenda.services'
import Genapi from "../../../../assets/images/Agenda/genapi.png";
import Fiducial from "../../../../assets/images/Agenda/fiducial.png";

class LoginAgenda extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading : false,
            error : false,
            provider: "1"
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.checkboxHandler = this.checkboxHandler.bind(this);
        this.handleChangeProvider = this.handleChangeProvider.bind(this);
    }

    async onSubmit (value) {
        this.setState({loading : true});
        let access_token = await token(value, this.state.provider);
        if (access_token === 'error') {
            this.setState({loading : false, error : true});
        }else {
            agendaServices.updateAgenda(access_token);
            window.location.reload(false)
        }
    }

    checkboxHandler(e) {
        this.setState({
            checked: e.target.checked
        });
    }
    handleChangeProvider(event) {
        this.setState({provider: event.target.value});
    }


    render() {

        return (
                <div className='row justify-content-md-center'>
                    <div className='col-12 mt-5 mb-5'>
                        <h3 className='text-center'> Connectez-vous à votre compte mail : </h3>
                    </div>

                    <div className='col-8 row justify-content-md-center exchange-frame'>
                        <div className='col-12 text-center'>
                        <img src={'/'+SignUp} style={{width: "400px", height:'auto'}}/>
                        </div>
                        <div className='col-12 text-center' style={this.state.loading ?  {'display' : 'block'}: {'display' : 'none'}}>
                            <div className="lds-ring blue">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <h3><b>Chargement en cours...</b></h3>
                            <br/>
                        </div>
                        <div className='col-12 text-center' style={this.state.error ?  {'display' : 'block'}: {'display' : 'none'}}>
                            <p className='error'><b>Adresse mail ou mot de passe incorrect </b></p>
                            <p className='error'>Vérifier que le mot de passe saisie est celui utiliser pour vous connecter à votre solution logiciel</p>
                            <br/>
                        </div>

                        <form  onSubmit={this.props.handleSubmit(this.onSubmit)} className={'col-8'} style={this.state.loading ?  {'display' : 'none'}: {'display' : 'block'}}>
                            <div className="form-group">
                                <label><b>Choisir votre solution logiciel de votre office :</b></label>
                                <div className="text-center col-12">
                                    <div onChange={this.handleChangeProvider}>
                                        <label className="label_item"><input type="radio" value="1"  name="provider" defaultChecked className="provider"/><img src={'/'+Genapi} style={{width: "150px", padding: "10px", marginRight: "30px"}}/></label>
                                        <label className="label_item"><input type="radio" value="2" name="provider"  className="provider"/><img src={'/'+Fiducial} style={{width: "150px", padding: "10px"}}/></label>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label><b>Adresse mail :</b></label>
                                <Field name="mail" component="input" type='mail' className="login_agenda"/>
                            </div>
                            <div className="form-group">
                                <label><b>Mot de passe :</b></label>
                                <Field name="password" component="input" type='password' className="login_agenda"/>
                            </div>
                            <p className='text-center'><button className='btn btn-agenda' type="submit" disabled={this.state.loading}>Connexion</button></p>
                        </form>
                    </div>
                </div>
        )
    }
}

export default reduxForm({
    form: 'login_agenda', enableReinitialize: true
})(connect(null)(LoginAgenda));
