import React, { Component } from 'react'
import { history } from '../helpers/history'
import { Redirect, Route, Router, Switch } from 'react-router-dom'
import { PrivateRoute } from '../components/PrivateRoute'
import Quotations from '../containers/Quotation/Quotations/Quotations'
import CreateQuotationRequest from '../containers/Quotation/CreateQuotationRequest/CreateQuotationRequest'
import CreateQuotationQualification from '../containers/Quotation/CreateQuotationQualification/CreateQuotationQualification'
import CreateQuotation from '../containers/Quotation/CreateQuotation/CreateQuotation'
import ListQuotations from '../containers/Quotation/ListQuotations/ListQuotations'
import ListDossiers from '../containers/Quotation/ListDossiers/ListDossiers'
import Rating from '../containers/Rating/Rating'
import RatingSent from '../containers/Rating/RatingSent'
import UserShow from '../containers/UserShow/UserShow'
import Visio from '../containers/Visio/Visio'
import Leads from '../containers/Leads/Leads'
import Fiche from '../containers/Fiche/Fiche'
import WebSite from '../containers/WebSite/WebSite'
import HomePage from '../containers/WebSite/HomePage/HomePage'
import Office from '../containers/WebSite/Office/Office'
import Equipe from '../containers/WebSite/Equipe/Equipe'
import Articles from '../containers/WebSite/Articles/Articles'
import FicheHorraires from '../containers/Fiche/FicheHorraires/FicheHorraires'
import FicheAgenda from '../containers/Fiche/FicheAgenda/FicheAgenda'
import FicheTarif from '../containers/Fiche/FicheTarif/FicheTarif'
import Statistiques from '../containers/Statistiques/Statistiques'
import StatistiquesWebsite from '../containers/StatistiquesWebsite/StatistiquesWebsite'
import LinksProfil from '../containers/LinksProfil/LinksProfil'
import VisioShow from '../containers/Visio/VisioShow/VisioShow'
import App from '../components/App'
import Password from '../containers/Auth/Password/Password'
import NewPassword from '../containers/Auth/Password/NewPassword'
import ExistQuotation from '../containers/ExistQuotation/ExistQuotation'

class RouterApp extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <Router history={history}>
                <Switch>
                    <PrivateRoute exact path="/devis" component={Quotations} />
                    <PrivateRoute
                        exact
                        path="/devis/create"
                        component={CreateQuotationRequest}
                    />
                    <PrivateRoute
                        exact
                        path="/devis/create/:token/qualification"
                        component={CreateQuotationQualification}
                    />
                    <PrivateRoute
                        exact
                        path="/devis/create/:token"
                        component={CreateQuotation}
                    />
                    <PrivateRoute
                        exact
                        path="/devis/list"
                        component={ListQuotations}
                    />
                    <PrivateRoute
                        exact
                        path="/devis/dossiers"
                        component={ListDossiers}
                    />
                    <PrivateRoute exact path="/rating" component={Rating} />
                    <PrivateRoute
                        exact
                        path="/rating/envoyees"
                        component={RatingSent}
                    />
                    <PrivateRoute
                        exact
                        path="/leads/:id"
                        component={UserShow}
                    />
                     <PrivateRoute
                        exact
                        path="/havedevis/:id"
                        component={ExistQuotation}
                    />
                    <PrivateRoute exact path="/visio" component={Visio} />
                    <PrivateRoute exact path="/leads" component={Leads} />
                    <PrivateRoute exact path="/fiche" component={Fiche} />
                    <PrivateRoute exact path="/website" component={WebSite} />
                    <PrivateRoute
                        exact
                        path="/website/home_page"
                        component={HomePage}
                    />
                    <PrivateRoute
                        exact
                        path="/website/office"
                        component={Office}
                    />
                    <PrivateRoute
                        exact
                        path="/website/equipe"
                        component={Equipe}
                    />
                    <PrivateRoute
                        exact
                        path="/website/articles"
                        component={Articles}
                    />
                    <PrivateRoute
                        exact
                        path="/fiche/horaires"
                        component={FicheHorraires}
                    />
                    <PrivateRoute
                        exact
                        path="/fiche/agenda"
                        component={FicheAgenda}
                    />
                    <PrivateRoute
                        exact
                        path="/fiche/tarifs"
                        component={FicheTarif}
                    />
                    <PrivateRoute
                        exact
                        path="/stats"
                        component={Statistiques}
                    />
                    <PrivateRoute
                        exact
                        path="/stats/stat_website"
                        component={StatistiquesWebsite}
                    />
                    <PrivateRoute
                        exact
                        path="/links_profil"
                        component={LinksProfil}
                    />
                    <Route exact path="/visio/:id" component={VisioShow} />
                    <Route path="/login" exact component={App} />
                    <Route path="/password/:token" exact component={Password} />
                    <Route path="/new_password" exact component={NewPassword} />
                    <Route exact path="/">
                        <Redirect to="/leads" />
                    </Route>
                    <Route exact path="/users">
                        <Redirect to="/leads" />
                    </Route>
                </Switch>
            </Router>
        )
    }
}

export default RouterApp
