import { officeConstants } from '../constants/office.constants'
import { officeServices } from '../services/office.services'
import { res } from '../helpers/dispatch'

export const officeActions = {
    getOffice,
    updateOffice,
}

export function getOffice() {
    return (dispatch) => {
        dispatch(res.loading(officeConstants.OFFICE_LOADING))
        officeServices
            .getOffice()
            .then((response) => {
                let office = response.data
                dispatch(res.success(officeConstants.GETOFFICE_SUCCESS, office))
            })
            .catch((error) => {
                dispatch(res.failure(officeConstants.GETOFFICE_FAILURE, error))
            })
    }
}

function updateOffice(body, office_id) {
    return (dispatch) => {
        dispatch(res.loading(officeConstants.OFFICE_LOADING))
        officeServices
            .updateOffice(body, office_id)
            .then((response) => {
                let office = response.data
                dispatch(res.success(officeConstants.PUTOFFICE_SUCCESS, office))
            })
            .catch((error) => {
                dispatch(res.failure(officeConstants.PUTOFFICE_FAILURE, error))
            })
    }
}
