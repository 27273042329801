import React, { Component } from 'react'
import {
    articleActions,
    getArticles,
} from '../../../../actions/article.actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import ArticleCard from './ArticleCard'
import Loader from '../../../../components/Loader'

class ArticlesList extends Component {
    componentDidMount() {
        this.props.getArticles()
    }

    render() {
        const { loading, articles } = this.props.articles
        return loading ? (
            <Loader />
        ) : (
            <div className=" ">
                {articles.map((article) => (
                    <ArticleCard key={article.id} article={article} />
                ))}
                <br />
                <br />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        articles: state.articles,
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { getArticles: articleActions.getArticles },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticlesList)
