import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { quotationAction } from '../../../actions/quotation_requests.actions'
import HeaderQuotation from './HeaderQuotation/HeaderQuotation'
import Quotation from './Quotation/Quotation'
import InfoQuotation from './InfoQuotation/InfoQuotation'
import Loader from '../../../components/Loader'

class CreateQuotation extends Component {
    componentDidMount() {
        if (
            Object.keys(this.props.quotationRequest.quotationRequest).length ===
            0
        ) {
            this.props.getQuotation(this.props.match.params.token)
        }
    }

    componentWillUnmount() {
        this.props.resetQuotation()
    }

    render() {
        const { loading } = this.props.quotationRequest

        return loading ? (
            <Loader />
        ) : (
            <main
                className="dash-content col-12 col-md-9 col-xl-10"
                role="main"
            >
                <div className="py-5 px-2 px-sm-4">
                    <HeaderQuotation />
                    <InfoQuotation />
                    <Quotation />
                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    return { quotationRequest: state.quotationRequest }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getQuotation: quotationAction.getQuotation,
            resetQuotation: quotationAction.resetQuotation,
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateQuotation)
