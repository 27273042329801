import { categoryConstants } from '../constants/category.constants'
import { categoryServices } from '../services/category.services'
import { res } from '../helpers/dispatch'

export const categoriesAction = {
    getCategories,
}

function getCategories() {
    return (dispatch) => {
        dispatch(res.loading(categoryConstants.CATEGORIES_LOADING))
        categoryServices
            .getCategories()
            .then((response) => {
                let category = response.data
                dispatch(
                    res.success(
                        categoryConstants.GETCATEGORIES_SUCCESS,
                        category
                    )
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(categoryConstants.GETCATEGORIES_FAILURE, error)
                )
            })
    }
}

export function getCategoriesDevis() {
    return (dispatch) => {
        dispatch(res.loading(categoryConstants.CATEGORIESDEVIS_LOADING))
        categoryServices
            .getCategoriesDevis()
            .then((response) => {
                let categoryDevis = response.data
                dispatch(
                    res.success(
                        categoryConstants.GETCATEGORIESDEVIS_SUCCESS,
                        categoryDevis
                    )
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(
                        categoryConstants.GETCATEGORIESDEVIS_FAILURE,
                        error
                    )
                )
            })
    }
}
