import { roomConstants } from '../constants/room.constants';

export function room(state = [], action){
  switch (action.type){
    case roomConstants.GETROOMS_REQUEST:
      return action.payload;
    case roomConstants.GETROOM_REQUEST:
      return [action.payload];
    default:
      return state
  }

}
