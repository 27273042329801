export const userEmolumentConstants = {
    USEREMOLUMENT_LOADING: 'USER_EMOLUMENT_LOADING',
    POSTUSEREMOLUMENT_SUCCESS: 'POST_USER_EMOLUMENT_SUCCESS',
    POSTUSEREMOLUMENT_FAILURE: 'POST_USER_EMOLUMENT_FAILURE',
    GETUSEREMOLUMENTS_SUCCESS: 'GET_USER_EMOLUMENTS_SUCCESS',
    GETUSEREMOLUMENTS_FAILURE: 'GET_USER_EMOLUMENTS_FAILURE',
    DELETEUSEREMOLUMENT_SUCCESS: 'DELETE_USER_EMOLUMENT_SUCCESS',
    DELETEUSEREMOLUMENT_FAILURE: 'DELETE_USER_EMOLUMENT_FAILURE',
    EMOLUMENTS_LOADING: 'EMOLUMENTS_REQUEST',
    GETEMOLUMENTS_SUCCESS: 'GET_EMOLUMENTS_SUCCESS',
    GETEMOLUMENTS_FAILURE: 'GET_EMOLUMENTS_FAILURE',
    USERCONSULTING_LOADING: 'USER_CONSULTING_LOADING',
    POSTUSERCONSULTING_SUCCESS: 'POST_USER_CONSULTING_SUCCESS',
    POSTUSERCONSULTING_FAILURE: 'POST_USER_CONSULTING_FAILURE',
    GETUSERCONSULTING_SUCCESS: 'GET_USER_CONSULTING_SUCCESS',
    GETUSERCONSULTING_FAILURE: 'GET_USER_CONSULTING_FAILURE',
}
