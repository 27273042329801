import React, { Component } from 'react'

class CreateDevisHeader extends Component {
    render() {
        return (
            <div className="mb-4">
                <div className="d-flex flex-column flex-md-row justify-content-between">
                    <div>
                        <p className="h1 mb-1">Créer un devis</p>
                    </div>
                </div>
            </div>
        )
    }
}
export default CreateDevisHeader
