import React, { Component } from 'react'
import { getStatsRadar } from '../../../actions/statistiques.actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
    Radar,
    RadarChart,
    PolarGrid,
    PolarAngleAxis,
    PolarRadiusAxis,
} from 'recharts'

class StatProfil extends Component {
    componentDidMount() {
        if (Object.keys(this.props.stats_radar).length === 0) {
            this.props.getStatsRadar()
        }
    }

    render() {
        const data = [
            {
                subject: 'Specialitées',
                A: this.props.stats_radar.specialities,
                fullMark: 100,
            },
            {
                subject: 'Recommandations',
                A: this.props.stats_radar.rating_value,
                fullMark: 100,
            },
            {
                subject: 'Visite de la fiche',
                A: this.props.stats_radar.trafic,
                fullMark: 100,
            },
            {
                subject: 'Horaires',
                A: this.props.stats_radar.horaire,
                fullMark: 100,
            },
            {
                subject: 'Photo',
                A: this.props.stats_radar.photo,
                fullMark: 100,
            },
            {
                subject: 'Description',
                A: this.props.stats_radar.lexical_optimization,
                fullMark: 100,
            },
            {
                subject: 'Nbr de mots',
                A: this.props.stats_radar.nbr_word_description,
                fullMark: 100,
            },
        ]
        return (
            <div className="mt-4">
                <h3>
                    <strong>Points forts et axes d'améliorations:</strong>
                </h3>
                <RadarChart
                    outerRadius={150}
                    width={550}
                    height={550}
                    data={data}
                >
                    <PolarGrid />
                    <PolarAngleAxis dataKey="subject" />
                    <PolarRadiusAxis />
                    <Radar
                        name="Mike"
                        dataKey="A"
                        stroke="#048658"
                        fill="#1edd98"
                        fillOpacity={0.6}
                    />
                </RadarChart>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        stats_radar: state.stats.radar,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getStatsRadar: getStatsRadar }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(StatProfil)
