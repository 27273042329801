import React, {Component} from 'react';
import {Calendar, Views, momentLocalizer }from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css';

import moment from 'moment'



class Agenda extends Component {

    constructor(props) {
        super(props);
    }



    render() {

        return (
            <div>

                <Calendar
                    defaultView={Views.WEEK}
                    views={['week']}
                    navigate={'AAA'}
                    localizer={momentLocalizer(moment)}
                    events = {this.props.events}
                    titleAccessor = "subject"
                    culture='fr'
                    min= {new Date('1995-12-17T08:00:00')}
                    max= {new Date('1995-12-17T19:00:00')}
                    startAccessor="start"
                    endAccessor="end"
                />
            </div>
        )
    }
}

export default Agenda;
