import { fetchAPI } from './Api.jsx'
import { APINotreNotaire } from '../helpers/AxiosAPI'

export const rateService = {
    getRates,
    postReplyRate,
    askForRating,
    setVisible,
    getRatesSent,
}

function getRates() {
    return APINotreNotaire.get('notes')
}

function postReplyRate(body, callback) {
    return fetchAPI('reponses', 'POST', body, callback)
}

function askForRating(body, callback) {
    return fetchAPI('demande_notations', 'POST', body, callback)
}

function setVisible(body, id) {
    return APINotreNotaire.put('visible_note/' + id, body)
}

function getRatesSent() {
    return APINotreNotaire.get('demande_notations')
}
