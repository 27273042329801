import React, { Component, Fragment } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { horraireAction } from '../../../../actions/horraires.actions'
import Loader from '../../../../components/Loader'

class HorrairesCards extends Component {
    componentDidMount() {
        if (this.props.horraire.horraire.length === 0) {
            this.props.getHorraires()
        }
    }

    dateFormat(date) {
        const d = new Date(date)
        return date === null
            ? 'Fermée'
            : `${d.getUTCHours()}h${
                  d.getUTCMinutes() === 0
                      ? `${d.getUTCMinutes()}` + 0
                      : d.getUTCMinutes()
              }`
    }

    handleDelete(id) {
        this.props.deleteHorraire(id)
    }

    render() {
        const day = {
            1: 'Lundi',
            2: 'Mardi',
            3: 'Mercredi',
            4: 'Jeudi',
            5: 'Vendredi',
            6: 'Samedi',
            7: 'Dimanche',
        }

        const { loading, horraire } = this.props.horraire

        return loading ? (
            <Loader />
        ) : (
            <Fragment>
                {horraire.map((hor) => (
                    <div
                        key={hor.id}
                        className="shadow p-3 bg-white rounded mb-5"
                    >
                        <div className="row">
                            <div className="col-sm-6 pt-2">
                                <div className="d-flex align-items-center">
                                    <div className="mr-3">
                                        <p className="h6 mb-0 mr-4">
                                            {day[hor.jour]}
                                        </p>
                                    </div>
                                    <div>
                                        <p className="mb-0 mr-4">
                                            <u>Matin:</u>{' '}
                                            {this.dateFormat(hor.open_morning)}{' '}
                                            -{' '}
                                            {this.dateFormat(hor.close_morning)}
                                        </p>
                                    </div>
                                    <div>
                                        <p className="mb-0">
                                            <u>Après-midi:</u>{' '}
                                            {this.dateFormat(
                                                hor.open_afternoon
                                            )}{' '}
                                            -{' '}
                                            {this.dateFormat(
                                                hor.close_afternon
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 text-sm-right">
                                <a
                                    onClick={() => this.handleDelete(hor.id)}
                                    className="btn btn-sm btn-outline-danger mt-3 mt-sm-0"
                                >
                                    Supprimer
                                </a>
                            </div>
                        </div>
                    </div>
                ))}
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        horraire: state.horraire,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getHorraires: horraireAction.getHorraires,
            deleteHorraire: horraireAction.deleteHorraire,
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(HorrairesCards)
