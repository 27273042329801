export function calculTestament(data) {
    let id = null
    const type = data.reponse_qualifications.find(
        (element) => element.qualification.id === 41
    ).reponse
    switch (type) {
        case 'Authentique':
            id = 36
            break
        case 'Mystique':
            id = 37
            break
        case 'Olographe':
            id = 38
            break
        default:
            id = null
            break
    }
    let fixed_emolument = data.dataQuotation.fixed_emoluments.find(
        (fixed_emolument) => fixed_emolument.id === id
    )

    return [
        {
            prix_fixe: fixed_emolument.amount,
            total: fixed_emolument.amount,
            name: fixed_emolument.name,
        },
    ]
}
