import { APINotreNotaire } from '../helpers/AxiosAPI'

export const categoryServices = {
    getCategories,
    getCategoriesDevis,
}

function getCategories() {
    return APINotreNotaire.get('categories')
}

function getCategoriesDevis() {
    return APINotreNotaire.get('get_categories_devis')
}
