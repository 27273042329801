import React, { Component } from 'react'
//import NavBarWebsite from '../../components/NavBarWebsite/NavBarWebsite';
import { getStatVisits } from '../../../actions/statistiques.actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from 'recharts'

class StatVisits extends Component {
    componentDidMount() {
        if (Object.keys(this.props.stats).length === 0) {
            this.props.getStatVisits()
        }
    }

    render() {
        return (
            <div className="mt-4" style={{ width: '100%', height: 300 }}>
                <h3>
                    <strong>Trafic mensuel sur votre fiche:</strong>
                </h3>
                <ResponsiveContainer>
                    <AreaChart
                        data={this.props.stats}
                        margin={{
                            top: 10,
                            right: 30,
                            left: 0,
                            bottom: 0,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="month" />
                        <YAxis />
                        <Tooltip />
                        <Area
                            type="monotone"
                            dataKey="visites"
                            stroke="#2287ff"
                            fill="#2287ff"
                        />
                    </AreaChart>
                </ResponsiveContainer>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        stats: state.stats.profil,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getStatVisits: getStatVisits }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(StatVisits)
