import React, {Component} from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { postReplyRate } from '../../../actions/rate.actions';
import cx from 'classnames';

class RatingReplyForm extends Component {
   onSubmit = (values) => {
    values.note_id = this.props.note_id;
    this.props.postReplyRate(values, this.props.note_id)
    window.location.reload();
 };


  render(){
    return(
        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
            <div>
              <div>
                <Field name="content" component="textarea" />
              </div>
            </div>
            <div>
             <button className='btn btn-secondary' type="submit" disabled={this.props.pristine || this.props.submitting}>
                Répondre
              </button>
            </div>
        </form>
     )
   }
 }

  export default reduxForm({ form: 'reply_rate' })(
  connect(null, { postReplyRate })(RatingReplyForm)
);
