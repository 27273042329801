import { modalConstants } from '../constants/modal.constants'

const initialState = {
    id: null,
    modalOpen: null,
    hiddenClose: null,
}

export function modal(state = initialState, action) {
    switch (action.type) {
        case modalConstants.HIDE_MODAL:
            return {
                ...state,
                id: action.payload,
                modalOpen: false,
                hiddenClose: false,
            }
        case modalConstants.SHOW_MODAL:
            return {
                ...state,
                id: action.payload,
                modalOpen: true,
            }
        case modalConstants.HIDE_CLOSEMODAL:
            return {
                ...state,
                hiddenClose: true,
            }

        default:
            return state
    }
}
