export const quotationRequestsConstants = {
    QUOTATION_LOADING: 'QUOTATION_LOADING',
    POSTQUOTATION_SUCCESS: 'POST_QUOTATION_SUCCESS',
    POSTQUOTATION_FAILURE: 'POST_QUOTATION_FAILURE',
    GETQUOTATION_SUCCESS: 'GET_QUOTATION_SUCCESS',
    GETQUOTATION_FAILURE: 'GET_QUOTATION_FAILURE',
    DATAQUOTATION_LOADING: 'DATA_QUOTATION_LOADING',
    GETDATAQUOTATION_SUCCESS: 'GET_DATA_QUOTATION_SUCCESS',
    GETDATAQUOTATION_FAILURE: 'GET_DATA_QUOTATION_FAILURE',
    POSTREPONSEQUALIFICATION_SUCCESS: 'POST_REPONSE_QUALIFICATION_SUCCESS',
    POSTREPONSEQUALIFICATION_FAILURE: 'POST_REPONSE_QUALIFICATION_FAILURE',
    QUOTATIONS_LOADING: 'QUOTATIONS_LOADING',
    GETQUOTATIONS_SUCCESS: 'GET_QUOTATIONS_SUCCESS',
    GETQUOTATIONS_FAILURE: 'GET_QUOTATIONS_FAILURE',
    RESETQUOTATION_REQUEST: 'RESET_QUOTATION_REQUEST',
    REFUSEQUOTATION_SUCCESS: 'REFUSE_QUOTATION_SUCCESS',
    REFUSEQUOTATION_FAILURE: 'REFUSE_QUOTATION_FAILURE',
}
