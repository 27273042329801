import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import { getCategoriesDevis } from '../../../../actions/category.action'
import { quotationAction } from '../../../../actions/quotation_requests.actions'
import { history } from '../../../../helpers/history'

import { bindActionCreators } from 'redux'
import Loader from '../../../../components/Loader'

class InformationsUserForm extends Component {
    componentDidMount() {
        this.props.getCategoriesDevis()
    }

    onSubmit = (value) => {
        this.props.createQuotation(value)
    }

    componentDidUpdate() {
        if (this.props.quotationRequest.quotationRequest.token) {
            history.push(
                `/devis/create/${this.props.quotationRequest.quotationRequest.token}/qualification`
            )
        }
    }
    render() {
        const { loading, categoriesDevis } = this.props.categories
        return (
            <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                <div>
                    <label>Nom:</label>
                    <div>
                        <Field name="lastname" component="input" />
                    </div>
                </div>
                <div>
                    <label>Prénom:</label>
                    <div>
                        <Field name="firstname" component="input" />
                    </div>
                </div>
                <div>
                    <label>Téléphone:</label>
                    <div>
                        <Field name="phone_number" component="input" />
                    </div>
                </div>
                <div>
                    <label>Adresse e-mail:</label>
                    <div>
                        <Field name="email" component="input" />
                    </div>
                </div>
                {loading ? (
                    <Loader />
                ) : (
                    <div>
                        <label>Catégorie</label>
                        <div>
                            <Field name="category_id" component="select">
                                <option></option>
                                {categoriesDevis.map((cat) => (
                                    <option key={cat.id} value={cat.id}>
                                        {cat.name}
                                    </option>
                                ))}
                            </Field>
                        </div>
                    </div>
                )}
                <br />
                <div>
                    <button
                        className="btn btn-secondary"
                        type="submit"
                        disabled={this.props.pristine || this.props.submitting}
                    >
                        Créer un devis
                    </button>
                </div>
            </form>
        )
    }
}

function mapStateToProps(state) {
    return {
        categories: state.categoriesDevis,
        quotationRequest: state.quotationRequest,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getCategoriesDevis: getCategoriesDevis,
            createQuotation: quotationAction.createQuotation,
        },
        dispatch
    )
}

export default reduxForm({ form: 'create_devis' })(
    connect(mapStateToProps, mapDispatchToProps)(InformationsUserForm)
)
