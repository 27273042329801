import {fetchAPI} from './Api.jsx';

export const roomService = {
  createRoom,
  getRooms,
  getRoom,
  createRoomInvitation
}


function createRoom(room, callback){
  return fetchAPI("rooms", "POST", room, callback)
}

function getRooms(){
  return fetchAPI("rooms", "GET")
}

function getRoom(id, name) {
  return fetchAPI(`rooms/${id}?name=${name}`, "GET")
}

function createRoomInvitation(body, room_id, callback){
  return fetchAPI(`rooms/${room_id}/room_invitations`, "POST", body, callback)
}
