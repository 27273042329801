import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import { askForRating } from '../../../../actions/rate.actions'
import { bindActionCreators } from 'redux'
import { modalActions } from '../../../../actions/modal.actions'

class AskRatingForm extends Component {
    onSubmit = (value) => {
        this.props.askForRating(value)
        this.props.hideModal('AddRating')
    }
    render() {
        return (
            <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                <h2>Faire une demande d'avis</h2>
                <div>
                    <label>Nom:</label>
                    <div>
                        <Field name="nom" component="input" />
                    </div>
                </div>
                <div>
                    <label>Prénom:</label>
                    <div>
                        <Field name="prenom" component="input" />
                    </div>
                </div>
                <div>
                    <label>Adresse e-mail:</label>
                    <div>
                        <Field name="email" component="input" />
                    </div>
                </div>
                <div>
                    <button
                        className="btn btn-secondary"
                        type="submit"
                        disabled={this.props.pristine || this.props.submitting}
                    >
                        Envoyer la demande
                    </button>
                </div>
            </form>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            askForRating: askForRating,
            hideModal: modalActions.hideModal,
        },
        dispatch
    )
}

export default reduxForm({ form: 'ask_a_rate' })(
    connect(null, mapDispatchToProps)(AskRatingForm)
)
