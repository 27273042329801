import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { devisCompositionAction } from '../../../../../actions/devis_composition.actions'
import { Field, reduxForm } from 'redux-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class DiscountDetail extends Component {
    constructor(props) {
        super(props)

        this.state = {
            ligne_id: 0,
        }

        this.onSubmit = this.onSubmit.bind(this)
    }
    onSubmit(values) {
        values.ligne_id = this.state.ligne_id
        this.props.discountDevis(values)
        this.props.reset()
        this.setState({ ligne_id: this.state.ligne_id + 1 })
    }

    delete(id) {
        this.props.deleteDiscount(id)
    }

    render() {
        const { discounts } = this.props.devisComposition

        return (
            <Fragment>
                <div className="border-bottom py-2">
                    <div className="d-flex align-items-center">
                        <span className="badge badge-secondary h4 my-2">
                            Remises
                        </span>
                    </div>
                </div>
                {discounts.map((discount) => (
                    <div className="border-bottom py-2" key={discount.ligne_id}>
                        <div className="d-flex align-items-center">
                            <div className="mr-2">
                                <button
                                    className="btn btn-light rounded-circle"
                                    onClick={() =>
                                        this.delete(discount.ligne_id)
                                    }
                                >
                                    <FontAwesomeIcon icon="trash" />
                                </button>
                            </div>
                            <div className="mr-2">{discount.libelle}</div>
                            <div className="ml-auto">
                                <b>
                                    - {parseInt(discount.amount, 10).toFixed(2)}{' '}
                                </b>
                                € HT
                            </div>
                        </div>
                    </div>
                ))}

                <div className="border-bottom py-2">
                    <form>
                        <div className="row">
                            <div className="col-6">
                                <Field
                                    type="text"
                                    name="libelle"
                                    placeholder="Libellé (ex : Remise conseil)"
                                    component="input"
                                />
                            </div>
                            <div className="col-2">
                                <Field
                                    type="number"
                                    name="amount"
                                    placeholder="Valeur (ex: 20)"
                                    component="input"
                                />
                            </div>
                            <div className="col-2">
                                <Field
                                    className="form-control"
                                    name="type"
                                    component="select"
                                >
                                    <option value="1">€</option>
                                </Field>
                            </div>
                            <div className="col-2 text-center">
                                <button
                                    className="btn btn-outline-secondary mt-1"
                                    type="button"
                                    onClick={this.props.handleSubmit(
                                        this.onSubmit
                                    )}
                                >
                                    Ajouter
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    return { devisComposition: state.devisComposition }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            discountDevis: devisCompositionAction.discountDevis,
            deleteDiscount: devisCompositionAction.deleteDiscount,
        },
        dispatch
    )
}

export default reduxForm({ form: 'emolument_discount' })(
    connect(mapStateToProps, mapDispatchToProps)(DiscountDetail)
)
