import { APINotreNotaire } from '../helpers/AxiosAPI'

export const statistiquesServices = {
    getStatVisits,
    getStatsRadar,
    getStatScoreHistory,
    getPositionFiche,
    getStatVisitsWebsite,
    getStatVisitsUniqueWebsite,
    getStatPagessWebsite,
}

function getStatVisits() {
    return APINotreNotaire.get('get_trafic_dashboard')
}

function getStatsRadar() {
    return APINotreNotaire.get('get_radar_vision_profil')
}

function getStatScoreHistory() {
    return APINotreNotaire.get('get_historic_score')
}

function getPositionFiche() {
    return APINotreNotaire.get('get_current_moteur_position')
}

function getStatVisitsWebsite() {
    return APINotreNotaire.get('get_statistiques_pages_views')
}

function getStatVisitsUniqueWebsite() {
    return APINotreNotaire.get('get_statistiques_unique')
}

function getStatPagessWebsite() {
    return APINotreNotaire.get('trafic_by_pages')
}
