import React from 'react'

const FicheHeader = () => {
    return (
        <div>
            <div className="d-flex flex-column flex-md-row justify-content-between">
                <div>
                    <p className="h1 mb-md-5">Votre fiche sur Notre-Notaire</p>
                </div>
            </div>
        </div>
    )
}

export default FicheHeader
