import React, { Component } from 'react'
import WebSiteForm from './WebSiteForm/WebSiteForm'
import WebSiteFormUpdate from './WebSiteForm/WebSiteFormUpdate'
import { getWebsite } from '../../actions/website.actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Loader from '../../components/Loader'

class WebSite extends Component {
    componentDidMount() {
        this.props.getWebsite()
    }

    render() {
        const { loading, website } = this.props.website
        return loading ? (
            <Loader />
        ) : (
            <main
                className="dash-content col-12 col-md-9 col-xl-10"
                role="main"
            >
                <div className="py-5 px-2 px-sm-4">
                    <div className="title_website">
                        <h1>Configuration de votre site internet personnel</h1>
                    </div>
                    {website === null ? (
                        <WebSiteForm />
                    ) : (
                        <WebSiteFormUpdate initialValues={website} />
                    )}
                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    return {
        website: state.website,
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getWebsite }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(WebSite)
