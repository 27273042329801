import React from 'react'
import { NavLink } from 'react-router-dom'

const NavBarSecondaryItem = (props) => {
    return (
        <li>
            <NavLink activeClassName="selected_secondary" exact to={props.link}>
                {props.content}
            </NavLink>
        </li>
    )
}

export default NavBarSecondaryItem
