import React, { Component } from 'react'
import { connect } from 'react-redux'

class TotalDetail extends Component {
    render() {
        const { total_ht, total_ttc } = this.props.devisComposition
        return total_ttc === null || total_ht === null ? null : (
            <div className="border-bottom py-2">
                <div className="d-flex align-items-center">
                    <h3>
                        <span className="h2">Total Devis</span>
                    </h3>
                    <div className="ml-auto">
                        <br />
                        <h5>
                            <b>{total_ht.toFixed(2)}€ </b>
                            HT
                        </h5>
                        <h5>
                            <b>{total_ttc.toFixed(2)}€ </b>
                            TTC
                        </h5>
                    </div>
                </div>
                <div></div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return { devisComposition: state.devisComposition }
}

export default connect(mapStateToProps, null)(TotalDetail)
