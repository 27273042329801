export const articleConstants = {
    ARTICLES_LOADING: 'ARTICLES_LOADING',
    GETARTICLES_SUCCESS: 'GET_ARTICLES_SUCCESS',
    GETARTICLES_FAILURE: 'GET_ARTICLES_FAILURE',
    POSTARTICLE_SUCCESS: 'POST_ARTICLE_SUCCESS',
    POSTARTICLE_FAILURE: 'POST_ARTICLE_FAILURE',
    GETARTICLE_REQUEST: 'GET_ARTICLE_REQUEST',
    PUTARTICLE_SUCCESS: 'PUT_ARTICLE_SUCCESS',
    PUTARTICLE_FAILURE: 'PUT_ARTICLE_FAILURE',
}
