export function contratMariage(data, id) {
    let fixed_emolument = data.dataQuotation.fixed_emoluments.find(
        (fixed_emolument) => fixed_emolument.id === id
    )
    let result = {
        prix_fixe: fixed_emolument.amount,
        tranche: [],
    }

    let total = fixed_emolument.amount
    //result.abatement = action.abatement
    //TROUVER LA REPONSE QUALIFICATION ID POUR AVOIT L'INPUT DE VALEUR
    const input_commun = parseInt(
        data.reponse_qualifications.find(
            (element) => element.qualification.id === 2
        ).reponse,
        10
    )
    const restant_calcule = input_commun - fixed_emolument.abatement

    if (restant_calcule > 0) {
        fixed_emolument.tranche_fixed_emoluments.map((tranche) => {
            if (tranche.max === null) {
                if (restant_calcule > tranche.min) {
                    let result_max =
                        ((restant_calcule - tranche.min) * tranche.rate) / 100
                    let a = `Plus de ${tranche.min}`
                    const obj = {
                        key: a,
                        value: result_max,
                    }
                    result.tranche.push(obj)
                    total += obj.value
                } else {
                    let a = `Plus de ${tranche.min}`
                    const obj = {
                        key: a,
                        value: 0.0,
                    }
                    result.tranche.push(obj)
                    total += obj.value
                }
            }
            if (restant_calcule > tranche.max && tranche.max != null) {
                let lol = ((tranche.max - tranche.min) * tranche.rate) / 100
                let a = `${tranche.min} - ${tranche.max}`
                let obj = {
                    key: a,
                    value: lol,
                }
                result.tranche.push(obj)
                total += obj.value
            } else if (
                restant_calcule > tranche.min &&
                restant_calcule < tranche.max
            ) {
                let lol = ((restant_calcule - tranche.min) * tranche.rate) / 100
                let a = `${tranche.min} - ${tranche.max}`
                let obj = {
                    key: a,
                    value: lol,
                }
                result.tranche.push(obj)
                total += obj.value
            } else if (restant_calcule < tranche.min) {
                let b = `${tranche.min} - ${tranche.max}`
                result.tranche[b] = 0.0
                let obj = {
                    key: b,
                    value: 0.0,
                }
                result.tranche.push(obj)
                total += obj.value
            }
        })
    }
    result.total = total
    result.name = fixed_emolument.name
    result.id = fixed_emolument.id
    return [result]
}
