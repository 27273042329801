import { combineReducers } from 'redux'
import { authentication } from './auth'
import { alert } from './alert'
import { lead, leads } from './lead'
import { rate, ratesent } from './rate'
import { room } from './room'
import { reducer as formReducer } from 'redux-form'
import { categoriesDevis, categories } from './category'
import { fiche } from './fiche'
import { horraire } from './horraire'
import { website } from './website'
import { home } from './home'
import { office } from './office'
import { equipe } from './equipe'
import { teammate } from './teammate'
import { article, articles } from './article'
import { demande } from './demande'
import { agenda } from './agenda'
import { stats } from './stats'
import {
    dataQuotation,
    quotationRequest,
    quotationsRequest,
} from './quotationRequests'
import { devisComposition } from './devisComposition'
import { devis } from './devis'
import { consulting, emoluments, user_emoluments } from './user_emoluments'
import { dossiers } from './dossiers'
import { modal } from './modal'

export const reducers = combineReducers({
    authentication: authentication,
    modal: modal,
    alert: alert,
    articles: articles,
    leads: leads,
    lead: lead,
    rates: rate,
    ratesent: ratesent,
    rooms: room,
    form: formReducer,
    categories: categories,
    fiche: fiche,
    horraire: horraire,
    website: website,
    home: home,
    office: office,
    equipe: equipe,
    teammate: teammate,
    article: article,
    demande: demande,
    agenda: agenda,
    stats: stats,
    quotationRequest: quotationRequest,
    dataQuotation: dataQuotation,
    devisComposition: devisComposition,
    devis: devis,
    quotationsRequest: quotationsRequest,
    categoriesDevis: categoriesDevis,
    emoluments: emoluments,
    user_emoluments: user_emoluments,
    consulting: consulting,
    dossiers: dossiers,
})
