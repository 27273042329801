import { userEmolumentConstants } from '../constants/user_emoluments.constants'

const initialUserEmoluments = {
    loading: true,
    user_emoluments: [],
    error: false,
}

export function user_emoluments(state = initialUserEmoluments, action) {
    switch (action.type) {
        case userEmolumentConstants.USEREMOLUMENT_LOADING:
            return {
                ...state,
                loading: true,
            }
        case userEmolumentConstants.POSTUSEREMOLUMENT_SUCCESS:
            state.user_emoluments.push(action.payload.data)
            return {
                ...state,
                loading: false,
                user_emoluments: state.user_emoluments,
            }
        case userEmolumentConstants.GETUSEREMOLUMENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                user_emoluments: action.payload.data,
            }
        case userEmolumentConstants.DELETEUSEREMOLUMENT_SUCCESS:
            // eslint-disable-next-line no-case-declarations
            let indexDelete = state.user_emoluments.findIndex(
                (user_emolument) => user_emolument.id === action.payload.data
            )
            state.user_emoluments.splice(indexDelete, 1)
            return {
                ...state,
                loading: false,
                user_emoluments: state.user_emoluments,
            }
        default:
            return state
    }
}

const initialEmoluments = {
    loading: true,
    emoluments: [],
    error: false,
}

export function emoluments(state = initialEmoluments, action) {
    switch (action.type) {
        case userEmolumentConstants.EMOLUMENTS_LOADING:
            return {
                ...state,
                loading: true,
            }
        case userEmolumentConstants.GETEMOLUMENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                emoluments: action.payload.data,
            }
        default:
            return state
    }
}

const initialConsulting = {
    loading: true,
    consulting: {},
    error: false,
}

export function consulting(state = initialConsulting, action) {
    switch (action.type) {
        case userEmolumentConstants.USERCONSULTING_LOADING:
            return {
                ...state,
                loading: false,
            }
        case userEmolumentConstants.GETUSERCONSULTING_SUCCESS:
        case userEmolumentConstants.POSTUSERCONSULTING_SUCCESS:
            return {
                ...state,
                loading: false,
                consulting: action.payload.data,
            }
        default:
            return state
    }
}
