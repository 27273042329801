import React from 'react'
import RateStars from './RateStars/RateStars'
import RateStarZero from './RateStars/RateStarZero'
import useCollapse from 'react-collapsed'
import RatingReplyForm from '../../RatingReplyForm/RatingReplyForm'
import RatingVisible from './RatingVisible/RatingVisible'
import { DateTime } from 'luxon'

const RatingShow = (props) => {
    const { getCollapseProps, getToggleProps, isOpen } = useCollapse()

    function responseOrNot(props) {
        if (!props.data.reponse) {
            return (
                <div className="">
                    <a
                        className="btn btn-sm btn-outline-primary"
                        {...getToggleProps()}
                    >
                        {isOpen ? 'Fermer' : 'Répondre'}
                    </a>
                    <section {...getCollapseProps()}>
                        <div className="form_rating">
                            <RatingReplyForm
                                note_id={props.data.id}
                                id={props.data.id}
                            />
                        </div>
                    </section>
                </div>
            )
        } else {
            return (
                <div className="panel panel-primary margin-top-lg">
                    <div className="panel-heading">
                        <p className="h5 panel-title">Votre réponse:</p>
                    </div>
                    <div className="panel-body">
                        <p>{props.data.reponse.content}</p>
                    </div>
                </div>
            )
        }
    }

    return (
        <div className="shadow p-3 bg-white rounded mb-5">
            <div className="row">
                <div className="col-sm-4">
                    <div className="dash-rating mb-2">
                        {[...Array(props.data.moyenne.moyenne)].map((num) => (
                            <RateStars />
                        ))}
                        {[...Array(5 - props.data.moyenne.moyenne)].map(
                            (num) => (
                                <RateStarZero />
                            )
                        )}
                    </div>
                    <p className="text-muted mb-0">
                        <strong>{props.data.nom}</strong>
                    </p>
                    <p className="text-muted">{props.data.prenom}</p>
                </div>
                <div className="col-sm-8">
                    <div className="d-flex">
                        <div>
                            <p className="h3 mb-0">
                                {props.data.sujet_notation}
                            </p>
                            <p className="text-muted">
                                {props.data.commentaire_notation}
                            </p>
                        </div>
                        <div className="ml-auto text-right mb-4">
                            <p className="text-muted">
                                {DateTime.fromISO(
                                    props.data.created_at
                                ).toLocaleString(DateTime.DATETIME_MED)}
                            </p>
                            <RatingVisible
                                visible={props.data.visible}
                                id={props.data.id}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <div className="dash-rating mb-2">
                                Qualité :
                                {[...Array(props.data.note_qualite)].map(
                                    (num) => (
                                        <RateStars />
                                    )
                                )}
                                {[...Array(5 - props.data.note_qualite)].map(
                                    (num) => (
                                        <RateStarZero />
                                    )
                                )}
                            </div>
                            <div className="dash-rating mb-2">
                                Accueil :
                                {[...Array(props.data.note_accueil)].map(
                                    (num) => (
                                        <RateStars />
                                    )
                                )}
                                {[...Array(5 - props.data.note_accueil)].map(
                                    (num) => (
                                        <RateStarZero />
                                    )
                                )}
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="dash-rating mb-2">
                                Ponctualité :
                                {[...Array(props.data.note_ponctualite)].map(
                                    (num) => (
                                        <RateStars />
                                    )
                                )}
                                {[
                                    ...Array(5 - props.data.note_ponctualite),
                                ].map((num) => (
                                    <RateStarZero />
                                ))}
                            </div>
                            <div className="dash-rating mb-2">
                                Prestation :
                                {[...Array(props.data.note_communication)].map(
                                    (num) => (
                                        <RateStars />
                                    )
                                )}
                                {[
                                    ...Array(5 - props.data.note_communication),
                                ].map((num) => (
                                    <RateStarZero />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {responseOrNot(props)}
        </div>
    )
}

export default RatingShow
