import { contratMariage } from './contratMariage'
import { liquidation } from './liquidation'

export function calculMariage(data) {
    let id = null
    const type = data.reponse_qualifications.find(
        (element) => element.qualification.id === 35
    ).reponse

    switch (type) {
        case 'Une création de contrat de mariage':
            id = 1
            return contratMariage(data, id)
        case 'Un changement de régime matrimoniale':
            id = 35
            return liquidation(data, id)

        default:
            return null
    }
}
