import { websiteConstants } from '../constants/website.constants'

const initialWebsite = {
    loading: true,
    website: {},
    error: false,
}

export function website(state = initialWebsite, action) {
    switch (action.type) {
        case websiteConstants.WEBSITE_LOADING:
            return {
                ...state,
                loading: true,
            }
        case websiteConstants.GETWEBSITE_SUCCESS:
            return {
                ...state,
                loading: false,
                website: action.payload.data,
            }
        default:
            return state
    }
}
