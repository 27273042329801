import { quotationRequestsConstants } from '../constants/quotation_requests.constants'
import { quotationRequestsServices } from '../services/quotation_requests.services'
import { res } from '../helpers/dispatch'

export const quotationAction = {
    getQuotation,
    getDataQuotation,
    resetQuotation,
    createQuotation,
    getQuotationRequests,
    createReponseQualification,
    refuseQuotationRequests,
}

function createQuotation(body) {
    return (dispatch) => {
        dispatch(res.loading(quotationRequestsConstants.QUOTATION_LOADING))
        quotationRequestsServices
            .createQuotation(body)
            .then((response) => {
                let quotation = response.data
                dispatch(
                    res.success(
                        quotationRequestsConstants.POSTQUOTATION_SUCCESS,
                        quotation
                    )
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(
                        quotationRequestsConstants.POSTQUOTATION_FAILURE,
                        error
                    )
                )
            })
    }
}

function getQuotation(id) {
    return (dispatch) => {
        dispatch(res.loading(quotationRequestsConstants.QUOTATION_LOADING))
        quotationRequestsServices
            .getQuotationRequest(id)
            .then((response) => {
                let quotation = response.data
                dispatch(
                    res.success(
                        quotationRequestsConstants.GETQUOTATION_SUCCESS,
                        quotation
                    )
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(
                        quotationRequestsConstants.GETQUOTATION_FAILURE,
                        error
                    )
                )
            })
    }
}

function getDataQuotation(id) {
    return (dispatch) => {
        dispatch(res.loading(quotationRequestsConstants.DATAQUOTATION_LOADING))
        quotationRequestsServices
            .getDataQuotationRequest(id)
            .then((response) => {
                let dataQuotation = response.data
                dispatch(
                    res.success(
                        quotationRequestsConstants.GETDATAQUOTATION_SUCCESS,
                        dataQuotation
                    )
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(
                        quotationRequestsConstants.GETDATAQUOTATION_FAILURE,
                        error
                    )
                )
            })
    }
}

function createReponseQualification(id, body) {
    return (dispatch) => {
        dispatch(res.loading(quotationRequestsConstants.DATAQUOTATION_LOADING))
        quotationRequestsServices
            .createReponseQualification(id, body)
            .then((response) => {
                let reponseQualification = response.data
                dispatch(
                    res.success(
                        quotationRequestsConstants.POSTREPONSEQUALIFICATION_SUCCESS,
                        reponseQualification
                    )
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(
                        quotationRequestsConstants.POSTREPONSEQUALIFICATION_FAILURE,
                        error
                    )
                )
            })
    }
}

function getQuotationRequests() {
    return (dispatch) => {
        dispatch(res.loading(quotationRequestsConstants.QUOTATIONS_LOADING))
        quotationRequestsServices
            .getQuotationRequests()
            .then((response) => {
                let reponseQualification = response.data
                dispatch(
                    res.success(
                        quotationRequestsConstants.GETQUOTATIONS_SUCCESS,
                        reponseQualification
                    )
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(
                        quotationRequestsConstants.GETQUOTATIONS_FAILURE,
                        error
                    )
                )
            })
    }
}

function refuseQuotationRequests(id) {
    return (dispatch) => {
        dispatch(res.loading(quotationRequestsConstants.QUOTATIONS_LOADING))
        quotationRequestsServices
            .refuseQuotationRequests(id)
            .then(() => {
                dispatch(
                    res.success(
                        quotationRequestsConstants.REFUSEQUOTATION_SUCCESS,
                        id
                    )
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(
                        quotationRequestsConstants.REFUSEQUOTATION_FAILURE,
                        error
                    )
                )
            })
    }
}

function resetQuotation() {
    return (dispatch) => {
        dispatch(res.loading(quotationRequestsConstants.RESETQUOTATION_REQUEST))
    }
}
