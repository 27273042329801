import { officeConstants } from '../constants/office.constants'

const officeState = {
    loading: true,
    office: null,
    error: false,
}

export function office(state = officeState, action) {
    switch (action.type) {
        case officeConstants.OFFICE_LOADING:
            return {
                ...state,
                loading: true,
            }
        case officeConstants.GETOFFICE_SUCCESS:
            return {
                ...state,
                office: action.payload.data,
                loading: false,
            }
        case officeConstants.PUTOFFICE_SUCCESS:
            return {
                ...state,
                office: action.payload.data,
                loading: false,
            }
        default:
            return state
    }
}
