import { devisConstants } from '../constants/devis.constants'

const initialDevis = {
    loading: true,
    devis: [],
    error: false,
}

export function devis(state = initialDevis, action) {
    switch (action.type) {
        case devisConstants.DEVIS_LOADING:
            return {
                ...state,
                loading: true,
            }
        case devisConstants.GETDEVIS_SUCCESS:
            return {
                ...state,
                loading: false,
                devis: action.payload.data,
            }
        default:
            return state
    }
}
