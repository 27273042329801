import { authHeader } from '../helpers/auth-header';
import {IntercomAPI} from "react-intercom";
import sha256 from 'crypto-js/hmac-sha256';

export const userService = {
    login,
    logout,
    passwordReset,
    askNewPassword
};

function login(email, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ user: {email, password }})
    };

    return fetch(`${process.env.API_AUTH}/login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));
            IntercomAPI('update', {user_id: user.id, email: user.email, user_hash: sha256(user.id.toString(), process.env.HASH_INTERCOM.toString()).toString()});
            return user;
        })
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function handleResponse(response) {
   const token = response.headers.get('Authorization');
      return response.text()
       .then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        data.token = token;
        return data;
    });
}

function passwordReset(body, callback){
  const requestOptions = {
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      method: 'PATCH',
      body: JSON.stringify(body)
  };
  return fetch(`${process.env.API_AUTH}/password`, requestOptions)
}

function askNewPassword(body, callback){
  const requestOptions = {
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      method: 'POST',
      body: JSON.stringify(body)
  };
 return fetch(`${process.env.API_AUTH}/password`, requestOptions)
}
