import { articleConstants } from '../constants/article.constants'
import { articleServices } from '../services/article.services'
import { res } from '../helpers/dispatch'

export const articleActions = {
    getArticles,
    createArticle,
    updateArticle,
}

function createArticle(body) {
    return (dispatch) => {
        dispatch(res.loading(articleConstants.ARTICLES_LOADING))
        articleServices
            .postArticle(body)
            .then((response) => {
                let article = response.data
                dispatch(
                    res.success(articleConstants.POSTARTICLE_SUCCESS, article)
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(articleConstants.POSTARTICLE_FAILURE, error)
                )
            })
    }
}

function getArticles(id) {
    return (dispatch) => {
        dispatch(res.loading(articleConstants.ARTICLES_LOADING))
        articleServices
            .getArticles(id)
            .then((response) => {
                let articles = response.data
                dispatch(
                    res.success(articleConstants.GETARTICLES_SUCCESS, articles)
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(articleConstants.GETARTICLES_FAILURE, error)
                )
            })
    }
}

function updateArticle(body, article_id) {
    return (dispatch) => {
        dispatch(res.loading(articleConstants.ARTICLES_LOADING))
        articleServices
            .updateArticle(body, article_id)
            .then((response) => {
                let article = response.data
                dispatch(
                    res.success(articleConstants.PUTARTICLE_SUCCESS, article)
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(articleConstants.PUTARTICLE_SUCCESS, error)
                )
            })
    }
}
