import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { createRoom } from '../../../../../actions/room.actions';

class RoomForm extends Component {

   onSubmit = (values) => {
      this.props.createRoom(values)
          window.location.reload();
          return room;
      };

  render(){
    return(
          <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
           <h2>Créer une chambre de visio-conférence</h2>
              <div>
                <label>Nom de la chambre</label>
                <div>
                  <Field name="name" component="input" placeholder= "Renseigner le nom de votre client" />
                </div>
              </div>
              <div>
               <button className='btn btn-secondary' type="submit" disabled={this.props.pristine || this.props.submitting}>
                  Créer la chambre
                </button>
              </div>
          </form>
    )
  }
}


export default reduxForm({ form: 'room' })(
  connect(null, { createRoom })(RoomForm)
);
