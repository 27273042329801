import React, { Component, Fragment } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { devisCompositionAction } from '../../../../../actions/devis_composition.actions'
import Modal from '../../../../../components/Modal/Modal'
import ConsultingForm from '../../../../Fiche/FicheTarif/TarifConsulting/AddConsulting/ConsultingForm'

class ConsultingDetail extends Component {
    render() {
        return (
            <Fragment>
                <div className="border-bottom py-2">
                    <div className="d-flex align-items-center">
                        <span className="badge badge-info h4 my-2">
                            Honoraires Conseil
                        </span>
                    </div>
                </div>
                {this.props.dataQuotation.consulting === null ? (
                    <div className="border-bottom py-2">
                        <div className="text-center">
                            <Modal
                                id="s"
                                btnStyle="btn btn-secondary"
                                btnTitle="Ajouter des honoraires"
                            >
                                <ConsultingForm />
                            </Modal>
                        </div>
                    </div>
                ) : (
                    <div className="border-bottom py-2">
                        <div className="form-group text-center">
                            Nombre d'heures:
                            <br />
                            <a
                                className="btn btn-light btn-md rounded-circle mr-2"
                                onClick={() =>
                                    this.props.consultingDevisRemove(
                                        this.props.dataQuotation.consulting
                                    )
                                }
                            >
                                <FontAwesomeIcon icon="minus" />
                            </a>
                            <span style={{ fontSize: '25px' }}>
                                {this.props.devisComposition.hours_consulting}
                            </span>
                            <a
                                className="btn btn-light btn-md rounded-circle ml-2"
                                onClick={() =>
                                    this.props.consultingDevisAdd(
                                        this.props.dataQuotation.consulting
                                    )
                                }
                            >
                                <FontAwesomeIcon icon="plus" />
                            </a>
                        </div>
                    </div>
                )}
                {this.props.devisComposition.hours_consulting === 0 ? null : (
                    <div className="border-bottom py-2">
                        <div className="d-flex align-items-center">
                            <div className="mr-2">
                                <b>
                                    heures de conseils:{' '}
                                    {
                                        this.props.devisComposition
                                            .hours_consulting
                                    }
                                    h - Honoraire:{' '}
                                    {this.props.dataQuotation.consulting}
                                    €/h
                                </b>
                            </div>
                            <div className="ml-auto">
                                <b>
                                    {this.props.devisComposition.consulting}€{' '}
                                </b>
                                HT
                            </div>
                        </div>
                    </div>
                )}
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        devisComposition: state.devisComposition,
        dataQuotation: state.dataQuotation.dataQuotation,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            consultingDevisAdd: devisCompositionAction.consultingDevisAdd,
            consultingDevisRemove: devisCompositionAction.consultingDevisRemove,
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsultingDetail)
