import React, { Component } from 'react'

class ListQuotationsHeader extends Component {
    render() {
        return (
            <div className="mb-4">
                <div className="d-flex flex-column flex-md-row justify-content-between">
                    <div>
                        <p className="h1 mb-1">Liste des devis envoyés</p>
                    </div>
                </div>
            </div>
        )
    }
}
export default ListQuotationsHeader
