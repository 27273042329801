import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    boxShadow             : '0 0 0 1px rgba(0,0,0,.1), 0 4px 14px 0 rgba(0,0,0, 0.2)',
    borderRadius          : '8px',
    padding               : '32px'
  },
  overlay : {
    zIndex: 1021,
    backgroundColor : 'rgba(34,34,34,.5)'
  }
};
// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root')

class ConsultingAddModal extends Component {
  constructor() {
    super();

    this.state = {
      modalIsOpen: false
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({modalIsOpen: true});
  }


  closeModal() {
    this.setState({modalIsOpen: false});
  }


  render() {
    return (
      <React.Fragment>
        <a onClick={this.openModal} className="btn btn-sm btn-outline-primary mt-3 mt-sm-0 mr-2">{this.props.wording}</a>
        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          {this.props.children}
        </Modal>
      </React.Fragment>
    );
  }
}

export default ConsultingAddModal;

