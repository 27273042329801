import { userEmolumentConstants } from '../constants/user_emoluments.constants'
import { userEmolumentService } from '../services/user_emoluments.services'
import { res } from '../helpers/dispatch'

export const userEmolumentsAction = {
    createUserConsulting,
    getUserConsulting,
    createUserEmolument,
    getUserEmoluments,
    getEmoluments,
    deletUserEmolulment,
}

function getUserEmoluments() {
    return (dispatch) => {
        dispatch(res.loading(userEmolumentConstants.USEREMOLUMENT_LOADING))
        userEmolumentService
            .getUserEmoluments()
            .then((response) => {
                let userEmolument = response.data
                dispatch(
                    res.success(
                        userEmolumentConstants.GETUSEREMOLUMENTS_SUCCESS,
                        userEmolument
                    )
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(
                        userEmolumentConstants.GETUSEREMOLUMENTS_FAILURE,
                        error
                    )
                )
            })
    }
}

function getEmoluments() {
    return (dispatch) => {
        dispatch(res.loading(userEmolumentConstants.EMOLUMENTS_LOADING))
        userEmolumentService
            .getEmoluments()
            .then((response) => {
                let emolument = response.data
                dispatch(
                    res.success(
                        userEmolumentConstants.GETEMOLUMENTS_SUCCESS,
                        emolument
                    )
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(
                        userEmolumentConstants.GETEMOLUMENTS_FAILURE,
                        error
                    )
                )
            })
    }
}

function createUserEmolument(emolument) {
    return (dispatch) => {
        dispatch(res.loading(userEmolumentConstants.USEREMOLUMENT_LOADING))
        userEmolumentService
            .createUserEmolument(emolument)
            .then((response) => {
                let userEmolument = response.data
                dispatch(
                    res.success(
                        userEmolumentConstants.POSTUSEREMOLUMENT_SUCCESS,
                        userEmolument
                    )
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(
                        userEmolumentConstants.POSTUSEREMOLUMENT_FAILURE,
                        error
                    )
                )
            })
    }
}

function deletUserEmolulment(id) {
    return (dispatch) => {
        dispatch(res.loading(userEmolumentConstants.USERCONSULTING_LOADING))
        userEmolumentService
            .deletUserEmolulment(id)
            .then(() => {
                dispatch(
                    res.success(
                        userEmolumentConstants.DELETEUSEREMOLUMENT_SUCCESS,
                        id
                    )
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(
                        userEmolumentConstants.DELETEUSEREMOLUMENT_FAILURE,
                        error
                    )
                )
            })
    }
}

function getUserConsulting() {
    return (dispatch) => {
        dispatch(res.loading(userEmolumentConstants.USERCONSULTING_LOADING))
        userEmolumentService
            .getUserConsulting()
            .then((response) => {
                let consulting = response.data
                dispatch(
                    res.success(
                        userEmolumentConstants.GETUSERCONSULTING_SUCCESS,
                        consulting
                    )
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(
                        userEmolumentConstants.GETUSERCONSULTING_FAILURE,
                        error
                    )
                )
            })
    }
}

function createUserConsulting(body) {
    return (dispatch) => {
        dispatch(res.loading(userEmolumentConstants.USERCONSULTING_LOADING))
        userEmolumentService
            .createUserConsulting(body)
            .then(() => {
                dispatch(
                    res.success(
                        userEmolumentConstants.POSTUSERCONSULTING_SUCCESS,
                        body
                    )
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(
                        userEmolumentConstants.POSTUSERCONSULTING_FAILURE,
                        error
                    )
                )
            })
    }
}
