import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {getDemandes} from "../../../../actions/demandes.action";
import Tabs from "./Tabs";
import Demande from "./Demande";
import RdvList from "./RdvList";



class Rdv extends Component {

    constructor(props) {
        super(props);

    }

    componentDidMount(){
        this.props.getDemandes();
    }

    render() {

        if (this.props.demande.length === 0) {
            return <div></div>
        }
        return (
                <div>
                    <Tabs>
                        <div label="Demande de rendez-vous">
                            <Demande demande={this.props.demande.demande}/>
                        </div>
                        <div label="Rendez-vous Accepté">
                            <RdvList demande={this.props.demande.accepted}/>
                        </div>
                        <div label="Rendez-vous Refusé">
                            <RdvList demande={this.props.demande.refus}/>
                        </div>
                    </Tabs>
                </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        demande: state.demande
    };
}

function mapDispatchToProps(dispatch){
    return bindActionCreators(
        {getDemandes: getDemandes}, dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Rdv);

