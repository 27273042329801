import React, { Component } from 'react'
import FicheHorrairesHeader from './FicheHorrairesHeader'
import HorrairesCards from './Horraires/HorrairesCards'

class FicheHorraires extends Component {
    render() {
        return (
            <main
                className="dash-content col-12 col-md-9 col-xl-10"
                role="main"
            >
                <div className="py-5 px-2 px-sm-4">
                    <FicheHorrairesHeader />
                    <hr />
                    <HorrairesCards />
                </div>
            </main>
        )
    }
}

export default FicheHorraires
