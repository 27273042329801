import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { userEmolumentsAction } from '../../../../actions/user_emoluments.actions'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import UserEmolumentForm from './AddEmolument/UserEmolumentForm'
import Modal from '../../../../components/Modal/Modal'
import Loader from '../../../../components/Loader'

class TarifEmolument extends Component {
    componentDidMount() {
        if (this.props.user_emoluments.user_emoluments.length === 0) {
            this.props.getUserEmoluments()
        }
        if (this.props.emoluments.emoluments.length === 0) {
            this.props.getEmoluments()
        }
    }

    deleteEmol(id) {
        this.props.deletUserEmolulment(id)
    }

    render() {
        const { loading, user_emoluments } = this.props.user_emoluments
        return loading ? (
            <Loader />
        ) : (
            <div className="shadow p-3 bg-white rounded mb-5">
                <div className="row">
                    <div className="col-sm-6">
                        <div className="d-flex align-items-center">
                            <div className="mr-3">
                                <p className="h2 text-secondary mb-0">
                                    <FontAwesomeIcon icon="receipt" />
                                </p>
                            </div>
                            <div>
                                <p className="h6 mb-0">
                                    Emoluments libres de l'étude:{' '}
                                </p>
                            </div>
                        </div>
                        <ul>
                            {user_emoluments.map((emolument) => (
                                <li key={emolument.id} className="mt-3">
                                    <b>{emolument.emolument.name}: </b>{' '}
                                    {emolument.amount}€ HT{' '}
                                    <a
                                        href=""
                                        onClick={() =>
                                            this.deleteEmol(emolument.id)
                                        }
                                    >
                                        supprimer
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="col-sm-6 text-sm-right">
                        <Modal
                            id="AddEmolument"
                            btnStyle="btn btn-sm btn-outline-primary mt-3 mt-sm-0 mr-2"
                            btnTitle="Ajouter une prestation"
                        >
                            <UserEmolumentForm
                                emoluments={this.props.emoluments.emoluments}
                            />
                        </Modal>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user_emoluments: state.user_emoluments,
        emoluments: state.emoluments,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getUserEmoluments: userEmolumentsAction.getUserEmoluments,
            deletUserEmolulment: userEmolumentsAction.deletUserEmolulment,
            getEmoluments: userEmolumentsAction.getEmoluments,
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(TarifEmolument)
