import { devisCompositionConstants } from '../constants/devisComposition.constants'
import { calculeEmolumentQuotation } from '../helpers/Devis/calculeEmolumentQuotation'
import { quotationTaxeComposition } from '../helpers/Devis/calculeTaxeQuotationHelper'
import { quotationRequestsConstants } from '../constants/quotation_requests.constants'

const initalDevisState = {
    fixed_emoluments: [],
    taxes: [],
    free_emoluments: [],
    discounts: [],
    others_costs: [],
    hours_consulting: 0,
    consulting: 0,
    total_ht: null,
    total_ttc: null,
}

export function devisComposition(state = initalDevisState, action) {
    let amountDiscount = 0,
        amountOtherCost = 0,
        taxes = 0,
        totalTaxe = 0,
        emoluments = 0,
        totalEmolument = 0

    switch (action.type) {
        case devisCompositionConstants.ADDEMOLUMENTDEVIS:
            emoluments = calculeEmolumentQuotation(action.payload.data)
            emoluments.map((emolument) => (totalEmolument += emolument.total))
            state.fixed_emoluments = emoluments
            return {
                ...state,
                fixed_emoluments: state.fixed_emoluments,
                total_ht: (state.total_ht += totalEmolument),
                total_ttc: (state.total_ttc += totalEmolument * 1.2),
            }
        case devisCompositionConstants.RESETEMOLUMENTDEVIS_REQUEST:
            return {
                ...state,
                fixed_emoluments: [],
                total_ht:
                    state.total_ht -
                    state.fixed_emoluments.reduce((a, b) => a + b.total, 0),
                total_ttc:
                    state.total_ttc -
                    state.fixed_emoluments.reduce((a, b) => a + b.total, 0) *
                        1.2,
            }
        case devisCompositionConstants.ADDTAXEDEVIS_REQUEST:
            taxes = quotationTaxeComposition(action.payload.data)
            taxes.map((taxe) => (totalTaxe += taxe.amount))
            state.taxes = taxes
            return {
                ...state,
                taxes: state.taxes,
                total_ht: (state.total_ht += totalTaxe),
                total_ttc: (state.total_ttc += totalTaxe * 1.05),
            }
        case devisCompositionConstants.RESETTAXEDEVIS_REQUEST:
            if (state.taxes.reduce((a, b) => a + b.taxe_include, true)) {
                return {
                    ...state,
                    taxes: [],
                    total_ht:
                        state.total_ht -
                        state.taxes.reduce((a, b) => a + b.amount, 0),
                    total_ttc:
                        state.total_ttc -
                        state.taxes.reduce((a, b) => a + b.amount, 0) * 1,
                }
            } else {
                return {
                    ...state,
                    taxes: [],
                    total_ht:
                        state.total_ht -
                        state.taxes.reduce((a, b) => a + b.amount, 0),
                    total_ttc:
                        state.total_ttc -
                        state.taxes.reduce((a, b) => a + b.amount, 0) * 1.2,
                }
            }
        // CONSULTING
        case devisCompositionConstants.ADDCONSULTINGDEVIS:
            return {
                ...state,
                hours_consulting: state.hours_consulting + 1,
                consulting: state.consulting + action.payload.data,
                total_ht: state.total_ht + action.payload.data,
                total_ttc: state.total_ttc + action.payload.data * 1.2,
            }
        case devisCompositionConstants.REMOVECONSULTINGDEVIS:
            if (state.hours_consulting === 0) {
                return { ...state }
            } else {
                return {
                    ...state,
                    hours_consulting: state.hours_consulting - 1,
                    consulting: state.consulting - action.payload.data,
                    total_ht: state.total_ht - action.payload.data,
                    total_ttc: state.total_ttc - action.payload.data * 1.2,
                }
            }
        case devisCompositionConstants.RESETCONSULTINGDEVIS_REQUEST:
            state.total_ht -= state.hours_consulting * action.payload.data
            state.total_ttc -=
                state.hours_consulting * action.payload.data * 1.2
            return {
                ...state,
                hours_consulting: 0,
                consulting: 0,
                total_ht: state.total_ht,
                total_ttc: state.total_ttc,
            }
        // FREE EMOLUMENT
        case devisCompositionConstants.ADDFREEEMOLUMENTDEVIS:
            state.free_emoluments.push(action.payload.data)
            return {
                ...state,
                free_emoluments: state.free_emoluments,
                total_ht: state.total_ht + action.payload.data.amount,
                total_ttc: state.total_ttc + action.payload.data.amount * 1.2,
            }
        case devisCompositionConstants.RESETFREEEMOLUMENTDEVIS_REQUEST:
            return {
                ...state,
                free_emoluments: [],
                total_ht:
                    state.total_ht -
                    state.free_emoluments.reduce((a, b) => a + b.amount, 0),
                total_ttc:
                    state.total_ttc -
                    state.free_emoluments.reduce((a, b) => a + b.amount, 0) *
                        1.2,
            }
        // DISCOUNT
        case devisCompositionConstants.ADDDISCOUNTDEVIS:
            state.discounts.push(action.payload.data)
            return {
                ...state,
                discounts: state.discounts,
                total_ht:
                    state.total_ht - parseInt(action.payload.data.amount, 10),
                total_ttc:
                    state.total_ttc -
                    parseInt(action.payload.data.amount, 10) * 1.2,
            }

        case devisCompositionConstants.DELETE_DISCOUNTDEVIS_REQUEST:
            amountDiscount = parseInt(
                state.discounts.find(
                    (discount) => discount.ligne_id === action.payload.data
                ).amount
            )
            state.discounts.splice(
                state.discounts.findIndex(
                    (discount) => discount.ligne_id === action.payload.data
                ),
                1
            )
            return {
                ...state,
                discounts: state.discounts,
                total_ht: state.total_ht + amountDiscount,
                total_ttc: state.total_ttc + amountDiscount * 1.2,
            }
        // OTHERS COSTS
        case devisCompositionConstants.ADDOTHERCOSTSDEVIS_REQUEST:
            state.others_costs.push(action.payload.data)
            return {
                ...state,
                others_costs: state.others_costs,
                total_ht:
                    state.total_ht + parseInt(action.payload.data.amount, 10),
                total_ttc:
                    state.total_ttc +
                    parseInt(action.payload.data.amount, 10) * 1.2,
            }

        case devisCompositionConstants.DELETE_OTHERCOSTSDEVIS_REQUEST:
            amountOtherCost = state.others_costs.find(
                (other_cost) => other_cost.ligne_id === action.payload.data
            ).amount
            state.others_costs.splice(
                state.others_costs.findIndex(
                    (other_cost) => other_cost.ligne_id === action.payload.data
                ),
                1
            )
            return {
                ...state,
                others_costs: state.others_costs,
                total_ht: state.total_ht - amountOtherCost,
                total_ttc: state.total_ttc - amountOtherCost * 1.2,
            }
        case quotationRequestsConstants.RESETQUOTATION_REQUEST:
            return {
                fixed_emoluments: [],
                taxes: [],
                free_emoluments: [],
                others_costs: [],
                discounts: [],
                hours_consulting: 0,
                consulting: 0,
                total_ht: null,
                total_ttc: null,
            }
        default:
            return state
    }
}
