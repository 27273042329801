import React, { Component } from 'react'
import StatScoreHistory from './StatScoreHistory/StatScoreHistory'
import StatVisits from './StatVisits/StatVisits'
import StatProfil from './StatProfil/StatProfil'
import PositionFiche from './PositionFiche/PositionFiche'

class Statistiques extends Component {
    render() {
        return (
            <main
                className="dash-content col-12 col-md-9 col-xl-10"
                role="main"
            >
                <div className="py-5 px-2 px-sm-4">
                    <div className="mb-3">
                        <div className="d-flex flex-column flex-md-row justify-content-between">
                            <div>
                                <p className="h1 mb-2 mb-md-5">
                                    Vos statistiques
                                </p>
                            </div>
                        </div>
                    </div>
                    <StatScoreHistory />
                    <br /> <br /> <br /> <br />
                    <StatVisits />
                    <br /> <br /> <br /> <br />
                    <div className="row">
                        <div className="col-md">
                            <StatProfil />
                        </div>
                        <div className="col-md">
                            <PositionFiche />
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

export default Statistiques
