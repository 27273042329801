import { modalConstants } from '../constants/modal.constants'

export const modalActions = {
    hideModal,
    showModal,
    hideCloseModal,
}

function hideModal(id) {
    return {
        type: modalConstants.HIDE_MODAL,
        payload: id,
    }
}

function showModal(id) {
    return {
        type: modalConstants.SHOW_MODAL,
        payload: id,
    }
}

function hideCloseModal() {
    return {
        type: modalConstants.HIDE_CLOSEMODAL,
    }
}
