export function calculPACS(data) {
    let fixed_emoluments = data.dataQuotation.fixed_emoluments.filter(
        (fixed_emolument) => fixed_emolument.category_id === 4
    )

    let emoluments_PACS = []

    fixed_emoluments.map((fixed_emolument) => {
        emoluments_PACS.push({
            prix_fixe: fixed_emolument.amount,
            total: fixed_emolument.amount,
            name: fixed_emolument.name,
        })
    })

    return emoluments_PACS
}
