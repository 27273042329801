import React, { Component } from 'react'
import CKEditor from 'ckeditor4-react'

class CkEditorField extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        let { name, value, onChange } = this.props.input
        let { editorConfig } = this.props

        const config = editorConfig
            ? editorConfig
            : {
                  entities_latin: false,
                  format_tags: 'p;h3;h4;h5;h6;pre;address;div',
                  language: 'fr',
                  editorName: name,
              }

        return (
            <CKEditor
                onBeforeLoad={(CKEDITOR) => (CKEDITOR.disableAutoInline = true)}
                type="classic"
                config={config}
                data={value}
                onChange={(e) => {
                    onChange(e.editor.getData())
                }}
            />
        )
    }
}

export default CkEditorField
