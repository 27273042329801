import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { articleActions } from '../../../../actions/article.actions'

import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
} from 'react-share'

import { EmailIcon, FacebookIcon, LinkedinIcon, TwitterIcon } from 'react-share'
import { DateTime } from 'luxon'
import Modal from '../../../../components/Modal/Modal'
import ArticleForm from '../ArticleForm/ArticleForm'
import { modalActions } from '../../../../actions/modal.actions'
import { bindActionCreators } from 'redux'

class ArticleCard extends Component {
    constructor(props) {
        super(props)

        this.submit = this.submit.bind(this)
    }

    submit(value) {
        this.props.updateArticle(value, value.id)
        this.props.hideModal('EditArticle' + value.id)
    }

    render() {
        return (
            <div className="card_visio article_card row-fluid">
                <img
                    src={this.props.article.photo.url}
                    className={'col-lg-2'}
                    style={{ padding: 0 }}
                />
                <div
                    className="card_visio_info col-lg-6"
                    style={{ padding: '26px' }}
                >
                    <Link
                        to={`/website/articles/update/${this.props.article.id}`}
                        article={this.props.article}
                    >
                        <h2>{this.props.article.title}</h2>
                    </Link>
                    <p>
                        Créer le:{' '}
                        {DateTime.fromISO(this.props.article.created_at)
                            .setLocale('fr')
                            .toFormat('dd LLL yyyy')}
                    </p>
                    <div className={'pt-2 mb-2'}>
                        <LinkedinShareButton
                            className={'mr-1'}
                            url={`http://www.${this.props.website.website.domain_name}/article/${this.props.article.slug}`}
                            title={this.props.article.title}
                        >
                            <LinkedinIcon size={32} round={true} />
                        </LinkedinShareButton>
                        <FacebookShareButton
                            url={`http://www.${this.props.website.website.domain_name}/article/${this.props.article.slug}`}
                            className={'mr-1'}
                        >
                            {' '}
                            <FacebookIcon size={32} round={true} />{' '}
                        </FacebookShareButton>
                        <TwitterShareButton
                            url={`http://www.${this.props.website.website.domain_name}/article/${this.props.article.slug}`}
                            title={this.props.article.title}
                            className={'mr-1'}
                        >
                            <TwitterIcon size={32} round={true} />
                        </TwitterShareButton>
                        <EmailShareButton>
                            <EmailIcon
                                url={`http://www.${this.props.website.website.domain_name}/article/${this.props.article.slug}`}
                                size={32}
                                round={true}
                            />{' '}
                        </EmailShareButton>
                    </div>
                </div>
                <div className="padding_cta">
                    <Modal
                        id={'EditArticle' + this.props.article.id}
                        btnStyle="btn btn-primary"
                        btnTitle="Editer"
                    >
                        <Fragment>
                            <h2 className="text-center">
                                Editer {this.props.article.title}
                            </h2>
                            <ArticleForm
                                onSubmit={this.submit}
                                initialValues={this.props.article}
                            />
                        </Fragment>
                    </Modal>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        website: state.website,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            updateArticle: articleActions.updateArticle,
            hideModal: modalActions.hideModal,
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticleCard)
