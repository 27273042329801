import React, { Component } from 'react'
import { ChromePicker } from 'react-color'

class ColorBoxField extends Component {
    constructor(props) {
        super(props)
        this.state = {
            color: this.props.input.value,
        }

        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(color) {
        this.setState({ color: color.hex })
        this.props.input.onChange(color.hex)
    }

    render() {
        const { value } = this.props.input

        return (
            <div
                className="colorPicker title_website"
                style={{
                    backgroundColor: this.state.color,
                    borderRadius: '8px',
                }}
            >
                <ChromePicker
                    className="center"
                    color={value}
                    onChangeComplete={this.handleChange}
                    width="450px"
                    disableAlpha={true}
                />
            </div>
        )
    }
}

export default ColorBoxField
