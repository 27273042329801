import React, { Component } from 'react'
import { fetchAPIDomainr } from '../../../services/Api'
import Template1 from '../../../../assets/images/template-1.png'
import Template2 from '../../../../assets/images/template-2.png'
import Template3 from '../../../../assets/images/template-3.png'
import { ChromePicker } from 'react-color'
import { createWebsite } from '../../../actions/website.actions'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'

class WebSiteForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            value: '',
            theme: '1',
            checked: true,
            reponseDN: '',
            background: '#fff',
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.checkboxHandler = this.checkboxHandler.bind(this)
    }

    handleChange(event) {
        this.setState({ value: event.target.value })
    }

    handleChangeTheme(event) {
        this.setState({ theme: event.target.value })
    }

    handleSubmit(event) {
        event.preventDefault()
        this.props.createWebsite(
            {
                template_id: this.state.theme,
                domain_name: this.state.value,
                color: this.state.background,
                live: true,
            },
            (website) => {
                window.location.reload()
                return website
            }
        )
    }

    apiDomainr() {
        fetchAPIDomainr(this.state.value).then((result) => {
            if (
                result.status[0]['status'] === 'undelegated inactive' ||
                result.status[0]['status'] === 'undelegated' ||
                result.status[0]['status'] === 'inactive'
            ) {
                this.setState({ reponseDN: true })
            } else {
                this.setState({ reponseDN: false })
            }
        })
    }

    reponseDomaineIcon(statut) {
        if (statut === '') {
        } else if (statut === true) {
            return 'Disponible ✅'
        } else {
            return 'Non disponible ❌'
        }
    }

    checkboxHandler(e) {
        this.setState({
            checked: e.target.checked,
        })
    }

    handleChangeComplete = (color) => {
        this.setState({ background: color.hex })
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <h3> Nom de domaine:</h3>
                <p>
                    N'oubliez pas de mettre le ".fr" à la fin de votre nom de
                    domaine
                </p>
                <div className="form-row align-items-center">
                    <div className="col-sm-3 my-1">
                        <input
                            type="text"
                            value={this.state.value}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="col-auto my-1">
                        <a
                            className="btn btn-primary"
                            onClick={() => this.apiDomainr()}
                        >
                            Vérifier la disponibilité
                        </a>
                    </div>
                    <div className="col-auto my-1">
                        <p style={{ fontSize: '30px' }}>
                            {this.reponseDomaineIcon(this.state.reponseDN)}
                        </p>
                    </div>
                </div>
                <br />
                <br />
                <br />
                <h3>Choix du model de site:</h3>
                <p>
                    Clicker sur le model que vous souhaitez afin de le
                    sélectionner
                </p>
                <br />
                <div className="theme_choice text-center">
                    <div onChange={this.handleChangeTheme.bind(this)}>
                        <label className="label_item">
                            <input
                                type="radio"
                                value="1"
                                name="theme"
                                onChange={this.checkboxHandler}
                                className="radio"
                            />
                            <img
                                src={Template1}
                                style={{
                                    width: '300px',
                                    padding: '10px',
                                    margin: '30px',
                                }}
                            />
                            <a
                                href="https://georges.notre-notaire.fr"
                                target="_blank"
                                style={{ display: 'block' }}
                            >
                                Voir la demo
                            </a>
                        </label>
                        <label className="label_item">
                            <input
                                type="radio"
                                value="3"
                                name="theme"
                                onChange={this.checkboxHandler}
                                className="radio"
                            />
                            <img
                                src={Template2}
                                style={{
                                    width: '300px',
                                    padding: '10px',
                                    margin: '30px',
                                }}
                            />
                            <a
                                href="https://matt.notre-notaire.fr/"
                                target="_blank"
                                style={{ display: 'block' }}
                            >
                                Voir la demo
                            </a>
                        </label>
                        <label className="label_item">
                            <input
                                type="radio"
                                value="2"
                                name="theme"
                                onChange={this.checkboxHandler}
                                className="radio"
                            />
                            <img
                                src={Template3}
                                style={{
                                    width: '300px',
                                    padding: '10px',
                                    margin: '30px',
                                }}
                            />
                            <a
                                href="https://brad.notre-notaire.fr/"
                                target="_blank"
                                style={{ display: 'block' }}
                            >
                                Voir la demo
                            </a>
                        </label>
                    </div>
                </div>
                <br />
                <br />
                <br />
                <h3>Choix de la couleur de votre site:</h3>
                <br />
                <div
                    className="colorPicker title_website"
                    style={{
                        backgroundColor: this.state.background,
                        borderRadius: '8px',
                    }}
                >
                    <ChromePicker
                        className="center"
                        color={this.state.background}
                        onChangeComplete={this.handleChangeComplete}
                        width="450px"
                        disableAlpha="true"
                    />
                </div>
                <br />
                <br />
                <br />
                <input
                    type="submit"
                    value="Valider la configuration"
                    className="btn btn-secondary"
                />
                <br />
                <br />
                <br />
            </form>
        )
    }
}

export default reduxForm({ form: 'webiste' })(
    connect(null, { createWebsite })(WebSiteForm)
)
