export function immoTaxeCalcule(data) {
    let taxe = {}
    const departement =
        data.reponse_qualifications.find(
            (element) => element.qualification.id === 53
        ).reponse === "Oui il se trouve dans l'un de ces départements"
    if (departement) {
        taxe = data.dataQuotation.taxes.find((t) => t.id === 38)
    } else {
        taxe = data.dataQuotation.taxes.find((t) => t.id === 39)
    }
    // GET PRIX DU BIEN
    const input_commun_prix_ancien = parseInt(
        data.reponse_qualifications.find(
            (element) => element.qualification.id === 48
        ).reponse,
        10
    )
    // Check if price mobilier is here
    const input_prix_mobilier = data.reponse_qualifications.find(
        (element) => element.qualification.id === 52
    )
        ? parseInt(
              data.reponse_qualifications.find(
                  (element) => element.qualification.id === 52
              ).reponse,
              10
          )
        : 0.0
    // GET VALEUR MOBILIER MAX
    const valeur_max_mobilier = (20 * input_commun_prix_ancien) / 100
    // CALCULE PRIX TOTAL POUR EMOLUMENT
    const prix_total_pour_emolument =
        input_prix_mobilier < valeur_max_mobilier
            ? input_commun_prix_ancien - input_prix_mobilier
            : input_commun_prix_ancien
    if (taxe.rate != null) {
        taxe.amount = (prix_total_pour_emolument * taxe.rate) / 100
    }
    return taxe
}
