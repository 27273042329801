import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Loader from '../../../components/Loader'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { leadsAction } from '../../../actions/lead.actions'
import { DateTime } from 'luxon'

class LeadTable extends Component {
    componentDidMount() {
        if (this.props.leads.leads.length === 0) {
            this.props.getLeads()
        }
    }

    render() {
        const classStatut = {
            1: 'badge badge-primary',
            2: 'badge badge-info',
            3: 'badge badge-secondary',
            4: 'badge badge-danger',
        }

        const iconSource = {
            telephone: 'phone',
            Téléphone: 'phone',
            Mail: 'envelope',
            fiche_notaire: 'address-book',
            liste_fiche: 'address-book',
            Etude: 'university',
            website: 'globe',
            Autre: 'map-marker-alt',
            home_page: 'atlas',
            edito: 'newspaper',
            pap: 'globe',
        }

        const { loading, leads } = this.props.leads

        return loading ? (
            <Loader />
        ) : (
            <div className="mb-5">
                <div className="table-responsive">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th className="text-primary">
                                    <strong>Source</strong>
                                </th>
                                <th className="text-primary">
                                    <strong>Nom</strong>
                                </th>
                                <th className="text-primary">
                                    <strong>Téléphone</strong>
                                </th>
                                <th className="text-primary">
                                    <strong>E-mail</strong>
                                </th>
                                <th className="text-primary">
                                    <strong>Date</strong>
                                </th>
                                <th className="text-primary">
                                    <strong>Catégorie</strong>
                                </th>
                                <th className="text-primary">
                                    <strong>Statut</strong>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {leads.map((lead) => (
                                <tr key={lead.id}>
                                    <td className="text-center h4">
                                        <Link to={`leads/${lead.id}`}>
                                            <FontAwesomeIcon
                                                icon={iconSource[lead.source]}
                                            />
                                        </Link>
                                    </td>
                                    <td>
                                        <Link
                                            to={`leads/${lead.id}`}
                                            style={{ color: 'black' }}
                                        >
                                            {lead.nom}
                                        </Link>
                                    </td>
                                    <td>
                                        <Link
                                            to={`leads/${lead.id}`}
                                            style={{ color: 'black' }}
                                        >
                                            {lead.telephone}
                                        </Link>
                                    </td>
                                    <td>
                                        <Link
                                            to={`leads/${lead.id}`}
                                            style={{ color: 'black' }}
                                        >
                                            {lead.email}
                                        </Link>
                                    </td>
                                    <td>
                                        <Link
                                            to={`leads/${lead.id}`}
                                            style={{ color: 'black' }}
                                        >
                                            {DateTime.fromISO(
                                                lead.created_at
                                            ).toLocaleString(
                                                DateTime.DATETIME_MED
                                            )}
                                        </Link>
                                    </td>
                                    <td>
                                        <Link
                                            to={`leads/${lead.id}`}
                                            style={{ color: 'black' }}
                                        >
                                            {lead.category.category_name}
                                        </Link>
                                    </td>
                                    <td>
                                        <Link to={`leads/${lead.id}`}>
                                            <h5>
                                                <span
                                                    className={
                                                        classStatut[
                                                            lead.lead_statuts
                                                                .statut_id
                                                        ]
                                                    }
                                                >
                                                    {lead.lead_statuts.statut}
                                                </span>
                                            </h5>
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return { leads: state.leads }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getLeads: leadsAction.getLeads }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(LeadTable)
