import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { userEmolumentsAction } from '../../../../actions/user_emoluments.actions'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ConsultingAddModal from './AddConsulting/ConsultingAddModal'
import ConsultingForm from './AddConsulting/ConsultingForm'
import Loader from '../../../../components/Loader'
import UserEmolumentForm from '../TarifEmolument/AddEmolument/UserEmolumentForm'
import Modal from '../../../../components/Modal/Modal'

class TarifConsulting extends Component {
    componentDidMount() {
        if (Object.keys(this.props.consulting.consulting).length === 0) {
            this.props.getUserConsulting()
        }
    }

    render() {
        const { loading, consulting } = this.props.consulting
        return loading ? (
            <Loader />
        ) : (
            <div className="shadow p-3 bg-white rounded mb-5">
                <div className="row">
                    <div className="col-sm-6">
                        <div className="d-flex align-items-center">
                            <div className="mr-3">
                                <p className="h2 text-secondary mb-0">
                                    <FontAwesomeIcon icon="hourglass-half" />
                                </p>
                            </div>
                            <div>
                                <p className="h6 mb-0">
                                    Honoraires de conseil de l'étude:{' '}
                                    {consulting.honoraire_ht} € HT{' '}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 text-sm-right">
                        <Modal
                            id="AddConsulting"
                            btnStyle="btn btn-sm btn-outline-primary mt-3 mt-sm-0 mr-2"
                            btnTitle="Modifier mes honoraire"
                        >
                            <ConsultingForm />
                        </Modal>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        consulting: state.consulting,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { getUserConsulting: userEmolumentsAction.getUserConsulting },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(TarifConsulting)
