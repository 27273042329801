import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { bindActionCreators } from 'redux'
import { devisCompositionAction } from '../../../../../actions/devis_composition.actions'
import { Field, reduxForm } from 'redux-form'

class OthersCostsDetail extends Component {
    constructor(props) {
        super(props)

        this.state = {
            ligne_id: 0,
        }

        this.onSubmit = this.onSubmit.bind(this)
    }

    delete(id) {
        this.props.deleteOtherCostsDevis(id)
    }

    onSubmit(values) {
        values.ligne_id = this.state.ligne_id
        this.props.otherCostsDevis(values)
        this.props.reset()
        this.setState({ ligne_id: this.state.ligne_id + 1 })
    }

    render() {
        const { others_costs } = this.props.devisComposition
        return (
            <Fragment>
                <div className="border-bottom py-2">
                    <div className="d-flex align-items-center">
                        <span className="badge badge-other h4 my-2">
                            Autres frais
                        </span>
                    </div>
                </div>
                {others_costs.map((other) => (
                    <div className="border-bottom py-2" key={other.ligne_id}>
                        <div className="d-flex align-items-center">
                            <div className="mr-2">
                                <button
                                    className="btn btn-light rounded-circle"
                                    onClick={() => this.delete(other.ligne_id)}
                                >
                                    <FontAwesomeIcon icon="trash" />
                                </button>
                            </div>
                            <div className="mr-2">{other.libelle}</div>
                            <div className="ml-auto">
                                <b> {parseInt(other.amount, 10).toFixed(2)} </b>
                                € HT
                            </div>
                        </div>
                    </div>
                ))}
                <div className="border-bottom py-2">
                    <form>
                        <div className="row">
                            <div className="col-8">
                                <Field
                                    type="text"
                                    name="libelle"
                                    placeholder="Libellé (Ex: frais de dossiers)"
                                    component="input"
                                />
                            </div>

                            <div className="col-2">
                                <Field
                                    type="number"
                                    name="amount"
                                    placeholder="Valeur (en €)"
                                    component="input"
                                />
                            </div>

                            <div className="col-2 text-center">
                                <button
                                    className="btn btn-outline-secondary mt-1 "
                                    type="submit"
                                    onClick={this.props.handleSubmit(
                                        this.onSubmit
                                    )}
                                >
                                    Ajouter
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    return { devisComposition: state.devisComposition }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            otherCostsDevis: devisCompositionAction.otherCostsDevis,
            deleteOtherCostsDevis: devisCompositionAction.deleteOtherCostsDevis,
        },
        dispatch
    )
}

export default reduxForm({ form: 'emolument_other_costs' })(
    connect(mapStateToProps, mapDispatchToProps)(OthersCostsDetail)
)
