import ReactTooltip from 'react-tooltip'
import EquipeTitle from '../../../../assets/images/equipe_title.png'
import EquipeDesc from '../../../../assets/images/equipe_desc.png'
import React, { Component, Fragment } from 'react'
import { equipeActions } from '../../../actions/equipe.actions'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import TextareaCountCharField from '../../../components/Input/TextareaCountCharField'
import InputCountCharField from '../../../components/Input/InputCountCharField'

class EquipeForm extends Component {
    constructor(props) {
        super(props)

        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleSubmit(value) {
        this.props.updateEquipe(value, value.id)
    }

    render() {
        return (
            <Fragment>
                <form id="update_equipe">
                    <div className="padding_form">
                        <label>
                            <b>Titre:</b>
                        </label>
                        <label data-tip data-for="visible1" className="">
                            <span className="asktooltip">?</span>
                            <ReactTooltip
                                id="visible1"
                                type="info"
                                effect="solid"
                                place="right"
                            >
                                <p>C'est le titre de votre page Equipe:</p>
                                <img src={'/' + EquipeTitle} alt="title" />
                            </ReactTooltip>
                        </label>
                        <p style={{ color: '#33ab9f' }}>
                            Choisissez un titre de page court avec maximum 20
                            characters
                        </p>
                        <div>
                            <Field
                                name="title"
                                component={InputCountCharField}
                            />
                        </div>
                    </div>
                    <div className="padding_form">
                        <label>
                            <b>Description:</b>
                        </label>
                        <label data-tip data-for="visible2" className="">
                            <span className="asktooltip">?</span>
                            <ReactTooltip
                                id="visible2"
                                type="info"
                                effect="solid"
                                place="right"
                            >
                                <p>
                                    Il s'agit de la description de votre équipe.
                                    Elle est entourée en vert dans l'exemple
                                    ci-dessous:
                                </p>
                                <img src={'/' + EquipeDesc} />
                            </ReactTooltip>
                        </label>
                        <p style={{ color: '#33ab9f' }}>
                            Nous vous conseillons un maximum de 800 characters
                        </p>
                        <div>
                            <Field
                                name="description"
                                component={TextareaCountCharField}
                                style={{ height: '200px' }}
                            />
                        </div>
                    </div>
                    <div className="text-right mt-3">
                        <button
                            type="button"
                            id="update_equipe"
                            onClick={this.props.handleSubmit(this.handleSubmit)}
                            className="btn btn-primary"
                        >
                            Enregistrer les modifications
                        </button>
                    </div>
                </form>
            </Fragment>
        )
    }
}

export default reduxForm({ form: 'update_equipe' })(
    connect(null, { updateEquipe: equipeActions.updateEquipe })(EquipeForm)
)
