import React, { Component, Fragment } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import EquipeForm from './EquipeForm'
import { equipeActions } from '../../../actions/equipe.actions'
import Teammate from './Teammate/Teammate'
import TeammateForm from './Teammate/TeammateForm'
import Modal from '../../../components/Modal/Modal'
import Loader from '../../../components/Loader'
import { teammateActions } from '../../../actions/teammate.actions'
import { modalActions } from '../../../actions/modal.actions'

class Equipe extends Component {
    constructor(props) {
        super(props)
        this.submit = this.submit.bind(this)
    }

    componentDidMount() {
        if (this.props.equipe.equipe === null) {
            this.props.getEquipe()
        }
    }

    submit(value) {
        this.props.createTeammate(value, this.props.equipe.equipe.id)
        this.props.hideModal('NewMembre')
    }

    render() {
        const { loading, equipe } = this.props.equipe
        return loading ? (
            <Loader />
        ) : (
            <main
                className="dash-content col-12 col-md-9 col-xl-10"
                role="main"
            >
                <div className="py-5 px-2 px-sm-4">
                    <div className="title_website">
                        <h1>Votre Page sur votre Equipe</h1>
                        <br />
                        <div className="bloc_conseil">
                            <p>
                                <b>✒️ Conseil rédaction:</b> N'oubliez pas de
                                présenter chacun des membres de votre équipe et
                                leurs domaines d'interventions/ spécialités
                            </p>
                        </div>
                        <br />
                    </div>
                    <hr />
                    <h2>Quelques mots sur votre équipe:</h2>
                    <EquipeForm initialValues={equipe} />
                    <div className="mt-5 row">
                        <div className="col-6">
                            <h2>Les membres de votre équipe:</h2>
                        </div>
                        <div className="col-6 text-right">
                            <Modal
                                style={{
                                    color: '#1fdd98',
                                    fontSize: '18px',
                                    cursor: 'pointer',
                                    fontWeight: 'bold',
                                }}
                                id="NewMembre"
                                btnTitle="👨‍💼 Ajouter un nouveau membre"
                            >
                                <Fragment>
                                    <h2 className="text-center">
                                        Ajouter un membre
                                    </h2>
                                    <TeammateForm onSubmit={this.submit} />
                                </Fragment>
                            </Modal>
                        </div>
                    </div>
                    <Teammate />
                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    return {
        equipe: state.equipe,
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getEquipe: equipeActions.getEquipe,
            createTeammate: teammateActions.createTeammate,
            hideModal: modalActions.hideModal,
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Equipe)
