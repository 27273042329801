import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import { userEmolumentsAction } from '../../../../../actions/user_emoluments.actions'
import { bindActionCreators } from 'redux'
import Loader from '../../../../../components/Loader'

class UserEmolumentForm extends Component {
    onSubmit = (values) => {
        this.props.createUserEmolument(values)
    }

    render() {
        const { emoluments } = this.props

        return (
            <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                <h2>Ajouter une prestation</h2>
                <div>
                    <label>Nom:</label>
                    <div>
                        <Field name="emolument_id" component="select">
                            <option />
                            {emoluments.map((emol) => (
                                <option key={emol.id} value={emol.id}>
                                    {emol.name}
                                </option>
                            ))}
                        </Field>
                    </div>
                </div>
                <div>
                    <label>Montant en € HT</label>
                    <div>
                        <Field name="amount" component="input" type="number" />
                    </div>
                </div>

                <div className="mt-3">
                    <button
                        className="btn btn-secondary"
                        type="submit"
                        disabled={this.props.pristine || this.props.submitting}
                    >
                        Ajouter cette prestation
                    </button>
                </div>
            </form>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            createUserEmolument: userEmolumentsAction.createUserEmolument,
        },
        dispatch
    )
}

export default reduxForm({ form: 'user_emolument_add' })(
    connect(null, mapDispatchToProps)(UserEmolumentForm)
)
