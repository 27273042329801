import { categoryConstants } from '../constants/category.constants'

const categoriesState = {
    loading: true,
    categories: [],
    error: false,
}

export function categories(state = categoriesState, action) {
    switch (action.type) {
        case categoryConstants.CATEGORIES_LOADING:
            return {
                ...state,
                loading: true,
            }
        case categoryConstants.GETCATEGORIES_SUCCESS:
            return {
                ...state,
                loading: false,
                categories: action.payload.data,
            }
        default:
            return state
    }
}

const categoriesDevisState = {
    loading: true,
    categoriesDevis: [],
    error: false,
}

export function categoriesDevis(state = categoriesDevisState, action) {
    switch (action.type) {
        case categoryConstants.CATEGORIESDEVIS_LOADING:
            return {
                ...state,
                loading: true,
            }
        case categoryConstants.GETCATEGORIESDEVIS_SUCCESS:
            return {
                ...state,
                loading: false,
                categoriesDevis: action.payload.data,
            }
        default:
            return state
    }
}

// multiple request because we call the same reducer
// One reducer per call
