import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { devisCompositionAction } from '../../../../../actions/devis_composition.actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class EmolumentDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            toggle: false,
            total: 0,
        }

        this.toggleChange = this.toggleChange.bind(this)
    }
    componentDidMount() {
        const data = {
            reponse_qualifications:
                this.props.quotationRequest.reponse_qualifications,
            category: this.props.quotationRequest.category,
            dataQuotation: this.props.dataQuotation,
        }
        this.props.emolumentDevis(data)
        this.props.devisComposition.fixed_emoluments.map((fixed_emolument) =>
            this.setState({
                // eslint-disable-next-line react/no-direct-mutation-state
                total: (this.state.total += fixed_emolument.total),
            })
        )
    }

    renderTranche(fixed) {
        if (fixed.tranche) {
            return fixed.tranche.map((value) => (
                <div className="border-bottom py-2" key={value.key}>
                    <div className="d-flex align-items-center">
                        <div className="mr-2">Tranche {value.key} €</div>
                        <div className="ml-auto">
                            <b>{value.value.toFixed(2)}€ </b>HT
                        </div>
                    </div>
                </div>
            ))
        }
    }

    toggleChange() {
        this.setState({ toggle: !this.state.toggle })
    }

    render() {
        const { fixed_emoluments } = this.props.devisComposition

        return fixed_emoluments.length === 0 ? null : (
            <div>
                <div className="border-bottom py-2">
                    <div className="d-flex bd-highlight">
                        <div className="bd-highlight mr-2 my-auto">
                            <button
                                className="btn btn-light rounded-circle"
                                onClick={this.toggleChange}
                            >
                                <FontAwesomeIcon
                                    icon={
                                        this.state.toggle
                                            ? 'angle-down'
                                            : 'angle-right'
                                    }
                                />
                            </button>
                        </div>
                        <span className="bd-highlight badge badge-primary h4 my-2">
                            Emoluments du notaire
                        </span>
                        {this.state.toggle ? null : (
                            <div className="ml-auto bd-highlight h4 my-2">
                                {this.state.total.toFixed(2)}€ HT
                            </div>
                        )}
                    </div>
                </div>
                {this.state.toggle
                    ? this.props.devisComposition.fixed_emoluments.map(
                          (fixed_emolument) => (
                              <Fragment key={fixed_emolument.id}>
                                  <div className="border-bottom py-2">
                                      <div className="text-center">
                                          <b>{fixed_emolument.name}</b>
                                      </div>
                                  </div>
                                  <div className="border-bottom py-2">
                                      <div className="d-flex align-items-center">
                                          <div className="mr-2">
                                              Emoluments du notaire prix des
                                              formalités
                                          </div>
                                          <div className="ml-auto">
                                              <b>
                                                  {fixed_emolument.prix_fixe.toFixed(
                                                      2
                                                  )}
                                                  €{' '}
                                              </b>
                                              HT
                                          </div>
                                      </div>
                                  </div>
                                  {this.renderTranche(fixed_emolument)}
                                  <div className="border-bottom py-2">
                                      <div className="d-flex align-items-center">
                                          <div className="mr-2">
                                              <b>Total</b>
                                          </div>
                                          <div className="ml-auto">
                                              <b>
                                                  {fixed_emolument.total.toFixed(
                                                      2
                                                  )}
                                                  €{' '}
                                              </b>
                                              HT
                                          </div>
                                      </div>
                                  </div>
                              </Fragment>
                          )
                      )
                    : null}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        devisComposition: state.devisComposition,
        quotationRequest: state.quotationRequest.quotationRequest,
        dataQuotation: state.dataQuotation.dataQuotation,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { emolumentDevis: devisCompositionAction.emolumentDevis },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(EmolumentDetail)
