import React, { Component } from 'react'
import Template1 from '../../../../assets/images/template-1.png'
import { getWebsite } from '../../../actions/website.actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import HomePageForm from './HomePageForm'
import { homeActions } from '../../../actions/home.actions'
import Loader from '../../../components/Loader'

class HomePage extends Component {
    componentDidMount() {
        this.props.getHome()
    }

    render() {
        const { loading, home } = this.props.home
        return loading ? (
            <Loader />
        ) : (
            <main
                className="dash-content col-12 col-md-9 col-xl-10"
                role="main"
            >
                <div className="py-5 px-2 px-sm-4">
                    <div className="title_website">
                        <h1>Votre Page d'accueil</h1>
                        <br />
                        <div className="bloc_conseil">
                            <p>
                                <b>✒️ Conseil rédaction:</b> Synthétisez votre /
                                vos activités en quelques lignes. Soyez bref et
                                précis.
                            </p>
                        </div>
                        <br />
                        <div className="bloc_conseil_seo">
                            <p>
                                <b>🥇 Conseil réferencement:</b> Essayer
                                d'intégrer les termes liés à votre activité pour
                                avoir des mots clés pertinents (exemplede mots
                                clés: donation, succession, mariage, achat
                                immobilier...)
                            </p>
                        </div>
                    </div>
                    <hr />
                    <HomePageForm initialValues={home} />
                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    return {
        website: state.website,
        home: state.home,
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { getWebsite, getHome: homeActions.getHome },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage)
