export function immoPretCalcule(data) {
    let fixed_emolument = data.dataQuotation.fixed_emoluments.find(
        (fixed_emolument) => fixed_emolument.id === 42
    )
    // ADDITION PTZ ET AUTRES PRETS  CAR MEME TAUX
    let result = {
        prix_fixe: fixed_emolument.amount,
        tranche: [],
    }
    let total = fixed_emolument.amount

    const hypotheque =
        data.reponse_qualifications.find(
            (response_qualification) =>
                response_qualification.qualification.id === 49
        ).reponse === 'Oui un emprunt avec garantie hypothécaire'

    let input_commun_prix_ancien = 0
    if (hypotheque) {
        const input_pret_hypotheque = parseInt(
            data.reponse_qualifications.find(
                (element) => element.qualification.id === 51
            ).reponse,
            10
        )
        const input_pret_zero = parseInt(
            data.reponse_qualifications.find(
                (element) => element.qualification.id === 50
            ).reponse,
            10
        )
        input_commun_prix_ancien = input_pret_hypotheque + input_pret_zero
    }

    fixed_emolument.tranche_fixed_emoluments.map((tranche) => {
        if (tranche.max === null) {
            if (input_commun_prix_ancien > tranche.min) {
                let result_max =
                    ((input_commun_prix_ancien - tranche.min) * tranche.rate) /
                    100
                let a = `Plus de ${tranche.min}`
                const obj = {
                    key: a,
                    value: result_max,
                }
                result.tranche.push(obj)
                total += obj.value
            } else {
                let a = `Plus de ${tranche.min}`
                const obj = {
                    key: a,
                    value: 0.0,
                }
                result.tranche.push(obj)
                total += obj.value
            }
        } else if (input_commun_prix_ancien > tranche.max) {
            let lol = ((tranche.max - tranche.min) * tranche.rate) / 100
            let a = `${tranche.min} - ${tranche.max}`
            let obj = {
                key: a,
                value: lol,
            }
            result.tranche.push(obj)
            total += obj.value
        } else if (
            input_commun_prix_ancien > tranche.min &&
            input_commun_prix_ancien < tranche.max
        ) {
            let lol =
                ((input_commun_prix_ancien - tranche.min) * tranche.rate) / 100
            let a = `${tranche.min} - ${tranche.max}`
            let obj = {
                key: a,
                value: lol,
            }
            result.tranche.push(obj)
            total += obj.value
        } else if (input_commun_prix_ancien < tranche.min) {
            let b = `${tranche.min} - ${tranche.max}`
            result.tranche[b] = 0.0
            let obj = {
                key: b,
                value: 0.0,
            }
            result.tranche.push(obj)
            total += obj.value
        }
    })
    result.total = total
    result.name = fixed_emolument.name
    result.id = fixed_emolument.id
    return result
}
