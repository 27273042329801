import React, { Component } from 'react'
import { getRates } from '../../../actions/rate.actions'
// REDUX
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import RatingShow from './RatingShow/RatingShow'
import Loader from '../../../components/Loader'

class RatingList extends Component {
    componentDidMount() {
        if (this.props.rates.rates.length === 0) {
            this.props.getRates()
        }
    }
    render() {
        const { loading, rates } = this.props.rates

        return loading ? (
            <Loader />
        ) : (
            <div className="">
                {rates.map((rate) => (
                    <RatingShow key={rate.id} data={rate} />
                ))}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        rates: state.rates,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getRates: getRates }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(RatingList)
