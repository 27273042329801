import { APINotreNotaire } from '../helpers/AxiosAPI'

export const articleServices = {
    updateArticle,
    getArticles,
    postArticle,
}

function updateArticle(article, article_id) {
    let bodys = new FormData()
    for (const property in article) {
        bodys.append(property, article[property])
    }
    return APINotreNotaire.patch(`articles/${article_id}`, bodys)
}

function getArticles() {
    return APINotreNotaire.get('get_articles')
}

function postArticle(article) {
    let bodys = new FormData()
    for (const property in article) {
        bodys.append(property, article[property])
    }
    return APINotreNotaire.post('articles', bodys)
}
