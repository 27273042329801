import { roomConstants } from '../constants/room.constants';
import { history } from '../helpers/history';
import { authHeader } from '../helpers/auth-header';
import { roomService } from '../services/room.services';


export function createRoom(room, callback){
  const promise = roomService.createRoom(room, callback)
  return {
    type: roomConstants.POSTROOM_REQUEST,
    payload: promise
  };
}

export function getRooms(){
  const promise = roomService.getRooms()
  return {
    type: roomConstants.GETROOMS_REQUEST,
    payload: promise
  };
}
export function getRoom(id, name) {
  const promise = roomService.getRoom(id, name)
  return {
    type: roomConstants.GETROOM_REQUEST,
    payload: promise
  };
}

export function createRoomInvitation(body, room_id, callback){
  const promise = roomService.createRoomInvitation(body, room_id, callback)
  return {
    type: roomConstants.POSTINVITATION_REQUEST,
    payload: promise
  };
}
