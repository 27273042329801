import React, { Component } from 'react'

class CheckBoxField extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        let { name, value, onChange } = this.props.input

        return (
            <label data-tip data-for="visible" className="switch-wrap">
                <input
                    type="checkbox"
                    name={name}
                    onChange={onChange}
                    checked={value === '' ? false : value}
                />
                <div className="switch"></div>
            </label>
        )
    }
}

export default CheckBoxField
