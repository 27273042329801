import React, { Component } from 'react'
import EmolumentDetail from './EmolumentDetail'
import TaxeDetail from './TaxeDetail'
import TotalDetail from './TotalDetail'
import ConsultingDetail from './ConsultingDetail'
import FreeEmolumentDetail from './FreeEmolumentDetail'
import DiscountDetail from './DiscountDetail'
import OthersCostsDetail from './OthersCostsDetail'

class DetailQuotation extends Component {
    render() {
        return (
            <div className="shadow p-3 mb-5 bg-white rounded">
                <div className="row mb-4">
                    <div className="col-6">
                        <p className="h6 text-primary mb-3">
                            Elaboration du Devis
                        </p>
                    </div>
                </div>
                <EmolumentDetail />
                <TaxeDetail />
                <ConsultingDetail />
                <FreeEmolumentDetail />
                <OthersCostsDetail />
                <DiscountDetail />
                <TotalDetail />
            </div>
        )
    }
}

export default DetailQuotation
