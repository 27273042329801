import React from 'react';
import RoomModal from './RoomModal/RoomModal';
import RoomForm from './RoomForm/RoomForm';
const VisioListHeader = (props) => {
  return(
   <div className="mb-3">
     <div className="d-flex flex-column flex-md-row justify-content-between">
       <div>
         <p className="h1 mb-2 mb-md-5">Liste des visio-conférences</p>
       </div>
      <RoomModal>
        <RoomForm/>
      </RoomModal>
     </div>
   </div>
    )
}

export default VisioListHeader;

