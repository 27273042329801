import { agendaConstants } from '../constants/agenda.constants';
import moment from 'moment'

export function agenda(state = null, action) {
    switch (action.type) {
        case agendaConstants.GETAGENDA_REQUEST:
            let results = [];

            switch (action.payload) {
                case null:
                    results = 0;
                    break;
                default :
                    action.payload.map(result => {
                        results.push({subject: result.subject, start: new Date(moment.unix(result.start).format('LLL')), end: new Date(moment.unix(result.end).format('LLL'))})
                    });
                    break;
            }
            return results;
        default:
            return state;
    }
}
