import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { ficheAction } from '../../actions/fiche.actions'
import { bindActionCreators } from 'redux'
import LogoSquare from './../../../assets/images/nn-square.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class TopBar extends Component {
    componentDidMount() {
        if (Object.keys(this.props.fiche.fiche).length === 0) {
            this.props.getFiche()
        }
    }
    render() {
        const { fiche, loading } = this.props.fiche
        return loading ? null : (
            <div className="dash-topbar sticky-top">
                <div className="d-flex justify-content-between p-3">
                    <div style={{ height: '60' }}>
                        <img
                            src={'/' + LogoSquare}
                            style={{ height: '60', width: '60' }}
                        />
                    </div>
                    <div className="d-flex justify-content-end">
                        <div className="align-self-center mr-3">
                            <img
                                src={
                                    fiche.photo === null
                                        ? ''
                                        : fiche.photo.photo.url
                                }
                                height="60"
                                className="rounded-circle"
                                alt=""
                            />
                        </div>
                        <div className="align-self-center">
                            <strong>
                                {fiche.nom} {fiche.prenom}
                            </strong>
                            <br />
                            <Link to="/login">Déconnexion</Link>
                        </div>
                    </div>
                    <button
                        className="btn btn-link d-md-none p-0"
                        type="button"
                        data-toggle="collapse"
                        data-target="#dash-nav"
                    >
                        <span className="sr-only">Menu</span>
                        <FontAwesomeIcon className="text-primary" icon="bars" />
                    </button>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return { fiche: state.fiche }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getFiche: ficheAction.getFiche }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(TopBar)
