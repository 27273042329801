import { APINotreNotaire } from '../helpers/AxiosAPI'

export const quotationRequestsServices = {
    createQuotation,
    getQuotationRequest,
    getDataQuotationRequest,
    createReponseQualification,
    getQuotationRequests,
    refuseQuotationRequests,
}

function createQuotation(body) {
    return APINotreNotaire.post('post_notaire_quotation_request', body)
}

function getQuotationRequest(id) {
    return APINotreNotaire.get(`get_quotation_request/${id}`)
}

function getDataQuotationRequest(id) {
    return APINotreNotaire.get(`get_data_quotation_request/${id}`)
}

function createReponseQualification(id, body) {
    return APINotreNotaire.post(`create_since_dash/${id}`, body)
}

function getQuotationRequests() {
    return APINotreNotaire.get('get_quotation_requests')
}

function refuseQuotationRequests(id) {
    return APINotreNotaire.post('refuse_quotation_requests', {
        quotation_requests_id: id,
    })
}
