import { horrairesConstants } from '../constants/horraires.constants'

const horraireState = {
    loading: true,
    horraire: [],
    error: false,
}

export function horraire(state = horraireState, action) {
    switch (action.type) {
        case horrairesConstants.HORRAIRE_LOADING:
            return {
                ...state,
                loading: true,
            }
        case horrairesConstants.POSTHORRAIRE_SUCCESS:
            state.horraire.push(action.payload.data)
            state.horraire.sort((a, b) => a.jour - b.jour)
            return {
                ...state,
                horraire: state.horraire,
                loading: false,
                error: null,
            }
        case horrairesConstants.GETHORRAIRES_SUCCESS:
            return {
                ...state,
                horraire: action.payload.data,
                loading: false,
            }

        case horrairesConstants.DELETEHORRAIRE_SUCCESS:
            // eslint-disable-next-line no-case-declarations
            let indexDelete = state.horraire.findIndex(
                (horraire) => horraire.id === action.payload.data
            )
            state.horraire.splice(indexDelete, 1)
            return {
                ...state,
                horraire: state.horraire,
                loading: false,
                error: null,
            }
        default:
            return state
    }
}
