import React, { Component } from 'react'
import VisioList from './VisioList/VisioList'

class Visio extends Component {
    render() {
        return (
            <main
                className="dash-content col-12 col-md-9 col-xl-10"
                role="main"
            >
                <div className="py-5 px-2 px-sm-4">
                    <VisioList />
                </div>
            </main>
        )
    }
}

export default Visio
