import { equipeConstants } from '../constants/equipe.constants'

const equipeState = {
    loading: true,
    equipe: null,
    error: false,
}

export function equipe(state = equipeState, action) {
    switch (action.type) {
        case equipeConstants.EQUIPE_LOADING:
            return {
                ...state,
                loading: true,
            }
        case equipeConstants.GETEQUIPE_SUCCESS:
            return {
                ...state,
                loading: false,
                equipe: action.payload.data,
            }
        case equipeConstants.PUTEQUIPE_SUCCESS:
            return {
                ...state,
                loading: false,
                equipe: action.payload.data,
            }
        default:
            return state
    }
}
