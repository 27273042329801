import React, { Component } from 'react'
import FicheHeader from './FicheHeader/FicheHeader'
import FicheForm from './FicheForm/FicheForm'
import { ficheAction } from '../../actions/fiche.actions'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Loader from '../../components/Loader'

class Fiche extends Component {
    componentDidMount() {
        this.props.getFiche()
    }

    render() {
        const { fiche, loading } = this.props.fiche
        return loading ? (
            <Loader />
        ) : (
            <main
                className="dash-content col-12 col-md-9 col-xl-10"
                role="main"
            >
                <div className="py-5 px-2 px-sm-4">
                    <FicheHeader />
                    <hr />
                    <FicheForm initialValues={fiche} fiche={fiche} />
                </div>
            </main>
        )
    }
}
function mapStateToProps(state) {
    return {
        fiche: state.fiche,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getFiche: ficheAction.getFiche }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Fiche)
