import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import { categoriesAction } from '../../../../actions/category.action'
import { quotationAction } from '../../../../actions/quotation_requests.actions'
import { history } from '../../../../helpers/history'
import { bindActionCreators } from 'redux'

class CreateQuotationQualificationForm extends Component {
    constructor(props) {
        super(props)

        this.onSubmit = this.onSubmit.bind(this)
    }
    componentDidMount() {
        if (
            this.props.quotationRequest.quotationRequest.qualifications
                .length === 0
        ) {
            history.push(
                `/devis/create/${this.props.quotationRequest.quotationRequest.token}`
            )
        }
    }

    onSubmit(value) {
        this.props.createReponseQualification(
            this.props.quotationRequest.quotationRequest.token,
            {
                reponse_qualifications: { value },
            }
        )

        history.push(
            `/devis/create/${this.props.quotationRequest.quotationRequest.token}`
        )
    }

    render() {
        const { quotationRequest } = this.props.quotationRequest
        return (
            <form
                onSubmit={this.props.handleSubmit(this.onSubmit)}
                name="reponse_qualifications"
            >
                {quotationRequest.qualifications.map((qualification) => {
                    if (
                        typeof qualification.choice_qualifications !==
                            'undefined' &&
                        qualification.choice_qualifications.length > 0
                    ) {
                        return (
                            <div key={qualification.id}>
                                <label>{qualification.question}</label>
                                <div>
                                    <Field
                                        name={qualification.token}
                                        component="select"
                                    >
                                        <option></option>
                                        {qualification.choice_qualifications.map(
                                            (choice) => (
                                                <option
                                                    key={choice.id}
                                                    value={choice.choice}
                                                >
                                                    {choice.choice}
                                                </option>
                                            )
                                        )}
                                    </Field>
                                </div>
                            </div>
                        )
                    } else {
                        return (
                            <div key={qualification.id}>
                                <label>{qualification.question}</label>
                                <div>
                                    <Field
                                        name={qualification.token}
                                        component="input"
                                    />
                                </div>
                            </div>
                        )
                    }
                })}
                <br />
                <div>
                    <button
                        className="btn btn-secondary"
                        type="submit"
                        disabled={this.props.pristine || this.props.submitting}
                    >
                        Valider
                    </button>
                </div>
            </form>
        )
    }
}

function mapStateToProps(state) {
    return {
        categories: state.categories,
        quotationRequest: state.quotationRequest,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getCategories: categoriesAction.getCategories,
            createReponseQualification:
                quotationAction.createReponseQualification,
        },
        dispatch
    )
}

export default reduxForm({ form: 'create_reponse' })(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(CreateQuotationQualificationForm)
)
