import { APINotreNotaire } from '../helpers/AxiosAPI'

export const leadService = {
    getLead,
    getLeads,
    createLead,
    updateStatut,
}

function getLead(id) {
    return APINotreNotaire.get(`leads/${id}`)
}

function getLeads() {
    return APINotreNotaire.get('leads')
}

function createLead(lead) {
    return APINotreNotaire.post('leads', lead)
}

function updateStatut(lead_statut, lead_id) {
    return APINotreNotaire.post(`update_statut/${lead_id}`, {
        lead_statut: lead_statut,
    })
}
