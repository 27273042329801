import { quotationRequestsConstants } from '../constants/quotation_requests.constants'
import { userEmolumentConstants } from '../constants/user_emoluments.constants'
import { devisCompositionConstants } from '../constants/devisComposition.constants'

const initialQuotationRequest = {
    loading: true,
    quotationRequest: {},
    error: false,
}

export function quotationRequest(state = initialQuotationRequest, action) {
    switch (action.type) {
        case quotationRequestsConstants.QUOTATION_LOADING:
            return {
                ...state,
                loading: true,
            }
        case quotationRequestsConstants.POSTQUOTATION_SUCCESS:
            return {
                ...state,
                loading: false,
                quotationRequest: action.payload.data,
            }
        case quotationRequestsConstants.GETQUOTATION_SUCCESS:
            return {
                ...state,
                loading: false,
                quotationRequest: action.payload.data,
            }
        case quotationRequestsConstants.POSTREPONSEQUALIFICATION_SUCCESS:
            state.quotationRequest.reponse_qualifications = action.payload.data
            return {
                ...state,
                quotationRequest: state.quotationRequest,
            }
        case quotationRequestsConstants.RESETQUOTATION_REQUEST:
            return initialQuotationRequest
        default:
            return state
    }
}

const initialDataQuotation = {
    loading: true,
    dataQuotation: {},
    error: false,
}

export function dataQuotation(state = initialDataQuotation, action) {
    let indexEDelete, indexUEDelete
    switch (action.type) {
        case userEmolumentConstants.USEREMOLUMENT_LOADING:
            return {
                ...state,
                loading: true,
            }
        case quotationRequestsConstants.DATAQUOTATION_LOADING:
            return {
                ...state,
                loading: true,
            }
        case userEmolumentConstants.USERCONSULTING_LOADING:
            return {
                ...state,
                loading: true,
            }
        case quotationRequestsConstants.GETDATAQUOTATION_SUCCESS:
            return {
                ...state,
                loading: false,
                dataQuotation: action.payload.data,
            }
        case userEmolumentConstants.POSTUSERCONSULTING_SUCCESS:
            state.dataQuotation.consulting = parseInt(
                action.payload.data.honoraire_ht
            )
            return {
                ...state,
                loading: false,
                dataQuotation: state.dataQuotation,
            }
        case userEmolumentConstants.POSTUSEREMOLUMENT_SUCCESS:
            indexEDelete = state.dataQuotation.emoluments.findIndex(
                (emolument) => emolument.id === action.payload.data.emolument.id
            )
            state.dataQuotation.emoluments.splice(indexEDelete, 1)
            state.dataQuotation.user_emoluments.push(action.payload.data)
            return {
                ...state,
                loading: false,
                dataQuotation: state.dataQuotation,
            }
        case devisCompositionConstants.ADDFREEEMOLUMENTDEVIS:
            indexUEDelete = state.dataQuotation.user_emoluments.findIndex(
                (emolument) => emolument.id === action.payload.data.id
            )
            state.dataQuotation.user_emoluments.splice(indexUEDelete, 1)
            return {
                ...state,
                loading: false,
                dataQuotation: state.dataQuotation,
            }
        case quotationRequestsConstants.RESETQUOTATION_REQUEST:
            return initialDataQuotation
        default:
            return state
    }
}

const initialQuotationsRequest = {
    loading: true,
    quotationsRequest: [],
    error: false,
}

export function quotationsRequest(state = initialQuotationsRequest, action) {
    switch (action.type) {
        case quotationRequestsConstants.QUOTATIONS_LOADING:
            return {
                ...state,
                loading: true,
            }
        case quotationRequestsConstants.GETQUOTATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                quotationsRequest: action.payload.data,
            }
        case quotationRequestsConstants.REFUSEQUOTATION_SUCCESS:
            state.quotationsRequest.splice(
                state.quotationsRequest.findIndex(
                    (quotationRequest) =>
                        quotationRequest.id === action.payload.data
                ),
                1
            )
            return {
                ...state,
                loading: false,
                quotationRequest: state.quotationsRequest,
            }
        default:
            return state
    }
}
