import React from 'react';
import VisioListHeader from './VisioListHeader/VisioListHeader';
import VisioCard from './VisioCard/VisioCard';

const VisioList = (props) => {
return(
    <div className='containerCards'>
      <VisioListHeader/>
      <hr/>
      <VisioCard/>
    </div>
  )
}

export default VisioList;
