import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { existQuotation } from '../../actions/devis.actions'
import CardQuotation from '../Quotation/ListQuotations/CardQuotation/CardQuotation'
import Loader from '../../components/Loader'
import { Redirect } from 'react-router-dom'

class ExistQuotation extends Component {
    componentDidMount() {
        this.props.existQuotation(this.props.match.params.id)
    }

    render() {
        const { loading, devis } = this.props.devis
        return loading ? (
            <main
                className="dash-content col-12 col-md-9 col-xl-10 d-flex justify-content-center my-5"
                role="main"
            >
                <Loader />
            </main>
        ) : (
            devis == undefined ?  <Redirect
            to={{ pathname: '/devis'}}
        /> : (
            <main
                className="dash-content col-12 col-md-9 col-xl-10"
                role="main"
            >
                <div className="py-5 px-2 px-sm-4">
                <div className="mb-2">
                <div className="d-flex">
                    <div>
                        <p className="h1 mb-1">
                            Vous avez déjà répondu à cette demande de devis  :
                        </p>
                    </div>
                </div>
            </div>
                    <CardQuotation devis={devis} key={devis.id} />
                </div>
            </main>
            )
        )
    }
}

function mapStateToProps(state) {
    return {
        devis: state.devis,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ existQuotation }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ExistQuotation)
