import React, { Component } from 'react'
import NavBarItem from './NavBarItem'
import { connect } from 'react-redux'
import { getWebsite } from '../../actions/website.actions'
import { bindActionCreators } from 'redux'

class NavBar extends Component {
    componentDidMount() {
        this.props.getWebsite()
    }

    render() {
        return (
            <div className="dash-sidebar col-12 col-md-3 col-xl-2 p-0">
                <div className="sticky-top">
                    <nav className="dash-nav collapse" id="dash-nav">
                        <ul className="nav flex-column">
                            <NavBarItem
                                link="/leads"
                                svg="user-friends"
                                content="Demandes de contact"
                            />
                            {this.props.user.devis ? (
                                <NavBarItem
                                    link="/devis"
                                    svg="file-invoice"
                                    content="Devis"
                                    submenu={[
                                        {
                                            link: '/devis',
                                            content: 'Demande de devis',
                                        },
                                        {
                                            link: '/devis/list',
                                            content: 'Devis envoyés',
                                        },
                                        {
                                            link: '/devis/dossiers',
                                            content: 'Dossiers acceptés',
                                        },
                                    ]}
                                />
                            ) : null}
                            <NavBarItem
                                link="/rating"
                                svg="star"
                                content="Recommandation"
                                submenu={[
                                    { link: '/rating', content: 'Reçues' },
                                    {
                                        link: '/rating/envoyees',
                                        content: 'Envoyées',
                                    },
                                ]}
                            />
                            {this.props.website.website != null &&
                            this.props.website.website.live === true ? (
                                <NavBarItem
                                    link="/website"
                                    svg="desktop"
                                    content="Site perso"
                                    submenu={[
                                        {
                                            link: '/website',
                                            content: 'Votre site',
                                        },
                                        {
                                            link: '/website/home_page',
                                            content: 'Home page',
                                        },
                                        {
                                            link: '/website/office',
                                            content: "L'office",
                                        },
                                        {
                                            link: '/website/equipe',
                                            content: "L'équipe",
                                        },
                                        {
                                            link: '/website/articles',
                                            content: 'Vos articles',
                                        },
                                    ]}
                                />
                            ) : null}
                            <NavBarItem
                                link="/fiche"
                                svg="file"
                                content="Fiche"
                                submenu={[
                                    { link: '/fiche', content: 'Votre fiche' },
                                    {
                                        link: '/fiche/horaires',
                                        content: 'Vos horaires',
                                    },
                                    {
                                        link: '/fiche/tarifs',
                                        content: 'Vos tarifs',
                                    },
                                ]}
                            />
                        </ul>
                    </nav>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        website: state.website,
        user: state.authentication.user,
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getWebsite }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBar)
