import ReactTooltip from 'react-tooltip'
import OfficeTitle from '../../../../assets/images/office_title.png'
import OfficeDesc from '../../../../assets/images/office_desc.png'
import OfficePhoto from '../../../../assets/images/office_photo.png'
import OfficeCita from '../../../../assets/images/office_cita.png'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'

import InputCountCharField from '../../../components/Input/InputCountCharField'
import TextareaCountCharField from '../../../components/Input/TextareaCountCharField'
import InputPhotoField from '../../../components/Input/InputPhotoField'
import { officeActions } from '../../../actions/office.actions'

class OfficeForm extends Component {
    constructor(props) {
        super(props)

        this.handleSubmit = this.handleSubmit.bind(this)
    }
    handleSubmit(value) {
        this.props.updateOffice(value, value.id)
    }

    render() {
        return (
            <form id="update_office">
                <div className="padding_form">
                    <label>
                        <b>Titre:</b>
                    </label>
                    <label data-tip data-for="visible1" className="">
                        <span className="asktooltip">?</span>
                        <ReactTooltip
                            id="visible1"
                            type="info"
                            effect="solid"
                            place="right"
                        >
                            <p>
                                C'est le titre de votre page "Office". Il est
                                entouré en vert dans l'exemple
                            </p>
                            <img src={'/' + OfficeTitle} alt="title" />
                        </ReactTooltip>
                    </label>
                    <p style={{ color: 'rgb(230, 126, 35)' }}>
                        <b>Attention</b>: Le titre ci-dessous a été rédigé par
                        notre spécialiste. Il est déjà optimisé pour le
                        référencement sur Google. En changeant ce dernier, vous
                        risquez de perdre de la puissance.
                    </p>
                    <div>
                        <Field
                            name="title"
                            component={InputCountCharField}
                            className="fiche_update"
                        />
                    </div>
                </div>
                <div className="padding_form">
                    <label>
                        <b>Description:</b>
                    </label>
                    <label data-tip data-for="visible3" className="">
                        <span className="asktooltip">?</span>
                        <ReactTooltip
                            id="visible3"
                            type="info"
                            effect="solid"
                            place="right"
                        >
                            <p>
                                Il s'agit de la description de votre page
                                office. Elle est entourée en vert dans
                                l'exemple:
                            </p>
                            <img src={'/' + OfficeDesc} />
                        </ReactTooltip>
                    </label>
                    <p style={{ color: '#33ab9f' }}>
                        Nous vous conseillons un maximum de 800 characters
                    </p>
                    <div>
                        <Field
                            name="description"
                            component={TextareaCountCharField}
                            style={{ height: '200px' }}
                        />
                    </div>
                </div>
                <div className="padding_form">
                    <label>
                        <b>Une petit mot de votre part:</b>
                    </label>
                    <label data-tip data-for="visible2" className="">
                        <span className="asktooltip">?</span>
                        <ReactTooltip
                            id="visible2"
                            type="info"
                            effect="solid"
                            place="right"
                        >
                            <p>
                                Il s'agit de la citation mise en forme comme
                                dans l'exemple ci-dessous:
                            </p>
                            <img src={'/' + OfficeCita} />
                        </ReactTooltip>
                    </label>

                    <p style={{ color: '#33ab9f' }}>
                        Nous vous conseillons un maximum de 150 characters
                    </p>
                    <div>
                        <Field
                            name="citation"
                            component={TextareaCountCharField}
                            style={{ height: '100px' }}
                        />
                    </div>
                </div>
                <div className="padding_form">
                    <label className="label-file">
                        <b>Photo d'illustration:</b>
                    </label>
                    <label data-tip data-for="visible4" className="">
                        <span className="asktooltip">?</span>
                        <ReactTooltip
                            id="visible4"
                            type="info"
                            effect="solid"
                            place="right"
                        >
                            <p>
                                Il s'agit de la photo qui illustre votre office:
                            </p>
                            <img src={'/' + OfficePhoto} />
                        </ReactTooltip>
                    </label>
                    <Field
                        name="photo"
                        component={InputPhotoField}
                        style={{
                            height: '340px',
                            marginRight: 'auto',
                            marginLeft: 'auto',
                            display: 'block',
                            paddingTop: '12px',
                        }}
                    />
                </div>
                <div className="text-right mt-3">
                    <button
                        type="button"
                        id="update_office"
                        onClick={this.props.handleSubmit(this.handleSubmit)}
                        className="btn btn-primary"
                    >
                        Enregistrer les modifications
                    </button>
                </div>
            </form>
        )
    }
}

export default reduxForm({ form: 'update_office' })(
    connect(null, { updateOffice: officeActions.updateOffice })(OfficeForm)
)
