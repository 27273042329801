import React, { Component } from 'react'
import StatutForm from './../UserHeader/StatutModal/StatutForm'
import { connect } from 'react-redux'
import { DateTime } from 'luxon'
import Modal from '../../../components/Modal/Modal'
import AskRatingForm from '../../Rating/RatingHeader/AskRating/AskRatingForm'
import Description from '../../../components/Description'

const badgeStatut = {
    1: (
        <h5>
            <span className="badge badge-primary">Non traité</span>
        </h5>
    ),
    2: (
        <h5>
            <span className="badge badge-info">En cours de traitement</span>
        </h5>
    ),
    3: (
        <h5>
            <span className="badge badge-secondary">Succès</span>
        </h5>
    ),
    4: (
        <h5>
            <span className="badge badge-danger">Abandon</span>
        </h5>
    ),
}
const leadStatut = {
    1: 'Non traité',
    2: 'En cours de traitement',
    3: 'Succès',
    4: 'Abandon',
}

class UserHistory extends Component {
    render() {
        const { lead } = this.props.lead
        return (
            <div className="shadow p-3 mb-5 bg-white rounded">
                <div className="row mb-4">
                    <div className="col-6 offset-6 text-right">
                        {
                            badgeStatut[
                                lead.lead_statuts.history_statut[0].statut_id
                            ]
                        }
                    </div>
                </div>
                <div className="border-bottom mb-3">
                    <p>
                        <span className="font-weight-bold text-primary">
                            Information :
                        </span>
                        <br /> Demande fait le{' '}
                        {DateTime.fromISO(lead.created_at).toLocaleString(
                            DateTime.DATETIME_SHORT
                        )}{' '}
                        pour {lead.category.category_name}
                    </p>
                    <p>
                        <span className="font-weight-bold text-primary">
                            Description :
                        </span>
                        <br />
                        <Description lead={lead} />
                    </p>
                </div>
                {lead.lead_statuts.history_statut.map((statut) => (
                    <div key={statut.id}>
                        {statut.statut_id === 1 ? null : (
                            <p>
                                {DateTime.fromISO(
                                    statut.created_at
                                ).toLocaleString(DateTime.DATETIME_SHORT)}
                                {' - '}{' '}
                                <span className="font-weight-bold">
                                    {leadStatut[statut.statut_id]}
                                </span>
                                <br />
                                <span className="font-italic">
                                    {statut.commentaire}
                                </span>
                            </p>
                        )}
                    </div>
                ))}
                {lead.lead_statuts.history_statut[0].statut_id === 3 ? (
                    <div className="text-center">
                        <Modal
                            id="AddRating"
                            btnStyle="btn btn-secondary"
                            btnTitle="Collecter son avis"
                        >
                            <AskRatingForm />
                        </Modal>
                    </div>
                ) : (
                    <div className="text-center">
                        <p className="font-weight-bold">
                            Pour un meilleur suivi de la demande :
                        </p>
                        <Modal
                            id="AddStatut"
                            btnStyle="btn btn-secondary"
                            btnTitle="Modifier le statut"
                        >
                            <StatutForm initialValues={{ statut_id: '2' }} />
                        </Modal>
                    </div>
                )}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return { lead: state.lead }
}

export default connect(mapStateToProps, null)(UserHistory)
