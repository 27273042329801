import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getDossiers } from '../../../actions/dossiers.actions'
import ListDossiersHeader from './ListDossiersHeader/ListDossiersHeader'
import CardDossier from './CardDossier/CardDossier'
import Loader from '../../../components/Loader'

class ListDossiers extends Component {
    componentDidMount() {
        this.props.getDossiers()
    }

    render() {
        const { loading, dossiers } = this.props.dossiers
        return loading ? (
            <main
                className="dash-content col-12 col-md-9 col-xl-10 d-flex justify-content-center my-5"
                role="main"
            >
                <Loader />
            </main>
        ) : (
            <main
                className="dash-content col-12 col-md-9 col-xl-10"
                role="main"
            >
                <div className="py-5 px-2 px-sm-4">
                    <ListDossiersHeader />
                    {dossiers.map((dossier) => (
                        <CardDossier dossier={dossier} key={dossier.id} />
                    ))}
                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    return {
        dossiers: state.dossiers,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getDossiers }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ListDossiers)
