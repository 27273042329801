import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { leadsAction } from '../../actions/lead.actions'
import UserHeader from './UserHeader/UserHeader'
import UserInfo from './UserInfo/UserInfo'
import UserHistory from './UserHistory/UserHistory'
import Loader from '../../components/Loader'

class UserShow extends Component {
    componentDidMount() {
        if (this.props.lead.lead === null) {
            this.props.getLead(this.props.match.params.id)
        }
    }
    render() {
        const { loading } = this.props.lead
        return loading ? (
            <Loader />
        ) : (
            <main
                className="dash-content col-12 col-md-9 col-xl-10"
                role="main"
            >
                <div className="py-5 px-2 px-sm-4">
                    <UserHeader />
                    <UserInfo />
                    <UserHistory />
                </div>
            </main>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const id = parseInt(ownProps.match.params.id, 10)
    if (state.leads.leads.length !== 0) {
        state.lead.lead = state.leads.leads.find((lead) => lead.id === id)
        state.lead.loading = false
    }
    return { lead: state.lead }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getLead: leadsAction.getLead }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(UserShow)
