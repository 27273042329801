import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { createRoomInvitation } from '../../../../../actions/room.actions';
import renderDatePicker from '../../../../../helpers/renderDatePicker';
import moment from 'moment';
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import fr from 'date-fns/locale/fr';
registerLocale('fr', fr)

class VisioAddForm extends Component {


   onSubmit = (values) => {
      this.props.createRoomInvitation(values, this.props.room.id)
          window.location.reload();
      };

  render(){

    return(
          <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
           <h2>Envoyer une invitation à rejoindre {this.props.room.name}</h2>
              <div>
                <label>Nom du participant</label>
                <div>
                  <Field name="name" component="input" />
                </div>
              </div>
              <div>
                <label>Prénom du participant</label>
                <div>
                  <Field name="firstname" component="input"/>
                </div>
              </div>
              <div>
                <label>Email du participant</label>
                <div>
                  <Field name="email" component="input"/>
                </div>
              </div>
               <div>
                <label>Téléphone du participant</label>
                <div>
                  <Field name="phone_number" component="input"/>
                </div>
              </div>
               <div>
                <label>Séléctionnez la date et l'heure de votre RDV visio</label>
                <div>
                  <Field
                    name="date_time"
                    showTimeSelect
                    timeIntervals={15}
                    locale="fr"
                    timeFormat="HH:mm"
                    dateFormat="Pp"
                    normalize={value => (value ? moment(value).format('YYYY-MM-DD HH:mm') : null)}
                    component={renderDatePicker}
                  />
                </div>
              </div>
               <div>
                <label>Message pour le participant</label>
                <div>
                  <Field name="comment" component="textarea"/>
                </div>
              </div>
              <div>
               <button className='btn btn-secondary' type="submit" disabled={this.props.pristine || this.props.submitting}>
                  Envoyer l'invitation
                </button>
              </div>
          </form>
    )
  }
}


export default reduxForm({ form: 'visio_add' })(
  connect(null, { createRoomInvitation })(VisioAddForm)
);
