export async function token(data, provider) {
    let regex = RegExp('[a-z.+-]{1,64}@paris.notaires.fr')
    let webmail = ''

    switch (provider) {
        case '1':
            webmail = 'eas.notamail.eu'
            break
        case '2':
            webmail = 'mail.fiducial.com'
            break
    }

    if (regex.test(data.mail)) {
    }

    const bodyAuth = {
        client_id: process.env.NYLAS_CLIENT_ID,
        name: data.mail,
        email_address: data.mail,
        provider: 'exchange',
        settings: {
            username: data.mail,
            password: data.password,
            server: webmail,
        },
        scopes: 'calendar, email.modify, email.send, contacts',
    }

    const optionAuth = {
        crossDomain: true,
        method: 'POST',
        body: JSON.stringify(bodyAuth),
        credentials: 'omit',
        header: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': 'https://app.notre-notaire.fr',
        },
        mode: 'cors',
    }

    return await fetch(
        'https://cors-anywhere.herokuapp.com/' +
            'https://api.nylas.com/connect/authorize',
        optionAuth
    )
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText)
            }
            return response.json()
        })
        .then((responseJson) => {
            const bodyToken = {
                client_id: process.env.NYLAS_CLIENT_ID,
                client_secret: process.env.NYLAS_CLIENT_SECRET,
                code: responseJson.code,
            }

            const optionToken = {
                method: 'POST',
                body: JSON.stringify(bodyToken),
                header: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin':
                        'https://app.notre-notaire.fr',
                },
                mode: 'cors',
            }
            return fetch(
                'https://cors-anywhere.herokuapp.com/' +
                    'https://api.nylas.com/connect/token',
                optionToken
            )
        })
        .then((responseToken) => responseToken.json())
        .then((responseTokenJson) => {
            return responseTokenJson.access_token
        })
        .catch((error) => 'error')
}
