import { statistiquesConstants } from '../constants/statisiques.constants'
import { statistiquesServices } from '../services/statisiques.services'
import { res } from '../helpers/dispatch'

export function getStatVisits() {
    return (dispatch) => {
        dispatch(res.loading(statistiquesConstants.STAT_LOADING))
        statistiquesServices
            .getStatVisits()
            .then((response) => {
                let statVisit = response.data
                dispatch(
                    res.success(
                        statistiquesConstants.GETSTATVISITES_SUCCESS,
                        statVisit
                    )
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(
                        statistiquesConstants.GETSTATVISITES_FAILURE,
                        error
                    )
                )
            })
    }
}

export function getStatsRadar() {
    return (dispatch) => {
        dispatch(res.loading(statistiquesConstants.STAT_LOADING))
        statistiquesServices
            .getStatsRadar()
            .then((response) => {
                let radar = response.data
                dispatch(
                    res.success(
                        statistiquesConstants.GETSTATSRADAR_SUCCESS,
                        radar
                    )
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(
                        statistiquesConstants.GETSTATSRADAR_FAILURE,
                        error
                    )
                )
            })
    }
}

export function getStatScoreHistory() {
    return (dispatch) => {
        dispatch(res.loading(statistiquesConstants.STAT_LOADING))
        statistiquesServices
            .getStatScoreHistory()
            .then((response) => {
                let scoreHistory = response.data
                dispatch(
                    res.success(
                        statistiquesConstants.GETSTATSCOREHISTORY_SUCCESS,
                        scoreHistory
                    )
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(
                        statistiquesConstants.GETSTATSCOREHISTORY_FAILURE,
                        error
                    )
                )
            })
    }
}

export function getPositionFiche() {
    return (dispatch) => {
        dispatch(res.loading(statistiquesConstants.STAT_LOADING))
        statistiquesServices
            .getPositionFiche()
            .then((response) => {
                let positionFiche = response.data
                dispatch(
                    res.success(
                        statistiquesConstants.GETPOSITIONFICHE_SUCCESS,
                        positionFiche
                    )
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(
                        statistiquesConstants.GETPOSITIONFICHE_FAILURE,
                        error
                    )
                )
            })
    }
}

export function getStatVisitsWebsite() {
    return (dispatch) => {
        dispatch(res.loading(statistiquesConstants.STAT_LOADING))
        statistiquesServices
            .getStatVisitsWebsite()
            .then((response) => {
                let visitsWebsite = response.data
                dispatch(
                    res.success(
                        statistiquesConstants.GETSTATVISITESWEBSITE_SUCCESS,
                        visitsWebsite
                    )
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(
                        statistiquesConstants.GETSTATVISITESWEBSITE_FAILURE,
                        error
                    )
                )
            })
    }
}

export function getStatVisitsUniqueWebsite() {
    return (dispatch) => {
        dispatch(res.loading(statistiquesConstants.STAT_LOADING))
        statistiquesServices
            .getStatVisitsUniqueWebsite()
            .then((response) => {
                let visitsUniqueWebsite = response.data
                dispatch(
                    res.success(
                        statistiquesConstants.GETSTATUNIQUEWEBSITE_SUCCESS,
                        visitsUniqueWebsite
                    )
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(
                        statistiquesConstants.GETSTATUNIQUEWEBSITE_FAILURE,
                        error
                    )
                )
            })
    }
}

export function getStatPagessWebsite() {
    return (dispatch) => {
        dispatch(res.loading(statistiquesConstants.STAT_LOADING))
        statistiquesServices
            .getStatPagessWebsite()
            .then((response) => {
                let pagessWebsite = response.data
                dispatch(
                    res.success(
                        statistiquesConstants.GETSTATSPAGESWEBSITE_SUCCESS,
                        pagessWebsite
                    )
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(
                        statistiquesConstants.GETSTATSPAGESWEBSITE_FAILURE,
                        error
                    )
                )
            })
    }
}
