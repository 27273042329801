import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import { userEmolumentsAction } from '../../../../../actions/user_emoluments.actions'
import { bindActionCreators } from 'redux'
import { modalActions } from '../../../../../actions/modal.actions'

class ConsultingForm extends Component {
    constructor(props) {
        super(props)
        this.onSubmit = this.onSubmit.bind(this)
    }

    onSubmit(values) {
        this.props.createUserConsulting(values)
        this.props.hideModal('AddConsulting')
    }

    render() {
        return (
            <form onSubmit>
                <h2 className="text-center">
                    Mes honoraires Hors Taxe pour une heure
                </h2>
                <div>
                    <label>Montant en € HT</label>
                    <div>
                        <Field
                            name="honoraire_ht"
                            component="input"
                            type="number"
                        />
                    </div>
                </div>

                <div className="mt-3">
                    <button
                        className="btn btn-secondary"
                        type="button"
                        onClick={this.props.handleSubmit(this.onSubmit)}
                        disabled={this.props.pristine || this.props.submitting}
                    >
                        Enregistrer
                    </button>
                </div>
            </form>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            createUserConsulting: userEmolumentsAction.createUserConsulting,
            hideModal: modalActions.hideModal,
        },
        dispatch
    )
}

export default reduxForm({ form: 'consulting_add' })(
    connect(null, mapDispatchToProps)(ConsultingForm)
)
