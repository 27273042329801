import React, { Component } from 'react'
import TitleHelp from '../../../../assets/images/tooltip_title.png'
import TooltipDesc from '../../../../assets/images/tootltip_description.png'
import TooltipPhoto from '../../../../assets/images/tooltip_photo.png'
import { homeActions } from '../../../actions/home.actions'
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import { reduxForm, Field } from 'redux-form'
import InputCountCharField from '../../../components/Input/InputCountCharField'
import TextareaCountCharField from '../../../components/Input/TextareaCountCharField'
import InputPhotoField from '../../../components/Input/InputPhotoField'

class HomePageForm extends Component {
    constructor(props) {
        super(props)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleSubmit(value) {
        this.props.updateHome(value, value.id)
    }

    render() {
        return (
            <form id="update_home">
                <div className="padding_form">
                    <label>
                        <b>Titre:</b>
                    </label>
                    <label data-tip data-for="visible1" className="">
                        <span className="asktooltip">?</span>
                        <ReactTooltip
                            id="visible1"
                            type="info"
                            effect="solid"
                            place="right"
                        >
                            <p>
                                C'est le titre de votre page d'acceuil. Il est
                                entouré en vert dans l'exemple ci-dessous:
                            </p>
                            <img src={'/' + TitleHelp} alt="title" />
                        </ReactTooltip>
                    </label>
                    <p style={{ color: '#33ab9f' }}>
                        Si vous changé le titre, limitez le à un titre court
                        (exemple: "Etude [Nom de l'étude] à [Nom de la ville]"
                        ou "[Nom + Prénom] à [Nom de la ville]"){' '}
                    </p>
                    <p style={{ color: 'rgb(230, 126, 35)' }}>
                        <b>Attention</b>: Le titre ci-dessous a été rédigé par
                        notre spécialiste. Il est déjà optimisé pour le
                        référencement sur Google. En changeant ce dernier, vous
                        risquez de perdre de la puissance.
                    </p>
                    <div>
                        <Field name="title" component={InputCountCharField} />
                    </div>
                </div>
                <div className="padding_form">
                    <label>
                        <b>Description:</b>
                    </label>
                    <label data-tip data-for="visible2" className="">
                        <span className="asktooltip">?</span>
                        <ReactTooltip
                            id="visible2"
                            type="info"
                            effect="solid"
                            place="right"
                        >
                            <p>
                                Il s'agit de la description de votre activité
                                dans la page d'acceuil.Elle est entourée en vert
                                dans l'exemple ci-dessous:
                            </p>
                            <img src={'/' + TooltipDesc} />
                        </ReactTooltip>
                    </label>
                    <p style={{ color: '#33ab9f' }}>
                        Nous vous conseillons un maximum de 165 characters
                    </p>
                    <div>
                        <Field
                            name="description"
                            component={TextareaCountCharField}
                            style={{ height: '200px' }}
                        />
                    </div>
                </div>
                <div className="padding_form">
                    <label className="label-file">
                        <b>Photo d'illustration:</b>
                    </label>
                    <label data-tip data-for="visible3" className="">
                        <span className="asktooltip">?</span>
                        <ReactTooltip
                            id="visible3"
                            type="info"
                            effect="solid"
                            place="right"
                        >
                            <p>
                                Il s'agit de la photo qui illustre votre page
                                d'accueil:
                            </p>
                            <img src={'/' + TooltipPhoto} />
                        </ReactTooltip>
                    </label>
                    <Field
                        name="photo"
                        component={InputPhotoField}
                        style={{
                            height: '340px',
                            marginRight: 'auto',
                            marginLeft: 'auto',
                            display: 'block',
                            paddingTop: '12px',
                        }}
                    />
                </div>
                <div className="text-right mt-3">
                    <button
                        type="button"
                        id="update_home"
                        onClick={this.props.handleSubmit(this.handleSubmit)}
                        className="btn btn-primary"
                    >
                        Enregistrer les modifications
                    </button>
                </div>
            </form>
        )
    }
}

export default reduxForm({ form: 'update_home' })(
    connect(null, { updateHome: homeActions.updateHome })(HomePageForm)
)
