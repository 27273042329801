import React, { Component } from 'react'
import { getStatScoreHistory } from '../../../actions/statistiques.actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from 'recharts'

class StatScoreHistory extends Component {
    componentDidMount() {
        if (Object.keys(this.props.stats).length === 0) {
            this.props.getStatScoreHistory()
        }
    }

    render() {
        return (
            <div style={{ width: '100%', height: 300 }}>
                <h3>
                    <strong>Historique du score de votre fiche:</strong>
                </h3>
                <p>(100 étant le meilleur score)</p>
                <ResponsiveContainer>
                    <AreaChart
                        data={this.props.stats}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="day" />
                        <YAxis />
                        <Tooltip />
                        <Area
                            type="monotone"
                            dataKey="score"
                            stroke="#e7a3a3"
                            fill="#e7a3a3"
                        />
                    </AreaChart>
                </ResponsiveContainer>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        stats: state.stats.scoreHistory,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { getStatScoreHistory: getStatScoreHistory },
        dispatch
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(StatScoreHistory)
