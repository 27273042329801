import React, { Component, Fragment } from 'react'
import { reduxForm, Field } from 'redux-form'
import InputPhotoField from '../../../../components/Input/InputPhotoField'

class TeammateForm extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { handleSubmit } = this.props

        return (
            <Fragment>
                <form name="teammate">
                    <div className="">
                        <label>
                            <b>Prénom:</b>
                        </label>
                        <div>
                            <Field
                                name="firstname"
                                component="input"
                                type="text"
                                lassName="fiche_update"
                            />
                        </div>
                        <label>
                            <b>Nom de famille:</b>
                        </label>
                        <div>
                            <Field
                                name="lastname"
                                component="input"
                                type="text"
                                className="fiche_update"
                            />
                        </div>
                        <label>
                            <b>Fonction:</b>
                        </label>
                        <div>
                            <Field
                                name="role"
                                component="input"
                                className="fiche_update"
                            />
                        </div>
                    </div>
                    <div className="">
                        <label>
                            <b>Quelques mots:</b>
                        </label>
                        <div>
                            <Field
                                name="description"
                                component="textarea"
                                type="text"
                                className="fiche_update"
                                style={{ height: '100px' }}
                            />
                        </div>
                    </div>
                    <div className="">
                        <label className="label-file">
                            <b>Photo:</b>
                        </label>
                        <Field
                            name="photo"
                            component={InputPhotoField}
                            style={{
                                width: '240px',
                                height: '240px',
                                borderRadius: '8px',
                                margin: 'auto',
                                display: 'block',
                            }}
                        />
                    </div>
                    <div className="text-right mt-3">
                        <button
                            className="btn btn-primary "
                            type="button"
                            onClick={handleSubmit}
                        >
                            Valider
                        </button>
                    </div>
                </form>
            </Fragment>
        )
    }
}

export default reduxForm({
    form: 'teammate',
})(TeammateForm)
