import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field, Fields } from 'redux-form'
import { bindActionCreators } from 'redux'
import { ficheAction, updateFiche } from '../../../actions/fiche.actions'
import { categoriesAction } from '../../../actions/category.action'
import PlacesAutocomplete from '../../../helpers/renderMapAutocomplet'
import { FileInput } from '../../../helpers/FileInput'
import { Image, CloudinaryContext } from 'cloudinary-react'
import InputCatField from '../../../components/Input/InputCatField'
import Loader from '../../../components/Loader'
import CkEditorField from '../../../components/Input/CkEditorField'
import InputCountCharField from '../../../components/Input/InputCountCharField'

class FicheForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            url: this.props.fiche.photo.photo.url,
        }
    }

    componentDidMount() {
        if (this.props.categories.categories.length === 0) {
            this.props.getCategories()
        }
    }

    onSubmit = (value) => {
        this.props.updateFiche(value, value.id)
    }

    render() {
        const { loading, categories } = this.props.categories
        return (
            <Fragment>
                <form
                    onSubmit={this.props.handleSubmit(this.onSubmit)}
                    name="fiche"
                >
                    <div className="row">
                        <div className="col">
                            <div className="padding_form">
                                <label>
                                    <b>Nom:</b>
                                </label>
                                <div>
                                    <Field
                                        name="nom"
                                        component="input"
                                        className="fiche_update"
                                    />
                                </div>
                            </div>
                            <div className="padding_form">
                                <label>
                                    <b>Téléphone visible sur votre fiche:</b>
                                </label>
                                <div>
                                    <Field
                                        name="telephone"
                                        component="input"
                                        className="fiche_update"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="padding_form">
                                <label>
                                    <b>Prénom:</b>
                                </label>
                                <div>
                                    <Field
                                        name="prenom"
                                        component="input"
                                        className="fiche_update"
                                    />
                                </div>
                            </div>
                            <div className="padding_form">
                                <label>
                                    <b>Adresse e-mail de contact:</b>
                                </label>
                                <div>
                                    <Field
                                        name="email_contact"
                                        component="input"
                                        className="fiche_update"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="padding_form">
                                <label>
                                    <b>
                                        Téléphone portable pour notification SMS
                                        de contact:
                                    </b>
                                </label>
                                <div>
                                    <Field
                                        name="contact_phonenumber"
                                        component="input"
                                        className="fiche_update"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="padding_form">
                        <label>
                            <b>Nom de l'étude:</b>
                        </label>
                        <div>
                            <Field
                                name="nom_entrepriset"
                                component="input"
                                className="fiche_update"
                            />
                        </div>
                    </div>
                    <div className="padding_form">
                        <label>
                            <b>
                                Adresse de l'étude:{' '}
                                <span className="adresse_update_fiche"></span>
                            </b>
                        </label>
                        <div>
                            <Field
                                name="adresse"
                                component={PlacesAutocomplete}
                                className="fiche_update"
                            />
                        </div>
                    </div>
                    <hr className="padding_form" />
                    <div className="padding_form">
                        <label>
                            <b>
                                Description courte de votre étude visible dans
                                la liste de résultats (200 caractères maximum):
                            </b>
                        </label>
                        <div>
                            <Field
                                name="chapeau"
                                component={InputCountCharField}
                                className="fiche_update"
                            />
                        </div>
                    </div>
                    <hr className="padding_form" />
                    <div className="padding_form">
                        <label>
                            <b>La présentation de votre activité:</b>
                        </label>
                        <div>
                            <Field
                                component={CkEditorField}
                                name="description"
                                className="fiche_update fiche_description"
                            />
                        </div>
                    </div>
                    <hr className="padding_form" />
                    <div className="row">
                        <div className="col">
                            <div className="padding_form">
                                <label>
                                    <b>Année de prestation de serment:</b>
                                </label>
                                <div>
                                    <Field
                                        name="annee_creation"
                                        component="input"
                                        type="date"
                                    />
                                </div>
                            </div>
                            <div className="padding_form">
                                <label>
                                    <b>Site internet</b>
                                </label>
                                <div>
                                    <Field
                                        name="site_internet"
                                        component="input"
                                        className="fiche_update"
                                    />
                                </div>
                            </div>
                            <div className="padding_form">
                                <label>
                                    <b>Signature électronique:</b>
                                </label>
                                <div>
                                    <Field
                                        name="signature_electronique"
                                        component="input"
                                        type="checkbox"
                                        className="fiche_update"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="padding_form">
                                <label>
                                    <b>Linkedin</b>
                                </label>
                                <div>
                                    <Field
                                        name="linkedin"
                                        component="input"
                                        className="fiche_update"
                                    />
                                </div>
                            </div>
                            <div className="padding_form">
                                <label>
                                    <b>Viadeo</b>
                                </label>
                                <div>
                                    <Field
                                        name="viadeo"
                                        component="input"
                                        className="fiche_update"
                                    />
                                </div>
                            </div>
                            <div className="padding_form">
                                <label>
                                    <b>Visio conférence:</b>
                                </label>
                                <div>
                                    <Field
                                        name="visio"
                                        component="input"
                                        type="checkbox"
                                        className="fiche_update"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="padding_form" />
                    {loading ? (
                        <Loader />
                    ) : (
                        <Fragment>
                            <div className="form-group px-1 mt-5">
                                <label>Spécialités :</label>
                            </div>
                            <Fields
                                component={InputCatField}
                                names={[
                                    'categories',
                                    'category_ids',
                                    'category_ids_forward',
                                ]}
                                allCategories={categories}
                            />
                        </Fragment>
                    )}

                    <hr className="padding_form" />
                    <div className="padding_form">
                        <label className="label-file">
                            <b>Photo de profil</b>
                        </label>
                        <div className="row">
                            <div className="col">
                                <Field
                                    name="photo"
                                    component={FileInput}
                                    onChange={this.handleFileChange}
                                    className="inputfile"
                                />
                            </div>
                            <div className="col photo_preview">
                                <CloudinaryContext>
                                    <Image
                                        cloudName="photo_fiche"
                                        publicId={this.state.url}
                                        width="300"
                                        crop="scale"
                                        width="150"
                                        className="image_preview"
                                        secure="true"
                                    />
                                </CloudinaryContext>
                            </div>
                        </div>
                    </div>
                    <button
                        className="btn btn-secondary btn-lg margin_30"
                        type="submit"
                    >
                        Enregistrer
                    </button>
                </form>
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        categories: state.categories,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getCategories: categoriesAction.getCategories,
            updateFiche: ficheAction.updateFiche,
        },
        dispatch
    )
}

export default reduxForm({
    form: 'update_fiche',
})(connect(mapStateToProps, mapDispatchToProps)(FicheForm))
