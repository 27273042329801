export function csiPretImmoTaxeCalcule(data) {
    //GET CSI PRET IMMO

    let taxe = data.dataQuotation.taxes.find((t) => t.id === 36)
    const input_pret_hypotheque = parseInt(
        data.reponse_qualifications.find(
            (element) => element.qualification.id === 51
        ).reponse,
        10
    )
    const input_pret_zero = parseInt(
        data.reponse_qualifications.find(
            (element) => element.qualification.id === 50
        ).reponse,
        10
    )
    const input_commun_pret = input_pret_hypotheque + input_pret_zero
    taxe.amount = (input_commun_pret * taxe.rate) / 100
    return taxe
}
