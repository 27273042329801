import React, { Component, Fragment } from 'react'
import { websiteAction } from '../../../actions/website.actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import ArticlesList from './ArticlesList/ArticlesList'
import Modal from '../../../components/Modal/Modal'
import ArticleForm from './ArticleForm/ArticleForm'
import { articleActions } from '../../../actions/article.actions'

class Articles extends Component {
    componentDidMount() {
        this.props.getWebsite()
    }

    constructor(props) {
        super(props)

        this.submit = this.submit.bind(this)
    }

    submit(value) {
        value.web_id = this.props.website.website.id
        value.online = false
        this.props.createArticle(value)
        this.props.hideModal('NewArticle')
    }

    render() {
        return (
            <main
                className="dash-content col-12 col-md-9 col-xl-10"
                role="main"
            >
                <div className="py-5 px-2 px-sm-4">
                    <div className="mb-5 row">
                        <div className="col-6">
                            <p className="h1 mb-2 mb-md-5">Mes articles</p>
                        </div>
                        <div className="col-6  text-right">
                            <Modal
                                id="NewArticle"
                                btnStyle="btn btn-primary"
                                btnTitle="Créer un article"
                            >
                                <Fragment>
                                    <h2 className="text-center">
                                        Ajouter un article
                                    </h2>
                                    <ArticleForm onSubmit={this.submit} />
                                </Fragment>
                            </Modal>
                        </div>
                    </div>

                    <hr />
                    <div className="bloc_conseil">
                        <p>
                            <b>💡 Quelques idées de sujets d'articles :</b>
                            <ul>
                                <li>
                                    De manière mensuelle ou trimestrielle, vous
                                    pouvez écrire un article sur le marché
                                    immobilier de votre ville/département.
                                </li>
                                <li>
                                    Actualités liées à la vie de votre étude
                                </li>
                                <li>
                                    Essayez d’expliquer votre métier et de le
                                    rendre accessible à tous
                                </li>
                                <li>
                                    N’hésitez pas à nous contacter si vous
                                    souhaitez des idées d’articles à créer, nous
                                    sommes à votre disposition.
                                </li>
                            </ul>
                        </p>
                    </div>
                    <br />
                    <ArticlesList />
                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    return {
        website: state.website,
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getWebsite: websiteAction.getWebsite,
            createArticle: articleActions.createArticle,
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Articles)
