import React, { Component, Fragment } from 'react'

class InputCatField extends Component {
    constructor(props) {
        super(props)

        this.state = {
            categories_ids: [],
            categories_ids_forward: [],
        }

        this.handleChange = this.handleChange.bind(this)
    }

    componentDidMount() {
        if (this.props.category_ids && this.props.category_ids_forward) {
            this.setState({
                categories_ids: this.props.category_ids.input.value,
                categories_ids_forward:
                    this.props.category_ids_forward.input.value,
            })
        }
    }

    handleChange(cat) {
        let newCategories = this.props.category_ids.input.value
        let newCategoriesForward = this.props.category_ids_forward.input.value
        let indexCategorie = newCategories.findIndex(
            (categorie) => categorie === cat.id
        )
        let indexCategorieForward = newCategoriesForward.findIndex(
            (categorie) => categorie === cat.id
        )

        if (indexCategorie === -1) {
            newCategories.push(cat.id)
        } else {
            newCategories.splice(indexCategorie, 1)
            if (indexCategorieForward !== -1) {
                newCategoriesForward.splice(indexCategorieForward, 1)
            }
        }

        this.setState({
            categories_ids: newCategories,
            categories_ids_forward: newCategoriesForward,
        })

        this.props.category_ids.input.onChange(newCategories)
        this.props.category_ids_forward.input.onChange(newCategoriesForward)
    }

    handleChangeForward(cat) {
        let newCategories = this.props.category_ids.input.value
        let newCategoriesForward = this.props.category_ids_forward.input.value
        let indexCategorie = newCategories.findIndex(
            (categorie) => categorie === cat.id
        )
        let indexCategorieForward = newCategoriesForward.findIndex(
            (categorie) => categorie === cat.id
        )

        if (indexCategorie !== -1) {
            if (indexCategorieForward !== -1) {
                newCategoriesForward.splice(indexCategorieForward, 1)
            } else if (newCategoriesForward.length < 3) {
                newCategoriesForward.push(cat.id)
            }
        }

        this.setState({
            categories_ids_forward: newCategoriesForward,
        })

        this.props.category_ids_forward.input.onChange(newCategoriesForward)
    }

    render() {
        const { categories_ids, categories_ids_forward } = this.state
        const { allCategories } = this.props

        return (
            <Fragment>
                <label>
                    <b>Spécialités</b>
                </label>
                <div className="row categories_liste">
                    {allCategories.map((cat) => (
                        <a
                            key={cat.id}
                            className={
                                categories_ids.includes(cat.id)
                                    ? 'card_specialite_on'
                                    : 'card_specialite_off'
                            }
                            onClick={() => this.handleChange(cat)}
                        >
                            {cat.name}
                        </a>
                    ))}
                </div>
                <div className="padding_form">
                    <label>
                        <b>
                            Spécialités misent en avant sur la liste de
                            resultats (3 maximum)
                        </b>
                    </label>
                    <div className="row categories_liste">
                        {allCategories.map((cat) => (
                            <a
                                key={cat.id}
                                className={
                                    categories_ids_forward.includes(cat.id)
                                        ? 'card_specialite_forward_on'
                                        : 'card_specialite_forward_off'
                                }
                                onClick={() => this.handleChangeForward(cat)}
                            >
                                {cat.name}
                            </a>
                        ))}
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default InputCatField
