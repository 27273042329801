export function authHeader() {
    // return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('user'))

    if (user && user.token && user.subscribed) {
        return { Authorization: user.token, 'Content-Type': 'application/json' }
    } else {
        return {}
    }
}

export function authHeaderFormData() {
    // return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('user'))

    if (user && user.token && user.subscribed) {
        return { Authorization: user.token }
    } else {
        return {}
    }
}

export function headerDomainr() {
    return {
        'x-rapidapi-host': 'domainr.p.rapidapi.com',
        'x-rapidapi-key': process.env.DOMAINR,
    }
}
