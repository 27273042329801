import { APINotreNotaire } from '../helpers/AxiosAPI'

export const ficheServices = {
    getFiche,
    updateFiche,
}

function getFiche() {
    return APINotreNotaire.get('get_user_fiche')
}

export function updateFiche(body, fiche_id) {
    let bodys = new FormData()
    for (const property in body) {
        bodys.append(property, body[property])
    }
    return APINotreNotaire.patch(`fiches/${fiche_id}`, bodys)
}
