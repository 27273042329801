import React, { Component } from 'react'
import FicheTarifHeader from './FicheTarifHeader/FicheTarifHeader'
import { ficheAction } from '../../../actions/fiche.actions'
import TarifConsulting from './TarifConsulting/TarifConsulting'
import TarifEmolument from './TarifEmolument/TarifEmolument'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Loader from '../../../components/Loader'

class FicheTarif extends Component {
    componentDidMount() {
        if (Object.keys(this.props.fiche.fiche).length === 0) {
            this.props.getFiche()
        }
    }

    render() {
        const { loading } = this.props.fiche

        return loading ? (
            <Loader />
        ) : (
            <main
                className="dash-content col-12 col-md-9 col-xl-10"
                role="main"
            >
                <div className="py-5 px-2 px-sm-4">
                    <FicheTarifHeader />
                    <hr />
                    <TarifConsulting />
                    <TarifEmolument />
                </div>
            </main>
        )
    }
}
function mapStateToProps(state) {
    return {
        fiche: state.fiche,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getFiche: ficheAction.getFiche }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(FicheTarif)
