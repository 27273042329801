import { equipeConstants } from '../constants/equipe.constants'
import { equipeServices } from '../services/equipe.services'
import { res } from '../helpers/dispatch'

export const equipeActions = {
    getEquipe,
    updateEquipe,
}

function getEquipe() {
    return (dispatch) => {
        dispatch(res.loading(equipeConstants.EQUIPE_LOADING))
        equipeServices
            .getEquipe()
            .then((response) => {
                let equipe = response.data
                dispatch(res.success(equipeConstants.GETEQUIPE_SUCCESS, equipe))
            })
            .catch((error) => {
                dispatch(res.failure(equipeConstants.GETEQUIPE_FAILURE, error))
            })
    }
}

function updateEquipe(body, equipe_id) {
    return (dispatch) => {
        dispatch(res.loading(equipeConstants.EQUIPE_LOADING))
        equipeServices
            .updateEquipe(body, equipe_id)
            .then((response) => {
                let equipe = response.data
                dispatch(res.success(equipeConstants.PUTEQUIPE_SUCCESS, equipe))
            })
            .catch((error) => {
                dispatch(res.failure(equipeConstants.PUTEQUIPE_FAILURE, error))
            })
    }
}
