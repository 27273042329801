import React, { Component } from 'react'
import ImageIllu from '../../../../../assets/images/create_devis.png'
import InformationsUserForm from './InformationsUserForm'
class InformationsUser extends Component {
    render() {
        return (
            <div className="row">
                <div className="col">
                    <InformationsUserForm />
                </div>
                <div className="col text-center">
                    <img
                        src={'/' + ImageIllu}
                        alt=""
                        style={{ width: '550px' }}
                    />
                </div>
            </div>
        )
    }
}
export default InformationsUser
