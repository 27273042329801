import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
    getTeammate,
    teammateActions,
} from '../../../../actions/teammate.actions'
import MemberCard from './MemberCard'
import Loader from '../../../../components/Loader'

class Teammate extends Component {
    componentDidMount() {
        this.props.getTeammate()
    }

    render() {
        const { loading, teammate } = this.props.teammate

        return loading ? (
            <Loader />
        ) : (
            <div className="mt-5">
                {teammate.map((member) => (
                    <MemberCard key={member.id} member={member} />
                ))}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        teammate: state.teammate,
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { getTeammate: teammateActions.getTeammate },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Teammate)
