import React, {Component} from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { Link } from 'react-router-dom';
import { askNewPassword } from '../../../actions/auth';
import LogoNn from '../../../../assets/images/nn-square.svg'



const renderField = ({
  input,
  placeholder,
  type,
  meta: { touched, error, warning }
}) => (
  <div>
    <div>
      <input {...input} placeholder={placeholder} type={type} />
      {touched &&
        ((error && <span>{error}</span>) ||
          (warning && <span>{warning}</span>))}
    </div>
  </div>
)
class NewPassword extends Component {

  onSubmit = (value) => {
    this.props.askNewPassword(value)
    this.props.history.push('/login'); // Navigate after submit
  };

  render(){

    return (
         <div className='row'>
           <div className='col'></div>
           <div className='col'>
      <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
              <div className='Bloc'>
                 <LogoNn alt="" className='logo_auth'/>
                 <br/>
                 <br/>
                 <p>Entrez votre adresse e-mail pour changer de mot de passe</p>
                 <br/>
                   <div className='containerInput'>
                      <div>
                         <Field
                          name="email"
                          type="email"
                          component={renderField}
                          placeholder="E-mail"
                        />
                      </div>
                   </div>
                   <button className='btn btn-primary margin_top' type="submit" disabled={this.props.pristine || this.props.submitting}>
                  Envoyer les instructions
                  </button>
              </div>
            </form>
           </div>
           <div className='col'></div>
        </div>
    )
  }
}
export default reduxForm({ form: 'askpassword' })(
  connect(null, { askNewPassword })(NewPassword)
);

