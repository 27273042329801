import React, { Component } from 'react'

class Description extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        switch (this.props.lead.category.category_name) {
            case 'Achat Immobilier':
                return (
                    <>
                        Valeur du bien est de{' '}
                        <span className="font-weight-bold">
                            {this.props.lead.montant} €
                        </span>
                        <br />
                        Situation sur le bien :{' '}
                        <span className="font-weight-bold">
                            {this.props.lead.information}
                        </span>
                    </>
                )
            case 'Vente Immobilière':
                return (
                    <>
                        Estimation du montant de la succession{' '}
                        <span className="font-weight-bold">
                            {this.props.lead.montant} €
                        </span>
                        <br />
                        Situation de la succession :{' '}
                        <span className="font-weight-bold">
                            {this.props.lead.information}
                        </span>
                    </>
                )
            case 'Succession':
                return (
                    <>
                        Valeur du bien est de{' '}
                        <span className="font-weight-bold">
                            {this.props.lead.montant} €
                        </span>
                        <br />
                        Situation sur le bien :{' '}
                        <span className="font-weight-bold">
                            {this.props.lead.information}
                        </span>
                    </>
                )
            default:
                return this.props.lead.information
        }
    }
}

export default Description
