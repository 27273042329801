//app/VideoComponent.js
import React, { Component } from 'react';
import Video, {createLocalVideoTrack} from 'twilio-video';
import {getRoom} from '../../../actions/room.actions';
import LogoNn from '../../../../assets/images/logo-square.svg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes} from '@fortawesome/free-solid-svg-icons'


class VideoComponent extends Component {

 render() {

    Video.connect(this.props.jwt, { name: this.props.name }).then(room => {

      room.participants.forEach(participantConnected);
      room.on('participantConnected', participantConnected);

      // Log your Client's LocalParticipant in the Room
      const localParticipant = room.localParticipant;
      // Log any Participants already connected to the Room
      room.participants.forEach(participant => {
      });

      // Log new Participants as they connect to the Room
      room.once('participantConnected', participant => {
      });


      createLocalVideoTrack().then(track => {
      const localMediaContainer = document.getElementById('local-media');
      localMediaContainer.appendChild(track.attach());

      });



    });

    function participantConnected(participant) {
    participant.tracks.forEach(publication => {
    if (publication.isSubscribed) {
      const track = publication.track;
      document.getElementById('remote-media-div').appendChild(track.attach());
      }
    });

    participant.on('trackSubscribed', track => {
      document.getElementById('remote-media-div').appendChild(track.attach());
    });
  };


   return (
     <body className="dash-body">

       <div className="dash-topbar sticky-top">
         <div className="d-flex justify-content-between p-3">
           <div>
             <LogoNn src={LogoNn} style={{height: "60", width: "60"}}alt=""/>
           </div>
           <div className="d-flex justify-content-end">
             <div className="align-self-center">
             </div>
           </div>
         </div>
       </div>
       <div className="container-fluid">
         <div className="row">
           <div className="col-sm-6 bg-primary" style={{height: "500px"}}>
             <div id='remote-media-div'></div>
           </div>
           <div className="col-sm-6 bg-secondary d-none d-sm-block" style={{height: "500px"}}>
              <div id='local-media'></div>
           </div>
         </div>

         <div className="text-center mt-5">
           <button type="button" onClick={window.close}  name="button" className="btn btn-lg btn-outline-danger rounded-circle"><FontAwesomeIcon icon={faTimes}/></button>
         </div>
       </div>
     </body>
   );
 }
}


export default VideoComponent;
