import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import OfficeForm from './OfficeForm'
import { officeActions } from '../../../actions/office.actions'
import Loader from '../../../components/Loader'

class Office extends Component {
    componentDidMount() {
        this.props.getOffice()
    }

    render() {
        const { loading, office } = this.props.office
        return loading ? (
            <Loader />
        ) : (
            <main
                className="dash-content col-12 col-md-9 col-xl-10"
                role="main"
            >
                <div className="py-5 px-2 px-sm-4">
                    <div className="title_website">
                        <h1>Votre Page sur votre Office</h1>
                        <br />
                        <div className="bloc_conseil">
                            <p>
                                <b>✒️ Conseil rédaction:</b> Prenez le temps de
                                décrire un peu plus en détail votre activité.
                                Surtout ne faite pas de copier/coller du text
                                mit en page d'accueil !
                            </p>
                        </div>
                        <br />
                        <div className="bloc_conseil_seo">
                            <p>
                                <b>🥇 Conseil réferencement:</b> N'oubliez pas
                                d'utiliser les mots clés principaux liés à votre
                                activité.
                            </p>
                        </div>
                    </div>
                    <hr />
                    <OfficeForm initialValues={office} />
                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    return {
        office: state.office,
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getOffice: officeActions.getOffice }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Office)
