import React from 'react'
import AskRatingForm from './AskRating/AskRatingForm'
import Modal from '../../../components/Modal/Modal'

const RatingHeader = () => {
    return (
        <div className="mb-5">
            <div className="d-flex flex-column flex-md-row justify-content-between">
                <div>
                    <p className="h1 mb-2 mb-md-5">Recommandations reçues</p>
                </div>
                <Modal
                    id="AddRating"
                    btnStyle="btn btn-secondary"
                    btnTitle="Collecter des avis"
                >
                    <AskRatingForm />
                </Modal>
            </div>
        </div>
    )
}

export default RatingHeader
