import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Tab from './Tab';

class Tabs extends Component {
    static propTypes = {
        children: PropTypes.instanceOf(Array).isRequired,
    }

    constructor(props) {
        super(props);

        this.state = {
            activeTab: this.props.children[0].props.label,
        };
    }

    onClickTabItem = (tab) => {
        this.setState({ activeTab: tab });
    }

    render() {
        const {
            onClickTabItem,
            props: {
                children,
            },
            state: {
                activeTab,
            }
        } = this;

        return (
          <Fragment>
            <div className="shadow p-3 bg-white rounded mb-5">
            <div>
              {children.map((child) => {
                  const { label } = child.props;

                  return (
                      <Tab
                          activeTab={activeTab}
                          key={label}
                          label={label}
                          onClick={onClickTabItem}
                      />
                  );
              })}
            </div>
            <div id="carouselExampleControls" className="carousel slide dash-carousel" data-ride="carousel" data-interval="false">
              <div className="carousel-inner">
                      {children.map((child) => {
                          if (child.props.label !== activeTab) return undefined;
                          return child.props.children;
                      })}
                  </div>
              </div>
            </div>
          </Fragment>
        );
    }
}

export default Tabs;
