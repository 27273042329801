import { statistiquesConstants } from '../constants/statisiques.constants'

const statsInitial = {
    loading: true,
    profil: {},
    radar: {},
    scoreHistory: {},
    positionFiche: {},
    statWebsite: {},
    statWebsiteUniq: {},
    statWebsitePages: {},
}

export function stats(state = statsInitial, action) {
    switch (action.type) {
        case statistiquesConstants.STAT_LOADING:
            return {
                ...state,
                loading: true,
            }
        case statistiquesConstants.GETSTATVISITES_SUCCESS:
            return {
                ...state,
                loading: false,
                profil: action.payload.data,
            }
        case statistiquesConstants.GETSTATSRADAR_SUCCESS:
            return {
                ...state,
                loading: false,
                radar: action.payload.data,
            }
        case statistiquesConstants.GETSTATSCOREHISTORY_SUCCESS:
            return {
                ...state,
                loading: false,
                scoreHistory: action.payload.data,
            }
        case statistiquesConstants.GETPOSITIONFICHE_SUCCESS:
            return {
                ...state,
                loading: false,
                positionFiche: action.payload.data,
            }
        case statistiquesConstants.GETSTATVISITESWEBSITE_SUCCESS:
            return {
                ...state,
                loading: false,
                statWebsite: action.payload.data,
            }
        case statistiquesConstants.GETSTATUNIQUEWEBSITE_SUCCESS:
            return {
                ...state,
                loading: false,
                statWebsiteUniq: action.payload.data,
            }
        case statistiquesConstants.GETSTATSPAGESWEBSITE_SUCCESS:
            return {
                ...state,
                loading: false,
                statWebsitePages: action.payload.data,
            }
        default:
            return state
    }
}
