import { teammateConstants } from '../constants/teammate.constants'
import { teammateServices } from '../services/teammate.services'
import { res } from '../helpers/dispatch'

export const teammateActions = {
    getTeammate,
    updateTeammate,
    createTeammate,
}

export function getTeammate() {
    return (dispatch) => {
        dispatch(res.loading(teammateConstants.TEAMMATE_LOADING))
        teammateServices
            .getTeammate()
            .then((response) => {
                let teammate = response.data
                dispatch(
                    res.success(teammateConstants.GETTEAMMATE_SUCCESS, teammate)
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(teammateConstants.GETTEAMMATE_FAILURE, error)
                )
            })
    }
}

function updateTeammate(body, member_id) {
    return (dispatch) => {
        dispatch(res.loading(teammateConstants.TEAMMATE_LOADING))
        teammateServices
            .updateTeammate(body, member_id)
            .then((response) => {
                let teammate = response.data
                dispatch(
                    res.success(teammateConstants.PUTTEAMMATE_SUCCESS, teammate)
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(teammateConstants.PUTTEAMMATE_FAILURE, error)
                )
            })
    }
}

export function createTeammate(body, equipe_id) {
    return (dispatch) => {
        dispatch(res.loading(teammateConstants.TEAMMATE_LOADING))
        teammateServices
            .postTeammate(body, equipe_id)
            .then((response) => {
                let equipe = response.data
                dispatch(
                    res.success(teammateConstants.POSTTEAMMATE_SUCCESS, equipe)
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(teammateConstants.POSTTEAMMATE_FAILURE, error)
                )
            })
    }
}
