import React, { Component, Fragment } from 'react'

class InputPhotoField extends Component {
    constructor(props) {
        super(props)
        this.state = {
            photo: null,
        }
        this.onChange = this.onChange.bind(this)
    }

    componentDidMount() {
        if (this.props.input.value.photo) {
            this.setState({ photo: this.props.input.value.photo.url })
        } else {
            this.setState({ photo: this.props.input.value.url })
        }
    }

    onChange(e) {
        const {
            input: { onChange },
        } = this.props
        onChange(e.target.files[0])
    }

    render() {
        const { photo } = this.state
        const { style } = this.props
        return (
            <Fragment>
                {photo === null || photo === undefined ? null : (
                    <img src={photo} alt="" style={style} />
                )}
                <input
                    type="file"
                    className="form-control"
                    onChange={this.onChange}
                />
            </Fragment>
        )
    }
}

export default InputPhotoField
