import React, { Component } from 'react'
import ListHeader from './ListHeader/ListHeader'
import LeadTable from './LeadTable/LeadTable'

class Leads extends Component {
    render() {
        return (
            <main
                className="dash-content col-12 col-md-9 col-xl-10"
                role="main"
            >
                <div className="py-5 px-2 px-sm-4">
                    <ListHeader />
                    <LeadTable />
                </div>
            </main>
        )
    }
}
export default Leads
