import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getQuotations } from '../../../actions/devis.actions'
import ListQuotationsHeader from './ListQuotationsHeader/ListQuotationsHeader'
import CardQuotation from './CardQuotation/CardQuotation'
import Loader from '../../../components/Loader'

class ListQuotations extends Component {
    componentDidMount() {
        this.props.getQuotations()
    }

    render() {
        const { loading, devis } = this.props.devis
        return loading ? (
            <main
                className="dash-content col-12 col-md-9 col-xl-10 d-flex justify-content-center my-5"
                role="main"
            >
                <Loader />
            </main>
        ) : (
            <main
                className="dash-content col-12 col-md-9 col-xl-10"
                role="main"
            >
                <div className="py-5 px-2 px-sm-4">
                    <ListQuotationsHeader />
                    <div className="bloc_conseil mb-4">
                        <div>
                            <b>💡 Bon à savoir :</b> Cette liste affiche les
                            devis en attentes d'une réponse client, si le devis
                            est accepté par le client alors celui-ci apparaîtra
                            dans les dossiers acceptés et toutes les
                            informations du client vous seront transmises.
                        </div>
                    </div>
                    {devis.map((devis) => (
                        <CardQuotation devis={devis} key={devis.id} />
                    ))}
                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    return {
        devis: state.devis,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getQuotations }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ListQuotations)
