import { rateConstants } from '../constants/rate.constants'

const rateState = {
    loading: true,
    rates: [],
    error: false,
}

export function rate(state = rateState, action) {
    switch (action.type) {
        case rateConstants.RATES_LOADING:
            return {
                ...state,
                loading: true,
            }
        case rateConstants.GETRATES_SUCCESS:
            return {
                ...state,
                loading: false,
                rates: action.payload.data,
            }
        case rateConstants.PUTRATESVISIBLE_SUCCESS:
            return {
                ...state,
                loading: false,
                rates: action.payload.data,
            }
        default:
            return state
    }
}

const rateSentState = {
    loading: true,
    ratesent: [],
    error: false,
}

export function ratesent(state = rateSentState, action) {
    switch (action.type) {
        case rateConstants.RATESSENT_LOADING:
            return {
                ...state,
                loading: true,
            }
        case rateConstants.GETRATESSENT_SUCCESS:
            return {
                ...state,
                loading: false,
                ratesent: action.payload.data,
            }
        default:
            return state
    }
}
