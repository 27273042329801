import { devisCompositionConstants } from '../constants/devisComposition.constants'
import { res } from '../helpers/dispatch'

export const devisCompositionAction = {
    consultingDevisAdd,
    consultingDevisRemove,
    consultingReset,
    emolumentDevis,
    emolumentReset,
    taxeDevis,
    taxeReset,
    freeEmolumentDevis,
    discountDevis,
    deleteDiscount,
    otherCostsDevis,
    deleteOtherCostsDevis,
}

function emolumentDevis(body) {
    return (dispatch) => {
        dispatch(res.success(devisCompositionConstants.ADDEMOLUMENTDEVIS, body))
    }
}

function emolumentReset() {
    return (dispatch) => {
        dispatch(
            res.success(devisCompositionConstants.RESETEMOLUMENTDEVIS_REQUEST)
        )
    }
}

function taxeDevis(body) {
    return (dispatch) => {
        dispatch(
            res.success(devisCompositionConstants.ADDTAXEDEVIS_REQUEST, body)
        )
    }
}

function taxeReset() {
    return (dispatch) => {
        dispatch(res.success(devisCompositionConstants.RESETTAXEDEVIS_REQUEST))
    }
}

function consultingDevisAdd(body) {
    return (dispatch) => {
        dispatch(
            res.success(devisCompositionConstants.ADDCONSULTINGDEVIS, body)
        )
    }
}

function consultingDevisRemove(body) {
    return (dispatch) => {
        dispatch(
            res.success(devisCompositionConstants.REMOVECONSULTINGDEVIS, body)
        )
    }
}

function consultingReset(consulting) {
    return (dispatch) => {
        dispatch(
            res.success(
                devisCompositionConstants.RESETCONSULTINGDEVIS_REQUEST,
                consulting
            )
        )
    }
}

function freeEmolumentDevis(body) {
    return (dispatch) => {
        dispatch(
            res.success(devisCompositionConstants.ADDFREEEMOLUMENTDEVIS, body)
        )
    }
}

export function dispatchFreeEmolumentResetStore() {
    return (dispatch) => {
        dispatch(
            res.success(
                devisCompositionConstants.RESETFREEEMOLUMENTDEVIS_REQUEST
            )
        )
    }
}

function discountDevis(body) {
    return (dispatch) => {
        dispatch(res.success(devisCompositionConstants.ADDDISCOUNTDEVIS, body))
    }
}

function deleteDiscount(id) {
    return (dispatch) => {
        dispatch(
            res.success(
                devisCompositionConstants.DELETE_DISCOUNTDEVIS_REQUEST,
                id
            )
        )
    }
}

function otherCostsDevis(body) {
    return (dispatch) => {
        dispatch(
            res.success(
                devisCompositionConstants.ADDOTHERCOSTSDEVIS_REQUEST,
                body
            )
        )
    }
}

function deleteOtherCostsDevis(id) {
    return (dispatch) => {
        dispatch(
            res.success(
                devisCompositionConstants.DELETE_OTHERCOSTSDEVIS_REQUEST,
                id
            )
        )
    }
}
