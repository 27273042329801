import { websiteConstants } from '../constants/website.constants'
import { websiteService } from '../services/website.services'
import { res } from '../helpers/dispatch'

export const websiteAction = {
    getWebsite,
}

export function getWebsite() {
    return (dispatch) => {
        dispatch(res.loading(websiteConstants.WEBSITE_LOADING))
        websiteService
            .getWebsite()
            .then((response) => {
                let website = response.data
                dispatch(
                    res.success(websiteConstants.GETWEBSITE_SUCCESS, website)
                )
            })
            .catch((error) => {
                dispatch(
                    res.failure(websiteConstants.GETWEBSITE_FAILURE, error)
                )
            })
    }
}

export function createWebsite(website, callback) {
    const promise = websiteService.createWebsite(website, callback)
    return {
        type: websiteConstants.POSTWEBSITE_REQUEST,
        payload: request,
    }
}

export function updateWebsite(website, website_id, callback) {
    const promise = websiteService.updateWebsite(website, website_id, callback)
    return {
        type: websiteConstants.PUTWEBSITE_REQUEST,
        payload: promise,
    }
}
