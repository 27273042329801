export function calculPACS(data) {
    let taxes = data.dataQuotation.taxes.filter(
        (fixed_emolument) => fixed_emolument.category_id === 4
    )

    let taxes_PACS = []

    taxes.map((taxe) => {
        taxes_PACS.push({
            amount: taxe.amount,
            total: taxe.amount,
            name: taxe.name,
            id: taxe.id,
        })
    })

    return taxes_PACS
}
