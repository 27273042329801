import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { reduxForm, Field } from 'redux-form'
import { bindActionCreators } from 'redux'
import { createQuotation } from '../../../../../actions/devis.actions'
import { history } from '../../../../../helpers/history'
import { quotationAction } from '../../../../../actions/quotation_requests.actions'

class ButtonsQuotation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
        }
        this.onSubmit = this.onSubmit.bind(this)
    }

    onSubmit(value_comment) {
        let regex = /(?:(?:\+|00)33|0)\s*[1-9](?:[\/\s.-]*\d{2}){4}/gm
        if (value_comment.comment.match(regex)) {
            this.setState({
                error: 'Le commentaire ne doit pas contenir votre numéro de téléphone.',
            })
        } else {
            let values = this.props.devisComposition
            values.id_quotation = this.props.quotation
            values.comment = value_comment.comment
            values.commitment_date = value_comment.commitment_date
            this.props.createQuotation({ quotations: { values } })
            this.props.resetQuotation()
            history.push('/devis/list')
        }
    }

    render() {
        if (!this.props.devisComposition.total_ht) {
            return <p></p>
        }
        return (
            <div className="shadow p-3 bg-white rounded mb-5">
                <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                    <div className="row">
                        <div className="col-sm-5">
                            <div className="d-flex align-items-center">
                                <div className="mr-3">
                                    <p className="h2 text-secondary mb-0">
                                        <FontAwesomeIcon icon={faCheckCircle} />
                                    </p>
                                </div>
                                <div>
                                    <div className="h6 mb-0">
                                        <div>
                                            <label>
                                                Délai de réalisation de l'acte :
                                            </label>
                                            <Field
                                                name="commitment_date"
                                                component="select"
                                            >
                                                <option />
                                                <option value="1 à 2 semaines">
                                                    1 à 2 semaines
                                                </option>
                                                <option value="2 semaines à 1 mois">
                                                    2 semaines à 1 mois
                                                </option>
                                                <option value="1 à 2 mois">
                                                    1 à 2 mois
                                                </option>
                                                <option value="2 à 3 mois">
                                                    2 à 3 mois
                                                </option>
                                                <option value="plus de 3 mois">
                                                    plus de 3 mois
                                                </option>
                                            </Field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-7">
                            <div className="">
                                <div>
                                    <div className="mb-0">
                                        <div>
                                            <label className="h6">
                                                Petit mot pour le client:
                                            </label>
                                            {this.state.error !== null && (
                                                <div className="alert alert-danger">
                                                    {this.state.error}
                                                </div>
                                            )}

                                            <Field
                                                name="comment"
                                                component="textarea"
                                                placeholder="Ex: Nous vous accompagnerons avec plaisir"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-md-6 offset-md-6 text-sm-right">
                            <button
                                className="btn btn-xl btn-secondary mt-3 mt-xl-0"
                                type="submit"
                            >
                                Enregistrer et envoyer le devis
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        devisComposition: state.devisComposition,
        quotation: state.quotationRequest.quotationRequest.id,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            createQuotation: createQuotation,
            resetQuotation: quotationAction.resetQuotation,
        },
        dispatch
    )
}

export default reduxForm({ form: 'emolument_other_costs' })(
    connect(mapStateToProps, mapDispatchToProps)(ButtonsQuotation)
)
