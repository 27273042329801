import React, { Component } from 'react'
import CreateDevisHeader from './CreateDevisHeader/CreateDevisHeader'
import InformationsUser from './InformationsUser/InformationsUser'

class CreateQuotationRequest extends Component {
    render() {
        return (
            <main
                className="dash-content col-12 col-md-9 col-xl-10"
                role="main"
            >
                <div className="py-5 px-2 px-sm-4">
                    <CreateDevisHeader />
                    <div className="shadow p-3 bg-white rounded mb-5">
                        <InformationsUser />
                    </div>
                </div>
            </main>
        )
    }
}
export default CreateQuotationRequest
