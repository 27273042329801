import { APINotreNotaire } from '../helpers/AxiosAPI'

export const teammateServices = {
    updateTeammate,
    getTeammate,
    postTeammate,
}

function updateTeammate(member, teammate_id) {
    let formData = new FormData()
    for (const property in member) {
        if (member[property] != null) {
            formData.append(property, member[property])
        }
    }
    return APINotreNotaire.put(`teammates/${teammate_id}`, formData)
}

function getTeammate() {
    return APINotreNotaire.get('get_teammates')
}

function postTeammate(member) {
    let formData = new FormData()
    for (const property in member) {
        if (member[property] != null) {
            formData.append(property, member[property])
        }
    }
    return APINotreNotaire.post('teammates', formData)
}
