import { demandeConstants } from '../constants/demande.constants';
import { demandeServices } from '../services/demande.services';

export function getDemandes(){
    const promise = demandeServices.getDemandes()
    return {
        type: demandeConstants.GETDEMANDE_REQUEST,
        payload: promise
    };
}
