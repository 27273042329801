import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import NavBarSecondaryItem from './NavBarSecondaryItem'
import { history } from '../../helpers/history'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class NavBarItem extends Component {
    ifOnPath() {
        return history.location.pathname.match(this.props.link) ? true : false
    }

    getChildren(items) {
        if ((items && this.ifOnPath()) === true) {
            return (
                <ul className="list-unstyled selected">
                    {items.map((item) => (
                        <NavBarSecondaryItem
                            key={item.content}
                            link={item.link}
                            content={item.content}
                        />
                    ))}
                </ul>
            )
        }
    }

    render() {
        return (
            <div>
                <li className="nav-item">
                    <NavLink
                        className="nav-link"
                        activeClassName="selected"
                        strict
                        to={this.props.link}
                    >
                        <FontAwesomeIcon
                            icon={this.props.svg}
                            style={{ fontWeight: '900 !important' }}
                            className="icon"
                        />
                        {this.props.content}
                    </NavLink>
                    {this.getChildren(this.props.submenu)}
                </li>
            </div>
        )
    }
}

export default NavBarItem
