import React, { Component } from 'react'
import { DateTime } from 'luxon'

class CardQuotation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            detail: false,
            emolumentNotaire: 0,
            taxes: 0,
            honoraires: 0,
            frais: 0,
            emolumentLibre: 0,
            remise: 0,
        }

        this.detailChange = this.detailChange.bind(this)
    }

    componentDidMount() {
        this.props.devis.fixed_emolument_quotations.map((emolument) =>
            this.setState({
                emolumentNotaire: this.state.emolumentNotaire + emolument.total,
            })
        )
        this.props.devis.taxe_quotations.map((taxe) =>
            this.setState({
                taxes: this.state.emolumentNotaire + taxe.amount,
            })
        )
        this.props.devis.consulting_quotations.map((consulting) =>
            this.setState({
                honoraires: this.state.emolumentNotaire + consulting.honoraire,
            })
        )
        this.props.devis.other_cost_quotations.map((other_cost) =>
            this.setState({
                frais: this.state.emolumentNotaire + other_cost.amount,
            })
        )
        this.props.devis.free_emolument_quotations.map((emolument) =>
            this.setState({
                emolumentLibre: this.state.emolumentNotaire + emolument.amount,
            })
        )
        this.props.devis.discount_quotations.map((emolument) =>
            this.setState({
                remise: this.state.emolumentNotaire + emolument.amount,
            })
        )
    }

    detailChange() {
        this.setState({ detail: !this.state.detail })
    }

    render() {
        return (
            <div className="shadow p-3 mb-4 bg-white rounded">
                <div className="row">
                    <div className="col-6">
                        <h4 className="text-primary mb-3">
                            Devis n°
                            {this.props.devis.quotation_request.id}{' '}
                            <span className="badge badge-category">
                                {this.props.devis.category}
                            </span>
                        </h4>
                    </div>
                    <div className="col-12">
                        <p className="">
                            <b>Date de création:</b>{' '}
                            {DateTime.fromISO(
                                this.props.devis.created_at
                            ).toLocaleString(DateTime.DATETIME_MED)}
                        </p>
                        {this.state.detail ? (
                            <div>
                                {this.state.emolumentNotaire === 0 ? null : (
                                    <div className="border-bottom py-2 mb-2">
                                        <div className="d-flex bd-highlight">
                                            <p className="bd-highlight badge badge-primary my-auto">
                                                Emoluments du notaire
                                            </p>
                                            <div className="ml-auto bd-highlight">
                                                <b>
                                                    {
                                                        this.state
                                                            .emolumentNotaire
                                                    }
                                                    € HT
                                                </b>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {this.state.taxes === 0 ? null : (
                                    <div className="border-bottom py-2 mb-2">
                                        <div className="d-flex bd-highlight">
                                            <p className="bd-highlight badge badge-danger my-auto">
                                                Taxes et droit
                                            </p>
                                            <div className="ml-auto bd-highlight">
                                                <b>{this.state.taxes}€ HT</b>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {this.state.honoraires === 0 ? null : (
                                    <div className="border-bottom py-2 mb-2">
                                        <div className="d-flex bd-highlight">
                                            <p className="bd-highlight badge badge-info my-auto">
                                                Honoraires Conseil
                                            </p>
                                            <div className="ml-auto bd-highlight">
                                                <b>
                                                    {this.state.honoraires}€ HT
                                                </b>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {this.state.frais === 0 ? null : (
                                    <div className="border-bottom py-2 mb-2">
                                        <div className="d-flex bd-highlight">
                                            <p className="bd-highlight badge badge-other my-auto">
                                                Autres frais
                                            </p>
                                            <div className="ml-auto bd-highlight">
                                                <b>{this.state.frais}€ HT</b>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {this.state.emolumentLibre === 0 ? null : (
                                    <div className="border-bottom py-2 mb-2">
                                        <div className="d-flex bd-highlight">
                                            <p className="bd-highlight badge badge-category my-auto">
                                                Emolument libre
                                            </p>
                                            <div className="ml-auto bd-highlight">
                                                <b>
                                                    {this.state.emolumentLibre}€
                                                    HT
                                                </b>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {this.state.remise === 0 ? null : (
                                    <div className="border-bottom py-2 mb-2">
                                        <div className="d-flex bd-highlight">
                                            <p className="bd-highlight badge badge-secondary my-auto">
                                                Remises
                                            </p>
                                            <div className="ml-auto bd-highlight">
                                                <b>{this.state.remise}€ HT</b>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ) : null}
                        <button
                            className="btn btn-sm btn-light"
                            onClick={this.detailChange}
                        >
                            {this.state.detail
                                ? 'Moins de détails'
                                : 'Plus de détails'}
                        </button>
                    </div>
                </div>
                <div className="pb-2">
                    <div className="d-flex align-items-center">
                        <h4>Total Devis</h4>
                        <div className="ml-auto">
                            <br />
                            <h5>
                                <b>{this.props.devis.total_ht.toFixed(2)}€ </b>
                                HT
                            </h5>
                            <h5>
                                <b>{this.props.devis.total_ttc.toFixed(2)}€ </b>
                                TTC
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default CardQuotation
