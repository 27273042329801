import { APINotreNotaire } from '../helpers/AxiosAPI'

export const equipeServices = {
    updateEquipe,
    getEquipe,
}

function updateEquipe(equipe, equipe_id) {
    return APINotreNotaire.put(`equipes/${equipe_id}`, equipe)
}

function getEquipe() {
    return APINotreNotaire.get('get_equipe')
}
