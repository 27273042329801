import { calculMariage } from './FixedEmolument/Mariage/calculMariage'
import { calculImmo } from './FixedEmolument/Immobilier/calculImmo'
import { calculPACS } from './FixedEmolument/calculPACS'
import { calculTestament } from './FixedEmolument/calculTestament'

export function calculeEmolumentQuotation(data) {
    switch (data.category.id) {
        // MARIAGE
        case 3:
            return calculMariage(data)
        // PACS
        case 4:
            return calculPACS(data)
        // Testament
        case 25:
            return calculTestament(data)
        // Achat immobilier
        case 21:
            return calculImmo(data)
        default:
            return []
    }
}
