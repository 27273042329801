import { fetchAPI } from './Api.jsx'

export const demandeServices = {
    getDemandes,
    updateDemandes,
}

function getDemandes() {
    return fetchAPI(`demandes/`, 'GET')
}

function updateDemandes(id, accept) {
    return fetchAPI(`demandes/${id}`, 'PUT', accept)
}
