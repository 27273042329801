export const rateConstants = {
    RATES_LOADING: 'GET_RATES_REQUEST',
    GETRATES_SUCCESS: 'GET_RATES_SUCCESS',
    GETRATES_FAILURE: 'GET_RATES_FAILURE',
    RATESSENT_LOADING: 'RATES_SENT_LOADING',
    GETRATESSENT_SUCCESS: 'GET_RATES_SENT_SUCCESS',
    GETRATESSENT_FAILURE: 'GET_RATES_SENT_FAILURE',
    POSTREPLY_REQUEST: 'POST_REPLY_REQUEST',
    ASKRATE_REQUEST: 'ASK_RATE_CONSTANT',
    PUTRATESVISIBLE_SUCCESS: 'PUT_RATES_VISIBLE_SUCCESS',
    PUTRATESVISIBLE_FAILURE: 'PUT_RATES_VISIBLE_FAILURE',
}
