import { dossiersConstants } from '../constants/dossiers.constants'

const dossiersState = {
    loading: true,
    dossiers: [],
    error: false,
}

export function dossiers(state = dossiersState, action) {
    switch (action.type) {
        case dossiersConstants.DOSSIERS_LOADING:
            return {
                ...state,
                loading: true,
            }
        case dossiersConstants.GETDOSSIERS_SUCCESS:
            return {
                ...state,
                loading: false,
                dossiers: action.payload.data,
            }
        default:
            return state
    }
}
