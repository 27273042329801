import React, { Component } from 'react'
//import NavBarWebsite from '../../components/NavBarWebsite/NavBarWebsite';
import { getPositionFiche } from '../../../actions/statistiques.actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

class PositionFiche extends Component {
    componentDidMount() {
        if (Object.keys(this.props.stats).length === 0) {
            this.props.getPositionFiche()
        }
    }

    render() {
        return (
            <div className="mt-4">
                <h3>
                    <strong>Votre position à {this.props.stats.ville} :</strong>
                </h3>
                <p>
                    Sur un total de{' '}
                    <strong>{this.props.stats.nbr_concurrents}</strong> notaires
                </p>
                <div
                    className="mx-auto w-50 text-center"
                    style={{ marginTop: '80px' }}
                >
                    <div
                        style={{
                            height: '300px',
                            width: '300px',
                            backgroundColor: '#2287ff',
                            borderRadius: '12px',
                        }}
                    >
                        <div className="mx-auto w-50 text-center">
                            <p
                                style={{
                                    fontSize: '200px',
                                    color: 'white',
                                    fontWeight: 'bold',
                                }}
                            >
                                {this.props.stats.position}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        stats: state.stats.positionFiche,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getPositionFiche: getPositionFiche }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(PositionFiche)
