import React, {Component} from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { Link } from 'react-router-dom';
import { passwordReset } from '../../../actions/auth';
import LogoNn from '../../../../assets/images/nn-square.svg'


const required = value => (value || typeof value === 'number' ? undefined : 'Requis')
export const minLength = min => value =>
  value && value.length < min ? `Doit avoir ${min} caractères minimum` : undefined
export const minLength2 = minLength(8)
export const passwordsMatch = (value, allValues) =>
  value !== allValues.password ? "Le mot de passe n'est pas le même" : undefined;

const renderField = ({
  input,
  label,
  type,
  meta: { touched, error, warning }
}) => (
  <div>
    <label>{label}</label>
    <div>
      <input {...input} placeholder={label} type={type} />
      {touched &&
        ((error && <span>{error}</span>) ||
          (warning && <span>{warning}</span>))}
    </div>
  </div>
)
class Password extends Component {

  onSubmit = (value) => {
    const values = value;
    values.reset_password_token = this.props.match.params.token;
    this.props.passwordReset(values)
    this.props.history.push('/login'); // Navigate after submit
  };

  render(){

    return (
         <div className='row'>
           <div className='col'></div>
           <div className='col'>
      <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
              <div className='Bloc'>
                 <LogoNn alt="" className='logo_auth'/>
                 <p>Changer le mot de passe de mon compte</p>
                   <div className='containerInput'>
                      <div>
                         <Field
                          name="password"
                          type="password"
                          component={renderField}
                          label="Nouveau mot de passe"
                          validate={[required, minLength2]}
                        />
                      </div>
                      <div>
                        <Field
                        name="password_confirmation"
                        type="password"
                        component={renderField}
                        label="Confirmation mot de passe"
                        validate={[required, minLength2, passwordsMatch]}
                        />
                      </div>
                   </div>
                   <button className='btn btn-primary margin_top' type="submit" disabled={this.props.pristine || this.props.submitting}>
                  Valider
                  </button>
              </div>
            </form>
           </div>
           <div className='col'></div>
        </div>
    )
  }
}
export default reduxForm({ form: 'password' })(
  connect(null, { passwordReset })(Password)
);

