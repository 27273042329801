export const devisCompositionConstants = {
    ADDEMOLUMENTDEVIS: 'ADD_EMOLUMENT_DEVIS',
    RESETEMOLUMENTDEVIS_REQUEST: 'RESET_EMOLUMENT_DEVIS_REQUEST',
    ADDTAXEDEVIS_REQUEST: 'ADD_TAXE_DEVIS_REQUEST',
    ADDCONSULTINGDEVIS: 'ADD_CONSULTING_DEVIS',
    REMOVECONSULTINGDEVIS: 'REMOVE_CONSULTING_DEVIS',
    RESETCONSULTINGDEVIS_REQUEST: 'RESET_CONSULTING_DEVIS_REQUEST',
    ADDDISCOUNTDEVIS: 'ADD_DISCOUNT_DEVIS',
    ADDFREEEMOLUMENTDEVIS: 'ADD_FREE_EMOLUMENT_DEVIS',
    RESETFREEEMOLUMENTDEVIS_REQUEST: 'RESET_FREE_EMOLUMENT_DEVIS_REQUEST',
    RESETTAXEDEVIS_REQUEST: 'RESET_TAXE_DEVIS_REQUEST',
    DELETE_DISCOUNTDEVIS_REQUEST: 'DELETE_DISCOUNT_DEVIS_REQUEST',
    ADDOTHERCOSTSDEVIS_REQUEST: 'ADD_OTHER_COSTS_DEVIS_REQUEST',
    DELETE_OTHERCOSTSDEVIS_REQUEST: 'DELETE_OTHER_COSTS_DEVIS_REQUEST',
    RESETDEVISCOMPOSITION_REQUEST: 'RESET_DEVIS_COMPOSITION_REQUEST',
}
