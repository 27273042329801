import React, { Component } from 'react'
import { ratesAction } from '../../../../../actions/rate.actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip'

class RatingVisible extends Component {
    constructor(props) {
        super(props)
        this.state = {
            visible: '',
            id: '',
        }

        this.handleInputChange = this.handleInputChange.bind(this)
    }

    componentDidMount() {
        this.setState({ visible: this.props.visible, id: this.props.id })
    }

    handleInputChange(event) {
        const target = event.target
        const value = target.checked
        this.props.visible_note({ visible: value }, this.state.id)
    }

    render() {
        return (
            <label data-tip data-for="visible" className="switch-wrap">
                <input
                    name="visible"
                    type="checkbox"
                    checked={this.state.visible}
                    onChange={this.handleInputChange}
                />
                <div className="switch"></div>
                <ReactTooltip
                    id="visible"
                    type="info"
                    effect="solid"
                    place="left"
                >
                    <span>Rendre visible ou non cet avis sur votre fiche</span>
                </ReactTooltip>
            </label>
        )
    }
}

function mapStateToProps(state) {
    return {
        rates: state.rates,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { visible_note: ratesAction.visible_note },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(RatingVisible)
