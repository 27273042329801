import React, { Component } from 'react'
import CreateQuotationQualificationHeader from './CreateQuotationQualificationHeader/CreateQuotationQualificationHeader'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { quotationAction } from '../../../actions/quotation_requests.actions'
import CreateQuotationQualificationForm from './CreateQuotationQualificationForm/CreateQuotationQualificationForm'
import Loader from '../../../components/Loader'

class CreateQuotationQualification extends Component {
    componentDidMount() {
        if (
            Object.keys(this.props.quotationRequest.quotationRequest).length ===
            0
        ) {
            this.props.getQuotation(this.props.match.params.token)
        }
    }

    render() {
        const { loading } = this.props.quotationRequest
        return loading ? (
            <Loader />
        ) : (
            <main
                className="dash-content col-12 col-md-9 col-xl-10"
                role="main"
            >
                <div className="py-5 px-2 px-sm-4">
                    <CreateQuotationQualificationHeader />
                    <div className="shadow p-3 bg-white rounded mb-5">
                        <CreateQuotationQualificationForm />
                    </div>
                </div>
            </main>
        )
    }
}
function mapStateToProps(state) {
    return { quotationRequest: state.quotationRequest }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { getQuotation: quotationAction.getQuotation },
        dispatch
    )
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateQuotationQualification)
