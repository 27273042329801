import { homeConstants } from '../constants/home.constants'

const homeState = {
    loading: true,
    home: null,
    error: false,
}

export function home(state = homeState, action) {
    switch (action.type) {
        case homeConstants.HOME_LOADING:
            return {
                ...state,
                loading: true,
            }
        case homeConstants.GETHOME_SUCCESS:
            return {
                ...state,
                home: action.payload.data,
                loading: false,
            }
        case homeConstants.PUTHOME_SUCCESS:
            return {
                ...state,
                home: action.payload.data,
                loading: false,
            }
        default:
            return state
    }
}
