import React, {Component} from 'react';
//import NavBarWebsite from '../../components/NavBarWebsite/NavBarWebsite';
import {getStatPagessWebsite} from '../../../actions/statistiques.actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { PieChart, Pie, Sector, ResponsiveContainer, Tooltip } from 'recharts';


class StatistiquesPages extends Component {


  componentDidMount(){
    this.props.getStatPagessWebsite();

  }

  render(){
    if(!this.props.stats){
      return(<p>Chargement...</p>)
    }
    const data = [
      { name: 'Home Page', value: this.props.stats.data_home },
      { name: 'Page Office', value: this.props.stats.data_office },
      { name: 'Page Equipe', value: this.props.stats.data_equipe },
      { name: 'Page Contacts', value: this.props.stats.data_contact },
    ];

    return(

         <div className="mt-4" style = {{ width: '100%', height: 300 }}>
         <h3><strong>Répartition des pages visités:</strong></h3>
            <ResponsiveContainer>
              <PieChart>
                <Pie dataKey="value" isAnimationActive={true} data={data} fill="#20dd98" label />
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
         </div>

    )
  }
}


function mapStateToProps(state) {
  return {
    stats: state.stats.statWebsitePages
  };
}

function mapDispatchToProps(dispatch){
  return bindActionCreators(
       {getStatPagessWebsite: getStatPagessWebsite}, dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(StatistiquesPages);
