import { APINotreNotaire } from '../helpers/AxiosAPI'

export const horrairesService = {
    createHorraire,
    getHorraires,
    deleteHorraire,
}

function createHorraire(horraire) {
    return APINotreNotaire.post('horraires', horraire)
}

function getHorraires() {
    return APINotreNotaire.get('horraires')
}

function deleteHorraire(id) {
    return APINotreNotaire.delete(`horraires/${id}`)
}
