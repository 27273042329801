import React, { Component, Fragment } from 'react'
import { devisCompositionAction } from '../../../../../actions/devis_composition.actions'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Field, reduxForm } from 'redux-form'
import Modal from '../../../../../components/Modal/Modal'
import UserEmolumentForm from '../../../../Fiche/FicheTarif/TarifEmolument/AddEmolument/UserEmolumentForm'

class FreeEmolumentDetail extends Component {
    constructor(props) {
        super(props)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleSubmit(value) {
        if (
            this.props.free_emoluments.find(
                (emolument) => emolument.id === parseInt(value.emolument)
            ) === undefined
        ) {
            const data = this.props.dataQuotation.user_emoluments.find(
                (emolument) => emolument.id === parseInt(value.emolument)
            )
            this.props.freeEmolumentDevis(data)
        }
    }

    render() {
        const { free_emoluments } = this.props.devisComposition

        return (
            <Fragment>
                <div className="border-bottom py-2">
                    <div className="d-flex align-items-center">
                        <span className="badge badge-category h4 my-2">
                            Emoluments du notaire libres
                        </span>
                    </div>
                </div>
                {free_emoluments.map((freeemolument) => (
                    <div className="border-bottom py-2" key={freeemolument.id}>
                        <div className="d-flex align-items-center">
                            <div className="mr-2">
                                {freeemolument.emolument.name}
                            </div>
                            <div className="ml-auto">
                                <b>
                                    {parseInt(freeemolument.amount, 10).toFixed(
                                        2
                                    )}
                                    €
                                </b>{' '}
                                HT
                            </div>
                        </div>
                    </div>
                ))}
                <div
                    className={
                        this.props.dataQuotation.user_emoluments.length === 0
                            ? null
                            : 'border-bottom py-2'
                    }
                >
                    {this.props.dataQuotation.emoluments.length !== 0 ? (
                        <div className="border-bottom py-2">
                            <div className="text-center">
                                <Modal
                                    id="AddEmolument"
                                    btnStyle="btn btn-secondary"
                                    btnTitle="Ajouter une prestation"
                                >
                                    <UserEmolumentForm
                                        emoluments={
                                            this.props.dataQuotation.emoluments
                                        }
                                    />
                                </Modal>
                            </div>
                        </div>
                    ) : null}
                    {this.props.dataQuotation.user_emoluments.length ===
                    0 ? null : (
                        <form>
                            <div className="row">
                                <div className="col-10">
                                    <Field
                                        name="emolument"
                                        className="form-control"
                                        component="select"
                                    >
                                        <option></option>
                                        {this.props.dataQuotation.user_emoluments.map(
                                            (freeemolument) => (
                                                <option
                                                    key={freeemolument.id}
                                                    value={freeemolument.id}
                                                >
                                                    {
                                                        freeemolument.emolument
                                                            .name
                                                    }
                                                </option>
                                            )
                                        )}
                                    </Field>
                                </div>
                                <div className="col-2 text-center">
                                    <button
                                        type="button"
                                        onClick={this.props.handleSubmit(
                                            this.handleSubmit
                                        )}
                                        className="btn  btn-outline-secondary mt-1"
                                    >
                                        Ajouter
                                    </button>
                                </div>
                            </div>
                        </form>
                    )}
                </div>
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        devisComposition: state.devisComposition,
        dataQuotation: state.dataQuotation.dataQuotation,
        free_emoluments: state.devisComposition.free_emoluments,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { freeEmolumentDevis: devisCompositionAction.freeEmolumentDevis },
        dispatch
    )
}

export default reduxForm({ form: 'emolument_libre' })(
    connect(mapStateToProps, mapDispatchToProps)(FreeEmolumentDetail)
)
