import React, { Component, Fragment } from 'react'

class TextareaCountCharField extends Component {
    constructor(props) {
        super(props)
        this.state = {
            nbChar: this.props.input.value.length,
        }
        this.countValueTitle = this.countValueTitle.bind(this)
    }

    countValueTitle(event) {
        this.setState({ nbChar: event.target.value.length })
        this.props.input.onChange(event.target.value)
    }

    render() {
        const {
            style,
            input: { value },
        } = this.props
        const { nbChar } = this.state

        return (
            <Fragment>
                <textarea
                    value={value}
                    className="fiche_update"
                    onChange={this.countValueTitle}
                    style={style}
                />
                <p className="font-italic">{nbChar} character(s)</p>
            </Fragment>
        )
    }
}

export default TextareaCountCharField
