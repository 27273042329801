import { fetchAPI } from './Api.jsx'
import { APINotreNotaire } from '../helpers/AxiosAPI'

export const websiteService = {
    createWebsite,
    getWebsite,
    updateWebsite,
}

function createWebsite(website, callback) {
    return fetchAPI('websites', 'POST', website, callback)
}

function getWebsite() {
    return APINotreNotaire.get('get_website')
}

function updateWebsite(website, website_id) {
    return fetchAPI(`websites/${website_id}`, 'PATCH', website)
}
