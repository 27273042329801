import axios from 'axios'
import { history } from './history'

export const APINotreNotaire = axios.create({
    baseURL: process.env.API_URL,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    },
})

APINotreNotaire.interceptors.request.use(
    (config) => {
        let user = JSON.parse(localStorage.getItem('user'))
        let token = user.token
        if (token) {
            config.headers.Authorization = token
        }
        return config
    },
    (error) => Promise.reject(error)
)

APINotreNotaire.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        localStorage.removeItem('user')
        history.push('/login')
        return Promise.reject(error)
    }
)
