export const leadConstants = {
    LEADS_LOADING: 'LEADS_LOADING',
    GETLEADS_SUCCESS: 'GET_LEADS_SUCCESS',
    GETLEADS_FAILURE: 'GET_LEADS_FAILURE',
    LEAD_LOADING: 'LEAD_LOADING',
    GETLEAD_SUCCESS: 'GET_LEAD_SUCCESS',
    GETLEAD_FAILURE: 'GET_LEAD_FAILURE',
    POSTLEAD_SUCCESS: 'POST_LEAD_SUCCESS',
    POSTLEAD_FAILURE: 'POST_LEAD_FAILURE',
    POSTSTATUT_SUCCESS: 'POST_STATUT_SUCCESS',
    POSTSTATUT_FAILURE: 'POST_STATUT_FAILURE',
}
