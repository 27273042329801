import React, {Component} from 'react';


class FicheAgendaHeader extends Component {


    render(){
        return(
          <div className="mb-3">
            <div className="d-flex flex-column flex-md-row justify-content-between">
              <div>
                <p className="h1 mb-2 mb-md-5">Agenda de votre étude</p>
              </div>
            </div>
          </div>
        )
    }
}

export default FicheAgendaHeader;
