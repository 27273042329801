import { agendaConstants } from '../constants/agenda.constants';
import { agendaServices } from '../services/agenda.services';

export function getAgenda(){
    const promise = agendaServices.getAgenda()
    return {
        type: agendaConstants.GETAGENDA_REQUEST,
        payload: promise
    };
}
