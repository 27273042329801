import React, { Component } from 'react'
import { connect } from 'react-redux'

class UserInfo extends Component {
    render() {
        const { lead } = this.props.lead
        return (
            <div className="shadow p-3 mb-5 bg-white rounded h-100">
                <div className="row">
                    <div className="col-6 h3 mb-0">{lead.telephone}</div>
                    <div className="col-6 text-right h3 mb-0">{lead.email}</div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return { lead: state.lead }
}

export default connect(mapStateToProps, null)(UserInfo)
