import {fetchAPI} from './Api.jsx';

export const agendaServices = {
    getAgenda,
    updateAgenda
}


function getAgenda(){
    return fetchAPI(`agenda/`, "GET")
}


function updateAgenda(token){
    return fetchAPI(`update_agenda_notaire/`, "PUT", {agenda_token: token})
}
