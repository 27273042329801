import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from 'react-redux'

class CreateQuotationQualificationHeader extends Component {
    render() {
        return (
            <div className="mb-5">
                <div className="d-flex">
                    <div>
                        <Link
                            to="/devis"
                            className="btn btn-light btn-lg rounded-circle mr-4"
                        >
                            <FontAwesomeIcon icon="angle-left" />
                        </Link>
                    </div>
                    <div>
                        <p className="h2">
                            Information pour le devis de{' '}
                            {
                                this.props.quotationRequest.quotationRequest
                                    .client.firstname
                            }{' '}
                            {
                                this.props.quotationRequest.quotationRequest
                                    .client.lastname
                            }{' '}
                            <span className="badge-category">
                                {
                                    this.props.quotationRequest.quotationRequest
                                        .category.name
                                }
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return { quotationRequest: state.quotationRequest }
}

export default connect(
    mapStateToProps,
    null
)(CreateQuotationQualificationHeader)
