import { demandeConstants } from '../constants/demande.constants'

export function demande(state = [], action) {
    switch (action.type) {
        case demandeConstants.GETDEMANDE_REQUEST:
            let results = {
                demande: [],
                accepted: [],
                refus: [],
            }
            if (action.payload) {
                action.payload.map((result) => {
                    switch (result.demande_disponibility.accepted) {
                        case true:
                            results.accepted.push(result)
                            break
                        case false:
                            results.refus.push(result)
                            break
                        case null:
                            results.demande.push(result)
                            break
                        default:
                            break
                    }
                })
            }
            return results
        default:
            return state
    }
}
