import React, { Component } from 'react'

class QuotationsHeader extends Component {
    render() {
        return (
            <div className="mb-2">
                <div className="d-flex">
                    <div>
                        <p className="h1 mb-1">
                            Les demandes de devis de votre secteur
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}
export default QuotationsHeader
